





























export default {
  name: 'AutomaticSlider',
  props: {
    news: Array,
  },

  data() {
    return {
      el: '.automatic-slider--item',
    };
  },
  methods: {
    randomKey() {
      return (
        new Date().getTime() + Math.floor(Math.random() * 10000).toString()
      );
    },
  },
};
