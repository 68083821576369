<template>
  <div
    class="add-to-cart-bar position-fixed end-0 bottom-0 start-0 bg-bone py-3 py-lg-2"
  >
    <div class="container px-xl-10">
      <div class="d-flex row align-items-center">
        <div class="left produkt-title col-lg-5 col-xl-6 d-none d-lg-flex">
          <p class="body-base-semibold m-0">{{ productDetails.title }}</p>
        </div>

        <div
          class="middle col-lg-4 col-xl-3 d-none d-lg-flex align-items-end pe-3"
        >
          <div class="info body-3-regular text-end ms-auto me-4">
            <ProductWeightInfo
              :product="productDetails"
              :tax="productDetails.productTaxLabel"
            />
          </div>

          <div class="price text-end ms-auto">
            <template
              v-if="!productDetails.discountPrice && productDetails.price"
            >
              <span class="d-block text-preset-details">
                {{ $fc(productDetails.price) }}
              </span>
            </template>
            <template v-else>
              <span
                v-if="productDetails.price"
                class="d-block body-3-regular text-decoration-line-through text-danger"
              >
                {{ $fc(productDetails.price) }}
              </span>
              <span
                class="d-block text-preset-details"
                v-if="productDetails.discountPrice"
              >
                {{ $fc(productDetails.discountPrice) }}
              </span>
            </template>
          </div>
        </div>

        <div class="right col-lg-3 col-xs-12 text-center">
          <template v-if="!productDetails.outOfStock">
            <button
              type="button"
              class="btn btn-primary"
              @click.stop.prevent="addToCartPopup(productDetails.magentoSku)"
            >
              {{ $t('Add to Cart') }}
            </button>
          </template>
          <template v-else>
            <button
              type="button"
              class="btn btn-primary"
              @click.stop.prevent="$emit('outOfStock')"
            >
              {{ $t('Notify me') }}
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SfModal } from '@storefront-ui/vue';
import ProductSubConfigModal from '~/components/ProductSubConfigModal.vue';
import ProductWeightInfo from '@/components/ProductWeightInfo.vue';
import { useProdSubConfigState } from '~/composables/useProdSubConfigState';
import { useLanguageStore } from '@/stores/languageStore';
import { computed, ref } from '@nuxtjs/composition-api';

export default {
  name: 'ProductAddToCartBar',
  components: {
    ProductSubConfigModal,
    ProductWeightInfo,
    SfModal,
  },
  props: {
    productDetails: Object,
    productLegalCaluclation: String,
  },
  setup(props) {
    const { openProdSubConfigModal, setProductSku } = useProdSubConfigState();

    const addToCartPopup = async (prodSku) => {
      setProductSku(prodSku);
      openProdSubConfigModal();
    };

    return {
      addToCartPopup,
    };
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.add-to-cart-bar {
  z-index: 9;

  .middle {
    border-right: $border-1;
    border-color: $bone-83;
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(lg) {
      width: auto;
    }
  }
}
</style>
