<template>
  <div class="border-top border-bottom border-bone-83 mb-4">
    <nav class="filter container">
      <ul class="blanklist d-flex my-2 font-weight-600 lh-1">
        <li>
          <label
            for="reset"
            @click="resetFilter"
            class="form-check-button-label btn btn-secondary border-bone-83 text-anthracite px-2"
            :class="{ selected: !selected[0] }"
          >
            {{ $t('See all') }}
          </label>
          <!-- <input type="checkbox" id="reset" value=""> -->
        </li>
        <li
          v-for="filter in filters"
          :key="filter.uid"
          v-if="filter.title"
          class="form-check-button ms-2"
        >
          <input
            type="checkbox"
            :id="`filter-${filter.uid}`"
            :name="`filter-${filter.uid}`"
            :value="filter.uid"
            class="form-check-button-input d-none"
            v-model="selected"
          />
          <label
            :for="`filter-${filter.uid}`"
            class="form-check-button-label btn btn-secondary border-bone-83 text-anthracite px-2"
          >
            {{ filter.title }}
          </label>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
export default {
  name: 'ListFilter',
  props: {
    filters: Array,
  },
  watch: {
    selected() {
      this.$emit('input', this.selected);
    },
  },
  // emits: ['update:modelValue'],
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    resetFilter() {
      this.selected = [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'assets/styles/misc/vars';
.filter {
  > ul {
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      overflow-y: inherit;
    }
  }
}
</style>
<style lang="scss">
@import 'assets/styles/misc/vars';
.form-check-button {
  &-input:checked {
    + label {
      background: $hazelgreen-70 !important;
      border-color: transparent !important;
    }
  }
  &-label {
    text-transform: none;
    font-weight: 400;
    white-space: nowrap;
    &.selected {
      background: $hazelgreen-70 !important;
      border-color: transparent !important;
    }
  }
}
</style>
