<template>
  <FullWithTwoCols
    :layoutElemSpace="'my-0'"
    :layoutLeftColBgColor="'bg-bone'"
    :layoutLeftColSpace="'px-4 py-6 pt-lg-18 pe-lg-6 pb-lg-13 ps-lg-5'"
    :layoutRightColBgColor="'bg-sand'"
    :layoutRightColSpace="'px-4 py-6 pt-lg-18 pe-lg-5 pb-lg-13 ps-lg-6'"
    v-if="newsLetterLeftData || newsLetterRightData"
  >
    <template #leftCol>
      <div class="leftNewsLetter" v-html="newsLetterLeftData"></div>
      <NewsletterB2C />
    </template>
    <template #rightCol>
      <div class="rightNewsLetter" v-html="newsLetterRightData"></div>
    </template>
  </FullWithTwoCols>
</template>

<script>
import FullWithTwoCols from '~/layouts/FullWithTwoCols.vue';
import TextContentBlock from './TextContentBlock.vue';
import { useMainNavStore } from '../stores/mainNavStore';
import { onBeforeMount, computed, useContext } from '@nuxtjs/composition-api';

import NewsletterB2C from './Newsletter/NewsletterB2C.vue';

export default {
  name: 'NewsletterSection',
  components: {
    FullWithTwoCols,
    TextContentBlock,
    NewsletterB2C,
  },
  setup() {
    const mainNavStore = useMainNavStore();

    let newsLetterRightData = computed(() => mainNavStore.newsLetterRight);
    let newsLetterLeftData = computed(() => mainNavStore.newsLetterLeft);

    return {
      newsLetterRightData,
      newsLetterLeftData,
      TextContentBlock,
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/elements/newsletter.scss';
</style>
