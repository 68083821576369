<template>
  <div class="product-kpi">
    <div class="row">
      <div
        class="kpi-item text-center col-4 col-md-3 col-xl-2"
        v-for="(item, i) in nutritions"
        :key="i"
        :id="`kpi-${item.uid}`"
      >
        <i
          class="kpi-icon-box mb-1 mx-auto bg-jasmine-85"
          :class="item.icon"
        ></i>
        <p class="body-2-semibold" v-html="item.title"></p>
      </div>
    </div>

    <div class="row">
      <div
        class="kpi-item text-center col-4 col-md-3 col-xl-2"
        v-for="(item, i) in attributes"
        :key="i"
        :id="`kpi-${item.uid}`"
      >
        <i
          class="kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon"
          :class="item.icon"
        ></i>
        <p class="body-2-semibold" v-html="item.title"></p>
      </div>

      <div
        v-if="
          product.productDailyPortionPrice !== '' &&
          product.productDailyPortionPrice !== undefined &&
          product.productDailyPortionPrice !== null
        "
        class="kpi-item text-center col-4 col-md-3 col-xl-2"
      >
        <SvgImage
          icon="Ebene_1"
          :label="product.productDailyPortionPrice"
          class="kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon"
        />
        <p class="body-2-semibold">{{ product.productDailyPortionPrice }}</p>
      </div>

      <!-- Kapseln -->
      <div
        class="kpi-item text-center col-4 col-md-3 col-xl-2 px-0"
        v-if="
          product.productType == 'Kapseln' || product.productType == 'capsules'
        "
      >
        <i
          class="kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon social-icon-kapsel"
        ></i>
        <p class="body-2-semibold">
          <span class="capLbl">{{ $t(product.productType) }}</span
          ><br />
          <ProductShowContentsByType :product="product" :showLabel="false" />
        </p>
      </div>
      <!-- Pulver -->
      <div
        class="kpi-item text-center col-4 col-md-3 col-xl-2 px-0"
        v-if="
          product.productType == 'Pulver' || product.productType == 'powder'
        "
      >
        <i
          class="kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon social-icon-pulver"
        ></i>
        <p class="body-2-semibold">
          <span class="capLbl">{{ $t(product.productType) }}</span
          ><br />
          <ProductShowContentsByType :product="product" :showLabel="false" />
        </p>
      </div>

      <!-- Tabletten -->
      <div
        class="kpi-item text-center col-4 col-md-3 col-xl-2 px-0"
        v-if="
          product.productType == 'Tabletten' ||
          product.productType == 'tablets' ||
          product.productType == 'tablets'
        "
      >
        <i
          class="kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon social-icon-kapsel"
        ></i>
        <p class="body-2-semibold">
          <span class="capLbl">{{ $t(product.productType) }}</span
          ><br />
          <ProductShowContentsByType :product="product" :showLabel="false" />
        </p>
      </div>

      <!-- Tabletten -->
      <div
        class="kpi-item text-center col-4 col-md-3 col-xl-2 px-0"
        v-if="
          product.productType == 'Tropfen' ||
          product.productType == 'Drops' ||
          product.productType == 'drops'
        "
      >
        <i
          class="kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon social-icon-fluessig"
        ></i>
        <p class="body-2-semibold">
          <span class="capLbl">{{ $t(product.productType) }}</span
          ><br />
          <ProductShowContentsByType :product="product" :showLabel="false" />
        </p>
      </div>

      <!-- Flasche -->
      <div
        class="kpi-item text-center col-4 col-md-3 col-xl-2 px-0"
        v-if="
          product.productType == 'Flasche' ||
          product.productType == 'bottle' ||
          product.productType == 'liquid'
        "
      >
        <i
          class="kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon social-icon-fluessig"
        ></i>
        <p class="body-2-semibold">
          <span class="capLbl">{{ $t(product.productType) }}</span
          ><br />
          <ProductShowContentsByType :product="product" :showLabel="false" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ProductShowContentsByType from '@/components/ProductPage/ProductShowContentsByType.vue';
import SvgImage from '~/components/General/SvgImage.vue';
export default {
  name: 'ProduktKpi',
  components: {
    ProductShowContentsByType,
    SvgImage,
  },
  props: {
    nutritions: Array,
    attributes: Array,
    product: Object,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'assets/styles/misc/vars';
.product-kpi {
  .kpi-item {
    .kpi-icon-box {
      width: 3rem;
      height: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 80rem;
      font-size: 1.8rem;
      figure {
        width: 1.25rem;
      }
    }
  }
}

.capLbl {
  text-transform: capitalize;
}
</style>
