var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-kpi"},[_c('div',{staticClass:"row"},_vm._l((_vm.nutritions),function(item,i){return _c('div',{key:i,staticClass:"kpi-item text-center col-4 col-md-3 col-xl-2",attrs:{"id":("kpi-" + (item.uid))}},[_c('i',{staticClass:"kpi-icon-box mb-1 mx-auto bg-jasmine-85",class:item.icon}),_vm._v(" "),_c('p',{staticClass:"body-2-semibold",domProps:{"innerHTML":_vm._s(item.title)}})])}),0),_vm._v(" "),_c('div',{staticClass:"row"},[_vm._l((_vm.attributes),function(item,i){return _c('div',{key:i,staticClass:"kpi-item text-center col-4 col-md-3 col-xl-2",attrs:{"id":("kpi-" + (item.uid))}},[_c('i',{staticClass:"kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon",class:item.icon}),_vm._v(" "),_c('p',{staticClass:"body-2-semibold",domProps:{"innerHTML":_vm._s(item.title)}})])}),_vm._v(" "),(
        _vm.product.productDailyPortionPrice !== '' &&
        _vm.product.productDailyPortionPrice !== undefined &&
        _vm.product.productDailyPortionPrice !== null
      )?_c('div',{staticClass:"kpi-item text-center col-4 col-md-3 col-xl-2"},[_c('SvgImage',{staticClass:"kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon",attrs:{"icon":"Ebene_1","label":_vm.product.productDailyPortionPrice}}),_vm._v(" "),_c('p',{staticClass:"body-2-semibold"},[_vm._v(_vm._s(_vm.product.productDailyPortionPrice))])],1):_vm._e(),_vm._v(" "),(
        _vm.product.productType == 'Kapseln' || _vm.product.productType == 'capsules'
      )?_c('div',{staticClass:"kpi-item text-center col-4 col-md-3 col-xl-2 px-0"},[_c('i',{staticClass:"kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon social-icon-kapsel"}),_vm._v(" "),_c('p',{staticClass:"body-2-semibold"},[_c('span',{staticClass:"capLbl"},[_vm._v(_vm._s(_vm.$t(_vm.product.productType)))]),_c('br'),_vm._v(" "),_c('ProductShowContentsByType',{attrs:{"product":_vm.product,"showLabel":false}})],1)]):_vm._e(),_vm._v(" "),(
        _vm.product.productType == 'Pulver' || _vm.product.productType == 'powder'
      )?_c('div',{staticClass:"kpi-item text-center col-4 col-md-3 col-xl-2 px-0"},[_c('i',{staticClass:"kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon social-icon-pulver"}),_vm._v(" "),_c('p',{staticClass:"body-2-semibold"},[_c('span',{staticClass:"capLbl"},[_vm._v(_vm._s(_vm.$t(_vm.product.productType)))]),_c('br'),_vm._v(" "),_c('ProductShowContentsByType',{attrs:{"product":_vm.product,"showLabel":false}})],1)]):_vm._e(),_vm._v(" "),(
        _vm.product.productType == 'Tabletten' ||
        _vm.product.productType == 'tablets' ||
        _vm.product.productType == 'tablets'
      )?_c('div',{staticClass:"kpi-item text-center col-4 col-md-3 col-xl-2 px-0"},[_c('i',{staticClass:"kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon social-icon-kapsel"}),_vm._v(" "),_c('p',{staticClass:"body-2-semibold"},[_c('span',{staticClass:"capLbl"},[_vm._v(_vm._s(_vm.$t(_vm.product.productType)))]),_c('br'),_vm._v(" "),_c('ProductShowContentsByType',{attrs:{"product":_vm.product,"showLabel":false}})],1)]):_vm._e(),_vm._v(" "),(
        _vm.product.productType == 'Tropfen' ||
        _vm.product.productType == 'Drops' ||
        _vm.product.productType == 'drops'
      )?_c('div',{staticClass:"kpi-item text-center col-4 col-md-3 col-xl-2 px-0"},[_c('i',{staticClass:"kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon social-icon-fluessig"}),_vm._v(" "),_c('p',{staticClass:"body-2-semibold"},[_c('span',{staticClass:"capLbl"},[_vm._v(_vm._s(_vm.$t(_vm.product.productType)))]),_c('br'),_vm._v(" "),_c('ProductShowContentsByType',{attrs:{"product":_vm.product,"showLabel":false}})],1)]):_vm._e(),_vm._v(" "),(
        _vm.product.productType == 'Flasche' ||
        _vm.product.productType == 'bottle' ||
        _vm.product.productType == 'liquid'
      )?_c('div',{staticClass:"kpi-item text-center col-4 col-md-3 col-xl-2 px-0"},[_c('i',{staticClass:"kpi-icon-box kpi-icon-box--attributes mb-1 mx-auto bg-bone icon social-icon-fluessig"}),_vm._v(" "),_c('p',{staticClass:"body-2-semibold"},[_c('span',{staticClass:"capLbl"},[_vm._v(_vm._s(_vm.$t(_vm.product.productType)))]),_c('br'),_vm._v(" "),_c('ProductShowContentsByType',{attrs:{"product":_vm.product,"showLabel":false}})],1)]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }