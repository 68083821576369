<template>
  <div>
    <vsa-list itemscope itemtype="https://schema.org/FAQPage">
      <vsa-item
        v-for="item in accordeonItem"
        :key="item.title"
        itemscope
        itemprop="mainEntity"
        itemtype="https://schema.org/Question"
      >
        <vsa-heading>
          <div itemprop="name">
            {{ item.title }}
          </div>
        </vsa-heading>

        <vsa-icon>
          <i class="icon-chevron-down"></i>
        </vsa-icon>

        <vsa-content>
          <div
            class="acceptedAnswer"
            itemscope
            itemprop="acceptedAnswer"
            itemtype="https://schema.org/Answer"
          >
            <div class="content" itemprop="text" v-html="item.text" />
          </div>
        </vsa-content>
      </vsa-item>
    </vsa-list>

    <div
      class="d-flex justify-content-center mt-6 mt-lg-5"
      v-if="buttonText && buttonSrc"
    >
      <a :href="buttonSrc" class="btn btn-secondary">
        {{ buttonText }}
      </a>
    </div>
  </div>
</template>

<script>
import {
  VsaList,
  VsaItem,
  VsaHeading,
  VsaContent,
  VsaIcon,
} from 'vue-simple-accordion';

export default {
  name: 'Accordeon',

  data() {
    return {};
  },

  props: {
    accordeonItem: Array,
    sectionHeadline: String,
    layoutElemSpace: {
      type: String,
      default: 'my-6 my-lg-15',
    },
    layoutBgColor: {
      type: String,
      default: 'bg-white',
    },

    buttonText: String,
    buttonSrc: String,
  },

  components: {
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
    VsaIcon,
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.vsa-list {
  width: auto;
  max-width: inherit;
  border: 0 none;

  .vsa-item {
    position: relative;

    &::after {
      content: '';
      width: calc(100% + 2rem);
      height: 1px;
      position: absolute;
      bottom: 0;
      left: -1rem;
      display: block;
      background: $bone-83;
    }

    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid $bone-83;

      &::after {
        display: none;
      }
    }

    &:first-child {
      &::before {
        content: '';
        width: calc(100% + 2rem);
        height: 1px;
        position: absolute;
        top: 0;
        left: -1rem;
        display: block;
        background: $bone-83;
      }

      @include media-breakpoint-up(lg) {
        border-top: 1px solid $bone-83;

        &::before {
          display: none;
        }
      }
    }

    &__heading {
      border: 0 none;
    }

    &__trigger {
      padding: 1.5rem 2rem 1.5rem 0;
      width: 100%;
      text-align: left;
      background: transparent;
      color: $anthracite;
      position: relative;

      @include media-breakpoint-up(lg) {
        padding: 1.5rem 3rem 1.5rem 1rem;
      }

      &__content {
        font-weight: 600;
        font-size: 1rem;
      }

      &:hover,
      &:focus {
        background: transparent;
        color: $anthracite;
      }

      &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;

        @include media-breakpoint-up(lg) {
          right: 1.5rem;
        }

        [class*='icon-'] {
          font-size: 1.5rem;
        }
      }
    }

    &--is-active {
      .vsa-item__trigger__icon {
        [class*='icon-'] {
          &::before {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__content {
      margin-bottom: 0;
      padding: 0 0 1.5rem;

      @include media-breakpoint-up(lg) {
        padding: 0 2rem 1.5rem 1.5rem;
      }
    }
  }
}
</style>
