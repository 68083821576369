<template>
  <div class="table--wrapper">
    <div class="table--inner">
      <table class="table">
        <thead>
          <tr>
            <th
              v-for="th in tablecontent[0]"
              :key="th.tableHead"
              v-html="th"
              :style="columnWidthStyle"
            />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(tr, index) in tablecontent.slice(1)" :key="`tr${index}`">
            <td
              v-for="td in tr"
              :key="td.first"
              v-html="td"
              :style="columnWidthStyle"
            />
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'Table',
  props: {
    tablecontent: {
      type: [Object, Array],
      default: () => [],
    },
  },
  setup(props) {
    const columnWidthStyle = computed(() => {
      const numberOfColumns = props.tablecontent[0]?.length || 0;
      return {
        width: numberOfColumns > 0 ? `${100 / numberOfColumns}%` : 'auto',
      };
    });

    return {
      columnWidthStyle,
    };
  },
});
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.table {
  width: 100%;

  tr {
    th,
    td {
      font-size: 0.875rem;

      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
    }
  }

  thead {
    tr {
      background: $hazelgreen-90;
      border-top: 1px solid $bone-83;

      th {
        padding: 1rem 1rem 2rem;
        font-weight: 600;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $bone-83;

      td {
        padding: 1rem;
      }
    }
  }

  &--component {
    margin-top: 5rem;

    h5,
    .h5 {
      margin-bottom: 1.5rem;
    }
  }

  &--wrapper {
    position: relative;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(md) {
      overflow-x: inherit;
    }
  }

  &--inner {
    max-width: var(--bs-breakpoint-md);
    min-width: var(--bs-breakpoint-md);

    @include media-breakpoint-up(md) {
      max-width: inherit;
      min-width: inherit;
    }
  }
}
</style>
