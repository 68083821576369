<template>
  <ul class="iconList">
    <li v-for="icon in iconList" :key="icon.text" :class="icon.icon">
      <a :href="icon.src" v-if="icon.src">
        <p v-html="icon.headline" />
        <p v-html="icon.copy" />
      </a>

      <template :href="`tel.:${icon.src}`" v-else>
        <p v-html="icon.headline" />
        <p v-html="icon.copy" />
      </template>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'IconList',

  props: {
    iconList: {
      type: Array,
    },
  },
};
</script>
