<template>
  <div class="mt-0 blogSidebar row">
    <h4>{{ $t('Latest News') }}</h4>

    <template v-for="(item, i) in latestNewsArr">
      <div
        class="col-xs-12 col-md-6 col-lg-12 blogSidebar--item"
        v-if="item.uid != currentPostId && i < latestArticlesCount"
        :key="item.uid"
      >
        <h3 class="text-preset-details mb-0" v-if="item.title.length < 42">
          {{ item.title }}
        </h3>
        <h3 class="text-preset-details mb-0" v-else>
          {{ item.title.substring(0, 42) + '...' }}
        </h3>
        <p v-html="item.teaser"></p>
        <T3Link
          :href="item.slug"
          class="btn btn-text btn-icon"
          v-if="item.slug"
        >
          <template #content>
            <span>
              {{ $t('Read more') }}
              <i class="icon icon-arrow-right"></i>
            </span>
          </template>
        </T3Link>
      </div>
    </template>
  </div>
</template>

<script>
import T3Link from './General/T3Link.vue';

export default {
  name: 'blog-sidebar',
  props: {
    relatedNews: Array,
    currentPostId: Number,
    bodytext: String,
  },
  data() {
    return {
      el: '.blogSidebar--item',
    };
  },
  computed: {
    latestNewsArr() {
      return this?.relatedNews[0].content?.data?.list;
    },
    latestArticlesCount() {
      return this.bodytext.length > 1500 ? 3 : 2;
    },
  },
  components: { T3Link },
};
</script>

<style lang="scss" scoped>
@import 'assets/styles/misc/vars';

.blogSidebar {
  // padding-bottom: 4.375rem;
  // padding-top: 2rem;
  margin-top: 5.5rem;
  position: relative;

  // &::before {
  //   content: '';
  //   width: calc(100% + calc(var(--bs-gutter-x) * 1));
  //   height: .0625rem;
  //   position: absolute;
  //   top: 0;
  //   left: calc(-0.5 * var(--bs-gutter-x));
  //   display: block;
  //   background: $bone-83;
  // }

  h4 {
    margin-bottom: 1.875rem;
  }

  &--item {
    margin-bottom: 2.5rem;

    p {
      margin-bottom: 1.875rem;
    }

    .btn {
      padding: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-between(md, lg) {
    &--item {
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    // padding: 1.875rem 0 1.875rem 1.875rem;

    // &::before {
    //   width: .0625rem;
    //   height: 100%;
    // }
  }
}
</style>
