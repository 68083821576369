<template>
  <AutomaticSlider :news="newsTickerData" />
</template>

<script>
import AutomaticSlider from '~/components/AutomaticSlider.vue';
import { useMainNavStore } from '../stores/mainNavStore';
import { onBeforeMount, computed } from '@nuxtjs/composition-api';

export default {
  name: 'DynamicNewsticker',
  components: {
    AutomaticSlider,
  },
  setup() {
    const mainNavStore = useMainNavStore();
    // onBeforeMount(() => {
    //   mainNavStore.getNavData();
    // });
    let newsTickerData = computed(() => mainNavStore.newsTicker);

    return {
      newsTickerData,
    };
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/elements/newsletter.scss';
</style>
