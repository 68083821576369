<template>
  <div class="hero-slider">
    <div
      class="w-100 justify-content-between hero-icon-container d-none d-md-flex"
    >
      <button @click.prevent="slidePrev" type="button">
        <i class="icon-arrow-left icon-size-class"></i>
      </button>
      <button @click.prevent="slideNext" type="button">
        <i class="icon-arrow-right icon-size-class"></i>
      </button>
    </div>
    <VueSlickCarousel v-if="slides.length" ref="heroCarousel" v-bind="settings">
      <div
        class="slider-main"
        v-for="(slide, indx) in slides"
        :key="indx"
        :index="indx"
      >
        <div class="container">
          <div class="hero-slider--content">
            <div class="row">
              <div
                class="offset-xl-1 col-xl-10 col-xs-12 text-white"
                v-html="slide.text"
              />
            </div>
          </div>
        </div>
        <div class="image" v-if="slide.image">
          <template v-if="indx === 0">
            <template v-if="slide.imageMobile">
              <img
                v-if="$device.isMobile"
                v-lazy-load :data-src="slide.imageMobile"
                :alt="slide.imageAltText"
                data-not-lazy
                class="d-md-none"
              />
              <img
                v-else
                v-lazy-load :data-src="slide.image"
                :alt="slide.imageAltText"
                data-not-lazy
                class="d-none d-md-block"
              />
            </template>
            <img
              v-lazy-load :data-src="slide.image"
              :alt="slide.imageAltText"
              data-not-lazy
              v-else
            />
          </template>
          <template v-else>
            <template v-if="slide.imageMobile">
              <img
                v-if="$device.isMobile"
                v-lazy-load :data-src="slide.imageMobile"
                :alt="slide.imageAltText"
                v-lazy-load
                class="d-md-none"
              />
              <img
                v-else
                v-lazy-load :data-src="slide.image"
                :alt="slide.imageAltText"
                v-lazy-load
                class="d-none d-md-block"
              />
            </template>
            <img
              v-lazy-load :data-src="slide.image"
              :alt="slide.imageAltText"
              v-lazy-load
              v-else
            />
          </template>
        </div>
      </div>

      <template #customPaging="page">
        <div class="silk-dot-class" />
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  name: 'HeroSlider',
  data() {
    return {
      settings: {
        arrows: true,
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 7000,
      },
    };
  },

  props: {
    slides: {
      type: Array,
    },
  },

  components: {
    VueSlickCarousel,
  },

  methods: {
    slidePrev() {
      this.$refs.heroCarousel.prev();
    },
    slideNext() {
      this.$refs.heroCarousel.next();
    },
  },

  head() {
    // Check if there are slides and the first slide has an image
    if (this.slides.length && this.slides[0].image) {
      // Determine the image source based on the device type
      const imageUrl =
        this.$device.isMobile && this.slides[0].imageMobile
          ? this.slides[0].imageMobile
          : this.slides[0].image;

      return {
        link: [
          {
            rel: 'preload',
            as: 'image',
            href: imageUrl, // Use the determined image URL
          },
        ],
      };
    }
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';
@import 'assets/styles/misc/icons';
@import 'assets/styles/misc/typo';
@import 'assets/styles/misc/slick-slider';

.hero-slider {
  .hooper-slide {
    position: relative;
  }

  .slider-arrow-left {
    position: absolute !important;
    color: white !important;
    z-index: 9999 !important;
    left: 1.875rem !important;
  }

  .slider-arrow-right {
    position: absolute !important;
    color: white !important;
    z-index: 9999 !important;
    right: 1.875rem !important;
  }

  .hooper-list {
    margin: 0;
    left: inherit;

    max-height: 100vh; // use a max-width that wouldn't be exceeded.
    transition: all 0.1s; // use preferred transition time

    .hooper-track {
      .hooper-slide {
        height: 100%;
      }
    }
  }

  .hooper-pagination {
    margin: 0;
    position: absolute;
    bottom: 3rem;

    @include media-breakpoint-up(md) {
      bottom: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
      bottom: 3rem;
    }

    li {
      button {
        @include media-breakpoint-up(lg) {
          width: 6.25rem;
        }
      }
    }
  }

  .hooper-navigation {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }

    button {
      position: absolute;

      &::before {
        @extend .iconElement;
        content: $icon-arrow-right;
        display: block;
        color: $white;
        font-size: 2rem;
      }

      svg {
        display: none;
      }

      &.hooper-next {
        @include media-breakpoint-up(xl) {
          padding-right: 3.25rem;
        }
      }

      &.hooper-prev {
        @include media-breakpoint-up(xl) {
          padding-left: 3.25rem;
        }
      }

      &.hooper-prev {
        &::before {
          transform: rotate(180deg);
        }
      }
    }
  }

  &--content {
    position: relative;
    z-index: 1;
    padding: 8rem 0 4.75rem;

    @include media-breakpoint-up(md) {
      padding: 5.75rem 2.5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 9.4375rem 0 12.375rem;
    }

    @include media-breakpoint-up(lg) {
      width: 80%;
      max-width: 80%;
    }

    @include media-breakpoint-between(lg, xxl) {
      padding: 6.4375rem 2.5rem 8.375rem;
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3 {
      hyphens: auto;
      @extend .text-preset-large;

      @include media-breakpoint-up(lg) {
        hyphens: inherit;
      }
    }

    p {
      @include media-breakpoint-up(lg) {
        font-size: 1rem;
      }

      @include media-breakpoint-up(xxl) {
        font-size: 1.2rem;
      }

      .btn {
        width: 100%;
        display: block;
        text-align: center;
        margin-top: 1.5rem;

        ~ .btn {
          margin-top: 1rem;
        }

        @include media-breakpoint-up(md) {
          width: inherit;
          display: inline-block;
          margin-right: 1rem;

          ~ .btn {
            margin-top: 1.5rem;
            margin-right: 0;
          }
        }
      }
    }

    .row {
      [class*='col-'] {
        p:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.slider-main {
  position: relative !important;
}

.hero-slider {
  overflow: hidden !important;
  position: relative;

  .slick-slider {
    .image {
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100%;
      margin: 0 !important;
      z-index: 0 !important;
      background: $black !important;
      overflow: hidden;

      img {
        width: 100% !important;
        height: 100% !important;
        object-fit: cover !important;
        position: absolute !important;
        opacity: 0.75 !important;
      }
    }
  }

  .slider-control {
    position: relative !important;

    .hooper--controls {
      z-index: 1 !important;

      &::before {
        @extend .iconElement;
        content: $icon-arrow-right;
        display: block;
        color: $white;
        font-size: 2rem;
      }
    }
  }

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;

    div {
      height: 100%;
    }
  }
}

.icon-size-class {
  font-size: 2rem;
  color: $white;
}

.hero-icon-container {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  padding: 0;

  @include media-breakpoint-up(xxl) {
    padding: 0 3.25rem;
  }
}
</style>
