<template>
  <div class="">
    <div class="border-top border-bottom border-bone-83 mb-lg-4">
      <nav class="filter container">
        <ul class="blanklist d-flex my-2 font-weight-600 lh-1">
          <li class="d-inline-block py-1">
            <strong>{{ $t('Products') }}</strong>
            <strong class="text-hazelgreen"
              >&nbsp;{{ filteredProducts.length }}</strong
            >
          </li>
          <li
            class="d-inline-block border-start border-bone-83 py-0 ms-3 ps-1 text-hazelgreen text-uppercase"
          >
            <button
              class="btn d-flex px-2"
              :class="{ active: showFilter }"
              type="button"
              @click="toggleFilter()"
            >
              <span class="icon-sliders me-1">&nbsp;</span>
              <strong
                >Filter
                <small v-if="activeFilters"
                  >({{ activeFilters }})</small
                ></strong
              >
            </button>
          </li>
        </ul>
      </nav>
    </div>
    <div class="container">
      <div class="row mx-lg-n2">
        <aside class="col-sm-12 col-md-6 col-lg-4 col-xl-3" v-show="showFilter">
          <div class="box bg-sand p-2 shadow my-3">
            <p class="text-end mb-0">
              <button type="button" @click="toggleFilter()" class="pe-0">
                <i class="icon-x"></i>
              </button>
            </p>
            <fieldset>
              <label for="filter-sort" class="form-label">{{
                $t('Sort by')
              }}</label>
              <select
                name="filter-sort"
                id="filter-sort"
                class="form-select"
                v-model="sort"
              >
                <option value="asc-manualsorting" v-if="manualSorting === 1">
                  {{ $t('Sort: Relevance') }}
                </option>
                <option value="asc-title">{{ $t('Sort: Name A-Z') }}</option>
                <option value="desc-title">{{ $t('Sort: Name Z-A') }}</option>
                <option value="desc-uid">{{ $t('New Products') }}</option>
                <option value="asc-price">
                  {{ $t('Sort: Price from low to high') }}
                </option>
                <option value="desc-price">
                  {{ $t('Sort: Price from high to low') }}
                </option>
              </select>
            </fieldset>
            <template v-if="filters.length > 1">
              <hr class="my-3 border-bone-83" />
              <legend class="form-label">{{ $t('Categories') }}</legend>
              <template v-for="(filter, i) in filters">
                <fieldset
                  class="form-check"
                  v-if="i < filterToShow && filter.productCount > 0"
                  :key="filter.title"
                >
                  <input
                    type="checkbox"
                    :id="`filter-${filter.uid}`"
                    :name="`filter-${filter.uid}`"
                    :value="filter.uid"
                    class="form-check-input"
                    v-model="selectedFilter"
                  />
                  <label
                    class="form-check-label"
                    :for="`filter-${filter.uid}`"
                    >{{ filter.title }}</label
                  >
                </fieldset>
              </template>
              <p class="text-center mt-2" v-if="filterToShow < filters.length">
                <button
                  type="button"
                  @click="setFilterToShow(filters.length)"
                  class="text-primary text-uppercase"
                >
                  {{ $t('show more') }} <i class="icon-chevron-down"></i>
                </button>
              </p>
            </template>
            <hr class="my-3 border-bone-83" />
            <legend class="form-label">Nutrition</legend>
            <template v-for="(nutrition, i) in nutritions">
              <fieldset
                class="form-check"
                v-if="i < nutritionToShow"
                :key="nutrition.title"
              >
                <input
                  type="checkbox"
                  :id="`nutrition-${nutrition.uid}`"
                  :name="`nutrition-${nutrition.uid}`"
                  :value="nutrition.uid"
                  class="form-check-input"
                  v-model="selectedNutritions"
                />
                <label :for="`nutrition-${nutrition.uid}`">{{
                  nutrition.title
                }}</label>
              </fieldset>
            </template>
            <p
              class="text-center mt-2"
              v-if="nutritionToShow < nutritions.length"
            >
              <button
                type="button"
                @click="setNutritionToShow(nutritions.length)"
                class="text-primary text-uppercase"
              >
                {{ $t('show more') }} <i class="icon-chevron-down"></i>
              </button>
            </p>
            <hr class="my-3 border-bone-83" />
            <IconList
              :iconList="[
                {
                  headline:
                    'Alle unsere Produkte sind natürlich und frei von künstlichen Zusatzstoffen sowie Schadstoffen.',
                  icon: 'icon-heart',
                },
              ]"
            />
            <hr class="my-3 border-bone-83" />
            <fieldset>
              <button
                type="button"
                class="btn btn-outline border-hazelgreen text-hazelgreen"
                @click="resetFilter()"
              >
                {{ $t('Reset') }}
              </button>
            </fieldset>
          </div>
        </aside>
        <div class="col-sm-12 col-md overflow-hidden">
          <div class="row">
            <div
              :class="getListClasses()"
              v-for="(product, i) in filteredProducts"
              :key="product.uid"
            >
              <ProductCard v-if="i < showItems" :class="i" :product="product" />
            </div>
          </div>

          <div class="row mt-3">
            <div
              class="col-xs-12 d-flex justify-content-center"
              v-if="showItems < filteredProducts.length"
            >
              <button
                type="button"
                class="btn btn-secondary d-block d-lg-none w-100"
                @click="setShowItems(filteredProducts.length)"
              >
                {{ $t('Load more') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary d-none d-lg-block"
                @click="setShowItems(filteredProducts.length)"
              >
                {{ $t('Load more') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from '@nuxtjs/composition-api';

import ProductCard from './ProductCard.vue';
import RatioImage from './RatioImage.vue';
import IconList from './IconList.vue';
import ProductSubConfigModal from '~/components/ProductSubConfigModal.vue';

export default {
  name: 'ProductGridSection',
  props: {
    sectionHeadline: String,
    contentProductList: Array,
    filters: Array,
    nutritions: Array,
    manualSorting: Number,
    showAllProducts: {
      type: Boolean,
      default: false,
    },
    layoutElemSpace: {
      type: String,
      default: 'my-6 my-lg-15',
    },
    layoutBgColor: {
      type: String,
      default: 'bg-white',
    },
  },
  components: { RatioImage, ProductCard, IconList, ProductSubConfigModal },
  methods: {
    outOfStock(e) {
      console.log('PRODUCT GRID SECTION - outOfStock');
      this.$emit('outOfStock');
    },
    getFilter(filters) {
      let returnValue = [];
      filters.forEach((filter) => {
        returnValue.push(filter.uid);
      });
      return returnValue;
    },
    getTotalProducts() {
      return 24;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    getListClasses() {
      if (this.showFilter) {
        return 'col-12 col-md-12 col-lg-6 col-xl-4';
      } else {
        return 'col-12 col-md-6 col-lg-4 col-xl-3';
      }
    },
    setFilterToShow(i) {
      this.filterToShow = i;
    },
    setNutritionToShow(i) {
      this.nutritionToShow = i;
    },
    resetFilter() {
      this.selectedFilter = [];
      this.selectedNutritions = [];
      this.sort = 'desc-uid';
    },
    sortList(list) {
      const orderBy = this.orderBy,
        order = this.order;
      if (list && orderBy !== 'manualsorting') {
        let returnList = list.sort((a, b) => {
          const fa = a[orderBy],
            fb = b[orderBy];

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        if (order === 'desc') {
          return returnList.reverse();
        } else {
          return returnList;
        }
      } else {
        return this.initialList;
      }
    },

    setShowItems(count) {
      const total = this.filteredProducts.length;

      if (this.showItems + count < total) {
        this.showItems = this.showItems + count;
      } else {
        this.showItems = total;
      }
    },
  },

  computed: {
    filteredProducts: function () {
      var vm = this;

      const filter = vm.selectedFilter.map(function (x) {
        return parseInt(x, 10);
      });
      const nutritions = vm.selectedNutritions.map(function (x) {
        return parseInt(x, 10);
      });

      if (filter.length || nutritions.length) {
        vm.activeFilters = filter.length + nutritions.length;
        const checkFilter = vm.contentProductList.filter((product) =>
          product.categories.some((cat) => filter.includes(cat.uid)),
        );
        const checkNutritions = vm.contentProductList.filter((product) =>
          product.nutritions.some((cat) => nutritions.includes(cat.uid)),
        );

        let returnObj = Object.assign(checkFilter, checkNutritions);
        var unique = returnObj.filter((x, i) => returnObj.indexOf(x) === i);

        return vm.sortList(unique);
      } else {
        vm.activeFilters = 0;

        return vm.sortList(vm.contentProductList);
      }
    },
  },

  data() {
    return {
      showFilter: false,
      filterToShow: 5,
      nutritionToShow: 5,
      selectedFilter: [],
      selectedNutritions: [],
      activeFilters: 0,
      sort: this.manualSorting === 1 ? 'asc-manualsorting' : 'asc-title',
      orderBy: this.manualSorting === 1 ? 'manualsorting' : 'title',
      order: 'asc',
      showTotal: 0,
      showItems: 0,
      maxItems: 12,
      initialList: [],
    };
  },
  watch: {
    sort() {
      this.order = this.sort.split('-')[0];
      this.orderBy = this.sort.split('-')[1];
    },
  },
  created() {
    this.initialList = [...this.contentProductList];
    if (this.showAllProducts) {
      this.setShowItems(this.contentProductList.length);
    } else {
      this.setShowItems(this.maxItems);
    }
  },
  setup() {
    const openProdSubConfigModal = (sku) => {
      prodSubConfigSku.value = sku;
      isProdSubConfigModalOpen.value = true;
    };

    const closeProdSubConfigModal = () => {
      isProdSubConfigModalOpen.value = false;
    };

    const isProdSubConfigModalOpen = ref(false);
    const prodSubConfigSku = ref('');

    return {
      prodSubConfigSku,
      openProdSubConfigModal,
      isProdSubConfigModalOpen,
      closeProdSubConfigModal,
    };
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/styles/misc/vars';

@include media-breakpoint-up(lg) {
  .mx-lg-n2 {
    margin-right: -$spacer-2;
    margin-left: -$spacer-2;
  }
}

.filter {
  .btn {
    border: 0;
    // margin-top: -3px;
    // margin-bottom: -3px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border: 0 !important;
    box-shadow: none !important;

    &:hover {
      background-color: $sand;
    }

    &.active {
      background: $bone-83;

      span {
        transform: scale(1, -1);
      }
    }
  }
}
</style>
