<template>
  <div class="row" :class="rowDirection">
    <div class="col-12 col-lg-5" @click="$emit('onClickEnlargeImage')">
      <slot name="mediaSide"></slot>
    </div>
    <div class="col-12 col-lg-6" :class="paddingTextSide">
      <slot name="textSide"></slot>
    </div>
  </div>
</template>

<script>
import RatioImage from './RatioImage.vue';
import TextContentBlock from './TextContentBlock.vue';
export default {
  name: 'TextMediaTeaser',
  components: { RatioImage, TextContentBlock },
  props: {
    layoutElemSpace: {
      type: String,
      default: 'my-6 my-lg-15',
    },
    rowreverse: {
      type: Boolean,
      default: false,
    },
    element: {
      type: Object,
    },
    gallery: {
      type: Object,
    },
  },
  computed: {
    rowDirection() {
      return [
        this.rowreverse ? 'flex-row-reverse justify-content-lg-between' : '',
      ];
    },
    paddingTextSide() {
      return [this.rowreverse ? 'pe-lg-5' : 'ps-lg-5'];
    },
  },
};
</script>
