























































































































































































































































































































































































































































import {
  defineComponent,
  ref,
  watch,
  onMounted,
  computed,
  useContext,
  nextTick,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {
  SfInput,
  SfButton,
  SfCheckbox,
  SfLoader,
  SfSelect,
  SfTextarea,
} from '@storefront-ui/vue';
import { required, email, confirmed, min } from 'vee-validate/dist/rules';
import {
  customerPasswordRegExp,
  invalidPasswordMsg,
} from '~/modules/customer/helpers/passwordValidation';
import { RegisterFormFields, FormName } from './types';
import { useCountrySearch } from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';
import addressGetter from '~/modules/customer/getters/addressGetter';
import type { Country } from '~/modules/GraphQL/types';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('confirmed', {
  ...confirmed,
  message: 'Value not matched',
});

extend('min', {
  ...min,
  message: 'The field should have at least {length} characters',
});

extend('password', {
  message: invalidPasswordMsg,
  validate: (value: string) => customerPasswordRegExp.test(value),
});

export default defineComponent({
  name: 'RegistrationForm',
  components: {
    SfInput,
    SfButton,
    SfCheckbox,
    SfLoader,
    SfTextarea,
    SfSelect,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    form: {
      type: Object as PropType<RegisterFormFields>,
      required: false,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    showRecaptcha: {
      type: Boolean,
      default: false,
    },
    element: {
      type: Object,
      required: false,
    },
  },
  setup(props, { emit }) {
    const lastStep = 2;
    const formRef = ref(null);
    const formCopy = ref<RegisterFormFields>();

    const { loading: userLoading, isCustomerEmailAvailable } = useUser();

    const { load: loadCountries } = useCountrySearch();

    watch(
      () => props.form,
      (newForm) => {
        formCopy.value = { ...newForm };
      },
      { immediate: true, deep: true },
    );
    const changeForm = (formToNavigateTo: FormName) => {
      emit('change-form', formToNavigateTo);
    };

    const getPageUrl = () => {
      return window.location.href;
    };

    const countries = ref<Country[]>([]);
    const countriesList = computed(() =>
      addressGetter.countriesList(countries.value),
    );
    // const { selectedStore } = useMagentoConfiguration();
    // const countriesList = computed(() => {
    // 	// #TS-704 : ch shop only CH country
    // 	return ["de_ch","en_ch","fr_ch"].includes(selectedStore.value) ?
    // 		[{id: 'CH', label: app.i18n.t('Switzerland') as string, englishLabel: 'Switzerland', abbreviation: 'CH'}]
    // 		: addressGetter.countriesList(countries.value);
    // });

    onMounted(async () => {
      let loadedCountries = await loadCountries();
      countries.value = loadedCountries;
    });

    const isValidEmail = ref(false);

    const isEmailAvailable = async () => {
      let result = await isCustomerEmailAvailable(formCopy.value.email);
      isValidEmail.value = result;
      return result;
    };

    const step = ref(1);
    const isCompany = ref(false);

    const handleChangeCompany = (val) => {
      isCompany.value = val;
    };

    const stepHandle = async (stepValue: number) => {
      if (stepValue < step.value) {
        step.value = stepValue;
        await nextTick();
        formRef.value.reset();
      }
    };

    const onSubmit = async () => {
      try {
        const isFormValid = await formRef.value.validate();
        if (!isFormValid) return;

        if (step.value === lastStep) {
          emit('submit', formCopy.value);
        } else {
          let result = await isCustomerEmailAvailable(formCopy.value.email);

          if (result) {
            step.value += 1;
          }
        }
      } catch (e) {}
    };

    return {
      lastStep,
      formRef,
      handleChangeCompany,
      isCompany,
      userLoading,
      countriesList,
      formCopy,
      changeForm,
      step,
      isValidEmail,
      isEmailAvailable,
      getPageUrl,
      stepHandle,
      onSubmit,
    };
  },
});
