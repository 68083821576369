<template>
  <div class="card" :class="[layoutProduct, layoutElemSpace]" v-if="card">
    <T3Link :href="getLink()" :target="getTarget()">
      <template #content>
        <div class="image-wrapper" v-if="getImageSrc()">
          <RatioImage :elemClass="'ratio-1x1 m-0'" :imgSrc="getImageSrc()" />
          <div class="card--button-hidden d-none" v-if="getLink()">
            <button class="btn btn-secondary">
              {{ getButtonText() }}
            </button>
          </div>
        </div>
        <div class="card--button">
          <i :class="card.icon"></i>
          <span class="text-preset-details">
            {{ getTitle() }}
          </span>
        </div>
        <div class="card--body">
          <p class="card--headline d-none" v-html="getTitle()" />
          <div class="card--details" v-html="getText()" />
          <p
            v-html="getProductCount()"
            v-show="layoutProduct !== 'categories'"
          />
        </div>
      </template>
    </T3Link>
  </div>
</template>

<script>
import T3Link from '~/components/General/T3Link.vue';
import RatioImage from './RatioImage.vue';

export default {
  name: 'Card',
  methods: {
    getImageSrc() {
      if (this.card?.image) {
        return this.card.image;
      } else if (this.card?._source?.image) {
        return this.card._source.image;
      } else {
        return '';
      }
    },
    getText() {
      if (this?.card?.text) {
        return this?.card?.text?.substring(0, 92) + '...';
      }
      if (this?.card?.description) {
        return this?.card?.description?.substring(0, 92) + '...';
      }
      if (this?.card?.subtitle) {
        return this?.card?.subtitle;
      }
      if (this.card?._source?.content) {
        return this.card._source.content?.substring(0, 92) + '...';
      }
    },
    getProductCount() {
      if (this?.card?.productCount) {
        return (
          this?.card?.productCount +
          ' ' +
          this.$tc('Product_s', this?.card?.productCount)
        );
      }
      if (this.card?._source?.productsCount) {
        return this?.card?._source?.productsCount + ' ' + this.$t('Products');
      }
    },
    getTitle() {
      if (this?.card?.headline) {
        return this?.card?.headline;
      }
      if (this?.card?.title) {
        return this?.card?.title;
      }
      if (this?.card?.name) {
        return this?.card?.name;
      }
      if (this.card?._source?.title) {
        return this.card._source.title;
      }
    },
    getLink() {
      if (this?.card?.link) {
        return this?.card?.link;
      }
      if (this?.card?.detailLink) {
        return this?.card?.detailLink;
      }
      if (this.card?._source?.url) {
        return this.card._source.url;
      }
    },
    getButtonText() {
      if (this?.card?.button) {
        return this?.card?.button;
      } else {
        return this.$t('Show');
      }
    },
    getTarget() {
      if (this?.card?.linkConfig) {
        return this?.card?.linkConfig?.target;
      }
    },
  },
  props: {
    card: {
      type: Object,
    },

    layoutProduct: {
      type: String,
      default: '',
    },

    layoutElemSpace: {
      type: String,
      default: 'mb-9 mb-lg-0',
    },
  },

  components: {
    RatioImage,
    T3Link,
  },
};
</script>

<style lang="scss" scoped>
.card::v-deep {
  .card--details {
    > p {
      display: inline;
    }
  }
}
</style>
