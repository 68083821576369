<template>
  <div class="trustSignals">
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  name: 'TrustSignals',
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';
.trustSignals {
  figure.image {
    margin-bottom: 1.25rem;
  }

  margin-bottom: 1.5rem;

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  h3,
  .h3,
  h5,
  .h5 {
    margin-bottom: 1.25rem;
  }
  .image {
    [class*='icon'] {
      background-color: $bone-83;
      color: $anthracite;
      font-size: 1.5rem;
      line-height: 3.5rem;
      width: 3.5rem;
      text-align: center;
      border-radius: 50%;
      display: inline-block;
    }
  }
}
</style>
