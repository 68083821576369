<template>
  <div class="row notify-banner--content">
    <div class="col-lg-6 col-xs-12 d-flex align-items-center">
      <slot name="left" />
    </div>
    <div
      class="col-lg-6 col-xs-12 d-flex justify-content-start justify-content-lg-end"
    >
      <slot name="right" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotificationElementContent',
  props: {
    layoutBgColor: {
      type: String,
      default: 'bg-white',
    },
    notifyText: String,
    arrowTrue: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    arrowTrueFunction() {
      return [this.arrowTrue ? 'd-inline-block' : ''];
    },
  },
};
</script>
<style lang="scss">
@import 'assets/styles/misc/vars';

.notify-banner {
  &--content {
    p {
      margin: 0;
      font-weight: 600;
    }

    .btn {
      margin-top: 1.5rem;
      width: 100%;
      text-align: center;
    }

    @include media-breakpoint-up(md) {
      .btn {
        width: auto;
      }
    }

    @include media-breakpoint-up(lg) {
      [class*='col-'] {
        div {
          display: flex;
          align-items: center;
        }
      }

      .btn {
        margin-top: 0;
        height: fit-content;
      }
    }
  }
}
</style>
