<template>
  <form
    :action="formAction"
    method="post"
    @submit.prevent="sendForm"
    ref="form"
    novalidate
    :id="`c${data.id}`"
    v-show="!isSent"
    enctype="multipart/form-data"
    class="form_contact"
    :name="getFormName()"
  >
    <a :href="`#c${data.id}`" class="anchor" ref="anchor"></a>
    <div v-html="getFormHeading(data)"></div>
    <div v-if="error" v-html="error" />
    <div
      class="inline-thank-you"
      v-if="inlineThankYouMessage"
      v-html="inlineThankYouMessage"
    />
    <fieldset
      v-for="page in fieldData"
      :key="page.title + '-' + page.pageId"
      class="row"
    >
      <template v-for="form in page.fields" v-if="form.field.type !== 'hidden'">
        <div
          class="fieldset-wrapper col-xs-12 align-self-start"
          :class="checkHalfWidth(form.field.css)"
          :key="form.placeholder"
        >
          <div class="form-group">
            <small
              class="text-danger mt-1"
              v-if="form.field.validationClass"
              v-html="
                form.field.additionalAttributes[
                  'data-powermail-required-message'
                ]
              "
            />
            <small
              class="text-danger mt-1"
              v-if="form.field.validationClass"
              v-html="
                form.field.additionalAttributes['data-powermail-error-message']
              "
            />
            <small
              class="text-danger mt-1"
              v-if="form.field.errorClass"
              v-html="
                form.field.additionalAttributes[
                  'data-powermail-powermailfilesize-message'
                ]
              "
            />

            <template
              v-if="form.field.type === 'text' && form.field.css === 'hidden'"
            >
              <input
                v-if="form.field.marker === 'user_email'"
                v-on:blur.prevent="sendConditions"
                type="text"
                class="d-none"
                :value="getUserEmail(form.field.value)"
                :name="form.field.name"
                :id="form.field.name"
              />
              <input
                v-else
                v-on:blur.prevent="sendConditions"
                type="text"
                class="d-none"
                :value="form.field.value"
                :name="form.field.name"
                :id="form.field.name"
              />
            </template>

            <input
              :disabled="isSending"
              v-on:blur.prevent="sendConditions"
              @change="form.field.validationClass = ''"
              :class="checkValidationClass(form.field.validationClass)"
              v-else-if="
                form.field.type === 'text' ||
                form.field.type === 'email' ||
                form.field.type === 'tel'
              "
              :type="form.field.type"
              :id="getFieldId(form)"
              :placeholder="form.field.placeholder"
              :required="form.field.label.mandatory"
              :name="form.field.name"
              v-model="form.field.value"
            />

            <div v-else-if="form.field.type === 'radio'">
              <div
                class="d-flex align-item-center radioContainer"
                v-for="(option, i) in form.field.options"
                :key="option.value"
              >
                <input
                  :disabled="isSending"
                  @change="onInputCheckboxRadioChange(form)"
                  :class="checkValidationClass(form.field.validationClass)"
                  class="form-check-input formRadio"
                  :id="form.field.name + '[' + i + ']'"
                  :type="form.field.type"
                  :required="form.field.label.mandatory"
                  :name="form.field.name"
                  :value="option.value"
                  :checked="option.checked"
                />
                <LabelComponent
                  v-if="option.label"
                  :for="form.field.name + '[' + i + ']'"
                  :label="option.label"
                  :class="form.field.label.labelClasses"
                />
              </div>
            </div>

            <div class="checkContainer" v-else-if="form.field.type === 'check'">
              <div
                v-for="(option, i) in form.field.options"
                :key="option.label"
              >
                <input
                  :disabled="isSending"
                  @change="onInputCheckboxRadioChange(form)"
                  type="checkbox"
                  :class="checkValidationClass(form.field.validationClass)"
                  class="form-check-input formCheck"
                  :id="form.field.name + '[' + i + ']'"
                  :name="form.field.name"
                  :value="option.value"
                  :required="form.field.label.mandatory"
                  :checked="option.checked"
                />

                <LabelComponent
                  v-if="option.label"
                  :for="form.field.name + '[' + i + ']'"
                  :label="option.label"
                  :class="form.field.label.labelClasses"
                  :required="form.field.label.mandatory"
                />
              </div>
            </div>

            <div
              class="uploadContainer"
              v-else-if="form.field.type === 'file'"
              :class="checkValidationClass(form.field.errorClass)"
            >
              <input
                :disabled="isSending"
                v-on:blur.prevent="sendConditions"
                @change="onFileChange($event, form)"
                :type="form.field.type"
                :id="getFieldId(form)"
                :placeholder="form.field.placeholder"
                :required="form.field.label.mandatory"
                :name="form.field.name"
                hidden
              />
              <p class="uploadPlaceholder">
                {{ form.field.label.description }}
              </p>
              <span class="icon-upload" @click="uploadFile(form)"></span>
            </div>

            <select
              :disabled="isSending"
              @change="form.field.validationClass = ''"
              :class="checkValidationClass(form.field.validationClass)"
              v-else-if="form.field.type === 'select'"
              :name="form.field.name"
              :id="getFieldId(form)"
              :required="form.field.label.mandatory"
            >
              <option
                :value="option.value"
                v-for="option in form.field.options"
                :key="option.name"
                :selected="option.isSelected"
              >
                {{ option.label }}
              </option>
            </select>

            <textarea
              :disabled="isSending"
              @change="form.field.validationClass = ''"
              :class="checkValidationClass(form.field.validationClass)"
              v-else-if="form.field.type === 'textarea'"
              :id="getFieldId(form)"
              :placeholder="form.placeholder"
              :required="form.field.label.mandatory"
              :name="form.field.name"
              v-model="form.field.value"
            />

            <small
              v-else-if="form.field.type === 'html'"
              v-html="form.field.text"
            />

            <button
              type="submit"
              v-else-if="form.field.type === 'submit'"
              class="btn btn-primary"
              :class="form.field.submitClasses"
              :disabled="isSending"
            >
              {{ form.field.title }}
              <i class="icon-loader isLoading" v-show="isSending"></i>
            </button>

            <LabelComponent
              v-if="form.field.label && form.field.css !== 'hidden'"
              :for="getFieldId(form)"
              :label="form.field.label.title"
              :mandatory="form.field.label.mandatory"
              :class="form.field.label.labelClasses"
            />
          </div>
        </div>
      </template>
    </fieldset>

    <template v-for="hiddenField in data.content.data.form.fields.hiddenFields">
      <input
        type="hidden"
        v-on:blur.prevent="sendConditions"
        :value="hiddenField.value"
        :name="hiddenField.name"
        :key="hiddenField.name"
      />
    </template>

    <!-- Hidden inputs for Out of stock contact form -->
    <template v-for="page in fieldData">
      <template v-for="hiddenField in page.fields">
        <input
          v-if="hiddenField.field.type === 'hidden'"
          type="hidden"
          :value="hiddenField.field.value"
          :name="hiddenField.field.name"
          :key="hiddenField.field.name"
        />
      </template>
    </template>

    <input
      v-on:blur.prevent="sendConditions"
      type="hidden"
      :value="data.id"
      name="responseElementId"
    />
    <input
      v-on:blur.prevent="sendConditions"
      type="hidden"
      value="1"
      name="responseElementRecursive"
    />

    <!-- Form Transaction ID -->
    <input
      v-on:blur.prevent="sendConditions"
      type="hidden"
      class="custom_payload form_transaction_id"
      value=""
    />

    <!-- GA Client Id -->
    <input
      v-on:blur.prevent="sendConditions"
      type="hidden"
      class="custom_payload ga_client_id"
      value=""
    />

    <!-- GCLID -->
    <input
      v-on:blur.prevent="sendConditions"
      type="hidden"
      class="custom_payload gclid"
      value=""
    />

    <!-- FBCLID -->
    <input
      v-on:blur.prevent="sendConditions"
      type="hidden"
      class="custom_payload fbclid"
      value=""
    />

    <!-- UTM traffic source -->
    <input
      v-on:blur.prevent="sendConditions"
      type="hidden"
      class="custom_payload utm_traffic_source"
      value=""
    />

    <!-- Page Url -->
    <input
      v-on:blur.prevent="sendConditions"
      type="hidden"
      class="custom_payload page_url"
      name="page_url"
      :value="getPageUrl()"
    />
  </form>
</template>

<script>
import axios from 'axios';
import InputComponent from '~/components/InputComponent';
import SelectComponent from '~/components/SelectComponent';
import LabelComponent from '~/components/LabelComponent';
import TextareaComponent from '~/components/TextareaComponent';
import { useRouter, useContext, ref, onMounted } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import PowermailCondition from '~/helpers/powermailConditions';

export default {
  name: 'ContactForm',

  components: {
    LabelComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
  },

  setup(props, context) {
    const form = ref(null);
    const anchor = ref(null);
    const fieldData = ref(null);
    const isSending = ref(false);
    const isSent = ref(false);
    const error = ref(null);
    const inlineThankYouMessage = ref(null);
    const formAction = ref('');
    const redirectURL = ref('');
    const formData = ref(new FormData());
    let formConditionData = ref(new FormData());
    const router = useRouter();
    const { app, $axios } = useContext();
    const { isAuthenticated, user } = useUser();

    onMounted(() => {
      sendConditions();
    });

    formAction.value = props.data?.content?.data?.form?.fields?.action;
    fieldData.value = props.data?.content?.data?.form?.fields?.pages;

    const formRedirect = async () => {
      await router.push(
        app.localeRoute({ name: 'typo3', params: { slug: redirectURL.value } }),
      );
    };

    const getPageUrl = () => {
      return window.location.href;
    };

    const onFileChange = ($event, form) => {
      form.field.errorClass = '';
      form.field.label.description = $event?.target?.files[0].name;
    };

    const onInputCheckboxRadioChange = (form) => {
      sendConditions();
      form.field.validationClass = '';
    };

    const getFormName = () => {
      switch (props.data?.content?.data?.form?.title) {
        case 'Event Registration':
          return 'form_seminar';
        case 'Fachpersonal Kontoüberprüfung':
          return 'form_b2b_verification';
        case 'Verify your professional account':
          return 'form_b2b_verification';
      }
    };

    const getUserEmail = (email) => {
      if (isAuthenticated.value && user.value !== null && user.value.email) {
        return user.value.email;
      }

      if (email) {
        return email;
      } else {
        return localStorage.getItem('email');
      }
    };

    const sendForm = async () => {
      Array.from(form.value.elements).forEach((input) => {
        if (
          input?.attributes?.type?.value === 'radio' ||
          input?.attributes?.type?.value === 'checkbox'
        ) {
          if (input.checked) {
            formData.value.append(input.name, input.value);
          }
        } else if (input?.attributes?.type?.value === 'file') {
          if (input.files.length > 0) {
            formData.value.append(input.name, input.files[0]);
          }
        } else {
          formData.value.append(input.name, input.value);
        }
      });
      const proxyURL = '/typo3-api' + formAction.value;
      isSending.value = true;

      axios
        .post(proxyURL, formData.value)
        .then((res) => {
          isSending.value = false;
          error.value = '';
          inlineThankYouMessage.value = '';

          fieldData.value = res.data?.content?.data?.form?.fields?.pages;
          if (!fieldData.value) {
            app.$gtm.push({
              event: 'form_submitted',
              form_submitted_html: form.value,
            });

            if (res.data?.content?.data) {
              inlineThankYouMessage.value = res.data?.content?.data;
              return;
            }
          } else {
            return;
          }

          // redirectURL.value = res.data.slug.replace(/^\//, '')
          isSent.value = true;

          // formRedirect();
        })
        .catch((error) => {
          console.log(error);
          isSending.value = false;
        });
    };

    const sendConditions = async () => {
      //take responseElementId and responseElementRecursive OUT from the body
      const proxyURL = '/typo3-api/de/condition.json';

      Array.from(form.value.elements).forEach((input) => {
        if (
          input?.name !== 'responseElementId' &&
          input?.name !== 'responseElementRecursive'
        ) {
          if (
            input?.attributes?.type?.value === 'radio' ||
            input?.attributes?.type?.value === 'checkbox'
          ) {
            if (input.checked) {
              formConditionData.value.append(input.name, input.value);
            }
          } else if (input?.attributes?.type?.value === 'file') {
            if (input.files.length > 0) {
              formConditionData.value.append(input.name, input.files[0]);
            }
          } else {
            formConditionData.value.append(input.name, input.value);
          }
        }
      });

      let powermailConditionAction = new PowermailCondition(form.value);

      axios
        .post(proxyURL, formConditionData.value)
        .then((res) => {
          formConditionData = ref(new FormData());

          powermailConditionAction.processActions(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return {
      form,
      sendForm,
      sendConditions,
      formAction,
      isSending,
      fieldData,
      isSent,
      error,
      inlineThankYouMessage,
      onFileChange,
      onInputCheckboxRadioChange,
      getUserEmail,
      getFormName,
      getPageUrl,
    };
  },

  props: {
    data: Object,
    formItem: Array,
    buttonHref: String,
    buttonText: String,
    formHeadline: String,
  },

  methods: {
    getFieldId(form) {
      return form?.field?.id;
    },
    checkHalfWidth(halfWidth) {
      if (halfWidth == 'halfWidth') {
        return 'col-lg-6';
      }
    },
    getFields(data) {
      return data?.content?.data?.form?.fields?.pages;
    },
    getFormAction(data) {
      return data?.content?.data?.form?.fields?.action;
    },
    checkValidationClass(validation) {
      if (validation) {
        return 'border-danger';
      }
    },
    getFormHeading(data) {
      return data ? data.content?.bodytext : '';
    },
    uploadFile(form) {
      document.getElementById(form?.field?.id)?.click();
    },
    getEventLink() {
      return location.href;
    },
  },
};
</script>
<style lang="scss" scoped>
.anchor {
  display: block;
  position: relative;
  top: -10rem;
}
</style>
<style lang="scss">
@import 'assets/styles/misc/vars';
@import 'assets/styles/misc/icons';

.icon-loader.isLoading {
  animation: loader 1.2s linear infinite;
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

//Inline thank-you message.
.inline-thank-you {
  margin: 150px 0 150px 0;
  text-align: center;
  p {
    font-size: 1.5rem;
  }
}

form {
  .form-group {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1.5rem;
    position: relative;

    label {
      font-size: 0.875rem;
      font-weight: 600;
      margin: 0 $border-radius $border-radius;
    }

    input,
    textarea,
    select {
      padding: 0.65625rem 1rem;
      border: 1px solid $bone-83;
      border-radius: $border-radius;
    }

    textarea {
      resize: none;
      height: 6.25rem;
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;

      ~ label {
        position: relative;

        &::before {
          @extend .iconElement;
          content: $icon-chevron-down;
          display: block;
          position: absolute;
          top: calc(100% + 1.0625rem);
          right: 0.4375rem;
          font-size: 1.5rem;
          color: $hazelgreen;
        }
      }
    }

    .btn.btn-primary {
      width: 100%;
      margin-top: 1.5rem;
      text-align: center;

      @include media-breakpoint-up(md) {
        width: fit-content;
      }
    }
  }
}

.uploadContainer {
  padding: 0.65625rem 1rem;
  border: 1px solid $bone-83;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .uploadPlaceholder {
    margin-bottom: 0;
    color: $anthracite;
  }
  span {
    cursor: pointer;
  }
}

.radioContainer {
  margin-right: 1rem;
  .formRadio {
    padding: 0px !important ;
  }
}
.checkContainer {
  .formsCheck {
    padding: 6px !important;
  }
}
</style>
