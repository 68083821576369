var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.itemsContent),function(ce){return [(ce)?_c('section',{key:ce.id,class:!_vm.itemsEventDetailsOnlyContent
          ? ce.type + _vm.getBg(ce) + _vm.getSpacing(ce)
          : '',attrs:{"id":_vm.getID(ce.id)}},[(
          ce.type == 'structured_content' && !_vm.itemsEventDetailsOnlyContent
        )?_c('GridElement',{key:ce.id,attrs:{"appearance":ce.appearance,"element":ce}}):(ce.type == 'news_pi1' && ce.content.data.detail)?_c('BlogDetailPage',{attrs:{"data":ce.content.data,"latest":_vm.itemsLatestNewsContent}}):(
          ce.type == 'headlessevents_eventslist' &&
          !_vm.itemsEventDetailsOnlyContent
        )?_c('Events',{attrs:{"dataProp":ce.content}}):(ce.type == 'headlessevents_eventslist')?_c('EventsDetail',{attrs:{"data":ce.content,"eventDetailsOnlyContent":_vm.itemsEventDetailsOnlyContent}}):(ce.type == 'shortcut' && _vm.checkShortcutContent(ce.content))?[_vm._l((ce.content.shortcut),function(ce){return [(ce.type == 'structured_content')?_c('GridElement',{key:ce.id,attrs:{"appearance":ce.appearance,"element":ce}}):_c('DynamicElement',{key:ce.id,attrs:{"element":ce}})]})]:_c('DynamicElement',{key:ce.id,attrs:{"element":ce,"isDetail":_vm.getIsDetail(_vm.itemsEventDetailsOnlyContent)}})],2):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }