<template>
  <article class="h-100 flex-wrap d-flex">
    <header class="w-100">
      <T3Link :href="getUrl(item)" class="w-100" v-if="getMedia(item)">
        <template #content>
          <RatioImage
            :imgSrc="getimgSrc(item)"
            elemClass="ratio-3x2 mt-0 mb-3"
          />
        </template>
      </T3Link>

      <ul class="tags blanklist mb-2 mt-0" v-if="getCategories(item)">
        <li
          v-for="tag in item.categories"
          :key="tag.id"
          class="d-inline-block me-1 mb-2"
        >
          <span class="tag bg-bone" :id="`tag-${tag.id}`">{{ tag.title }}</span>
        </li>
      </ul>
      <h4 v-if="item.title" class="fs-6">{{ item.title }}</h4>
      <p v-if="getText()">{{ getText() }}</p>
    </header>

    <footer class="d-flex justify-content-between mt-auto w-100">
      <span>{{ $t('Read time') }} {{ item.readTime }}</span>
      <T3Link
        class="text-uppercase text-decoration-none read-more-link"
        :href="getUrl(item)"
      >
        <template #content>
          {{ $t('Read more') }} <i class="icon-arrow-right"></i>
        </template>
      </T3Link>
    </footer>
  </article>
</template>
<script>
import T3Link from './General/T3Link.vue';
import RatioImage from './RatioImage.vue';
export default {
  name: 'BlogCard',
  props: {
    item: Object,
  },
  components: {
    RatioImage,
    T3Link,
  },
  methods: {
    getText() {
      if (this.item.content) {
        return this.item.content.substring(0, 150) + '...';
      }
    },
    getMedia(array) {
      if (array?.media?.length) {
        return array.media[0] || '';
      } else if (array.title) {
        return array.title;
      } else {
        return '';
      }
    },
    getCategories(array) {
      if (array?.categories?.length) {
        return array.categories[0] || '';
      } else {
        return '';
      }
    },
    getimgSrc(item) {
      if (item?.media?.length) {
        return item.media[0].images.listViewImage.publicUrl || '';
      } else if (item?.image) {
        return item?.image;
      } else {
        return '';
      }
    },
    getUrl(item) {
      if (item?.slug) {
        return item?.slug;
      } else if (item?.url) {
        return item?.url;
      } else {
        return '';
      }
    },
  },
};
</script>
