import { defineStore } from 'pinia';
import axios from 'axios';

export const useEventStore = defineStore({
  id: 'useEventStore',
  state: () => ({
    baseURL: process.env.T3_API_BASE,
    basePath: process.env.T3_API_PATH,
    eventsData: [],
    eventList: [],
    eventDetail: {} as any,
    eventLoading: true,
    detailLoading: true,
    error: null,
  }),
  getters: {},
  actions: {
    async getEvents(page, filter = '') {
      this.eventLoading = true;
      let filters = filter ? `?${filter}` : '';
      let pNo = page ? `${page}/` : '';
      try {
        const response = await axios.get(this.baseURL + `/de/ce/events/${pNo + filters}`);
        this.eventList = response?.data?.content[0]?.content?.events_list;
        this.eventLoading = false;
      } catch (error) {
        this.error = error;
      } finally {
        this.eventLoading = false;
      }
    },
    async getDetailEvent(event: string) {
      this.detailLoading = true;
      try {
        const response = await axios.get(this.baseURL + `/de/events-list/detail/${event}`);
        this.eventDetail = response?.data?.content[0]?.content?.event_detail;
        this.detailLoading = false;
      } catch (error) {
        this.error = error;
      } finally {
        this.detailLoading = false;
      }
    },
  },
});
