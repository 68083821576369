<template>
  <input
    :type="type"
    :id="id"
    :placeholder="placeholder"
    :required="required"
    :value="modelValue"
  />
</template>

<script>
export default {
  name: 'InputComponent',

  props: {
    modelValue: String,
    type: String,
    id: String,
    placeholder: String,
    required: Boolean,
    label: String,
  },
  emits: ['update:modelValue'],
};
</script>

<style scoped></style>
