export default class Utility {
  static hideElement(element: any): void {
    if (element !== null) {
      element.style.display = 'none';
    }
  }

  static showElement(element: any): void {
    if (element !== null) {
      element.style.display = 'block';
    }
  }
}
