<template>
  <div class="page" :class="{ loading: loading }">
    <section
      v-if="breadcrumb && notHome"
      :class="getBgForBreadcrumb(isProductDetail)"
    >
      <div class="container">
        <Breadcumb :breadcrumbs="breadcrumb" :currentURL="currentURL" />
      </div>
    </section>
    <template v-if="hasError">
      <section class="mb-0">
        <div class="container py-4">
          <h1>{{ $t('An error occurred') }} – {{ error }}</h1>
        </div>
      </section>
      <template v-for="ce in items.content">
        <section
          :class="
            !items.eventDetailsOnlyContent
              ? ce.type + getBg(ce) + getSpacing(ce)
              : ''
          "
          :id="getID(ce.id)"
          v-if="ce"
          :key="ce.id"
        >
          <GridElement
            :appearance="ce.appearance"
            :key="ce.id"
            :element="ce"
            v-if="
              ce.type == 'structured_content' && !items.eventDetailsOnlyContent
            "
          ></GridElement>
          <template
            v-else-if="
              ce.type == 'shortcut' && checkShortcutContent(ce.content)
            "
          >
            <template v-for="ce in ce.content.shortcut">
              <GridElement
                :appearance="ce.appearance"
                :key="ce.id"
                :element="ce"
                v-if="ce.type == 'structured_content'"
              ></GridElement>
              <DynamicElement
                :key="ce.id"
                :element="ce"
                v-else
              ></DynamicElement>
            </template>
          </template>
          <DynamicElement
            :key="ce.id"
            :element="ce"
            :isDetail="getIsDetail(items.eventDetailsOnlyContent)"
            v-else
          ></DynamicElement>
        </section>
      </template>
    </template>
    <section v-else-if="loading" class="bg-sand loading-section py-10 h-100">
      <div class="container py-4">
        <h1 class="loading-section-header mb-7"></h1>
        <p class="loading-section-text"></p>
        <p class="loading-section-text"></p>
        <p class="loading-section-text"></p>
        <p class="loading-section-text"></p>
      </div>
    </section>
    <template v-else>
      <ProductDetailHero
        v-if="items.productDetail"
        :productDetails="items.productDetail"
        @outOfStock="toggleOutOfStockModal"
      />
      <template v-for="ce in items.content">
        <section
          :class="
            !items.eventDetailsOnlyContent
              ? ce.type + getBg(ce) + getSpacing(ce)
              : ''
          "
          :id="getID(ce.id)"
          v-if="ce"
          :key="ce.id"
        >
          <GridElement
            :appearance="ce.appearance"
            :key="ce.id"
            :element="ce"
            v-if="
              ce.type == 'structured_content' && !items.eventDetailsOnlyContent
            "
          ></GridElement>
          <BlogDetailPage
            :data="ce.content.data"
            v-else-if="ce.type == 'news_pi1' && ce.content.data.detail"
            :latest="items.latestNewsContent"
          />
          <Events
            v-else-if="
              ce.type == 'headlessevents_eventslist' &&
              !items.eventDetailsOnlyContent
            "
            :dataProp="ce.content"
          />
          <EventsDetail
            v-else-if="ce.type == 'headlessevents_eventslist'"
            :data="ce.content"
            :eventDetailsOnlyContent="items.eventDetailsOnlyContent"
          />
          <template
            v-else-if="
              ce.type == 'shortcut' && checkShortcutContent(ce.content)
            "
          >
            <template v-for="ce in ce.content.shortcut">
              <GridElement
                :appearance="ce.appearance"
                :key="ce.id"
                :element="ce"
                v-if="ce.type == 'structured_content'"
              ></GridElement>
              <DynamicElement
                :key="ce.id"
                :element="ce"
                v-else
              ></DynamicElement>
            </template>
          </template>
          <DynamicElement
            :key="ce.id"
            :element="ce"
            :isDetail="getIsDetail(items.eventDetailsOnlyContent)"
            v-else
          ></DynamicElement>
        </section>
      </template>

      <OutOfStockModal :data="items" v-if="isOutOfStockModalOpen" />

      <NewsletterSection v-if="!error && !removeNewsletter" />
      <DynamicNewsticker v-if="!error" />
    </template>

    <PopupModal
      v-if="isPopupModalOpen && !getPopupCookie"
      :data="getPopupContent()"
      :visibleOn="isPopupModalOpen"
      @closePopupModal="isPopupModalOpen = false"
    />

    <div
      v-if="stickyButton.title && stickyButton.link && itemsInCart.length < 1"
      class="sticky-button position-fixed bottom-50"
    >
      <T3Link
        :href="stickyButton.link"
        class="d-block p-1 text-decoration-none"
      >
        <template #content>
          <div class="d-flex flex-column align-items-center">
            <i class="icon-shopping-cart fs-2"></i>
            <strong>{{ stickyButton.title }}</strong>
          </div>
        </template>
      </T3Link>
    </div>
  </div>
</template>

<script>
import GridElement from '~/components/GridElement.vue';
import DynamicElement from '~/components/DynamicElement.vue';
import Breadcumb from '~/components/Meta/Breadcrumb.vue';
import BlogDetailPage from '~/pages/BlogDetailPage.vue';
import ProductDetailHero from '~/components/ProductPage/ProductDetailHero.vue';
import NewsletterSection from '~/components/NewsletterSection.vue';
import DynamicNewsticker from '~/components/DynamicNewsticker.vue';
import { useLanguageStore } from '../stores/languageStore';
import Events from '~/components/EventsOverview.vue';
import EventsDetail from '~/components/EventsDetail.vue';
import {
  onMounted,
  useContext,
  computed,
  ref,
  reactive,
} from '@nuxtjs/composition-api';
import { SfMenuItem } from '@storefront-ui/vue';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';
import T3Link from '~/components/General/T3Link.vue';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import xml2js from 'xml2js';
import PopupModal from '~/modules/checkout/components/PopupModule/PopupModal.vue';
import T3PageContent from './T3PageContent.vue';
import OutOfStockModal from '~/modules/customer/components/ProductsOutOfStock/OutOfStockModal.vue';
import { useUiState } from '~/composables';

const isPodcastSlug = (slug) => {
  // Check if slug matches "/wissen/podcast/:id" where :id is a number
  return /wissen\/podcast\/[\w-]+$/.test(slug);
};

export default {
  name: 'T3',
  components: {
    OutOfStockModal,
    T3Link,
    SfMenuItem,
    GridElement,
    DynamicElement,
    Breadcumb,
    BlogDetailPage,
    ProductDetailHero,
    NewsletterSection,
    DynamicNewsticker,
    Events,
    EventsDetail,
    PopupModal,
    T3PageContent,
  },
  head() {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true });
    delete i18nHead.link;
    const links = this.i18n
      ? this.i18n.map((item) => {
          return item.available == false
            ? ''
            : {
                hid: `i18n-alt-${item.hreflang}`,
                href: process.env.VSF_STORE_URL + item.link,
                rel: 'alternate',
                hreflang: item.hreflang,
              };
        })
      : [];
    if (this.meta) {
      let contentOgImage = '/img/TISSO_Naturprodukte_Home_Hero_1_Wald.webp';
      if (this.items?.content?.length > 1) {
        this.items.content.map(function (element, key) {
          if (typeof element?.image !== 'undefined' && element.image) {
            contentOgImage = element.image;
          }
        });
      } else {
        this.items?.content?.map(function (element, key) {
          if (
            typeof element?.content?.data?.detail?.media !== 'undefined' &&
            element?.content?.data?.detail?.media.length
          ) {
            let images = element.content.data.detail.media[0];

            contentOgImage = images?.images?.detailViewImage?.publicUrl;
          }
        });
      }

      return {
        title: this.meta.title,
        __dangerouslyDisableSanitizers: ['script'],
        script: [
          {
            innerHTML: JSON.stringify(this.structuredData),
            type: 'application/ld+json',
          },
        ],
        htmlAttrs: {
          ...i18nHead.htmlAttrs,
        },
        meta: [
          {
            hid: 'robots',
            name: 'robots',
            content: this.robots,
          },
          {
            hid: 'og:title',
            name: 'og:title',
            content: this.meta.title,
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content: this.meta.description,
          },
          {
            hid: 'description',
            name: 'description',
            content: this.meta.description,
          },
          {
            hid: 'og:image',
            name: 'og:image',
            content:
              this.meta.ogImage?.properties?.originalUrl || contentOgImage,
          },
          {
            hid: 'og:url',
            name: 'og:url',
            content: this.currentURL,
          },
          ...i18nHead.meta,
        ],
        link: [
          {
            hid: 'i18n-can',
            rel: 'canonical',
            href: this.meta.canonical?.href ? this.canonical : this.currentURL,
          },
          {
            hid: 'i18n-xd',
            rel: 'alternate',
            href: this.meta.canonical?.href ? this.canonical : this.currentURL,
            hreflang: 'x-default',
          },
          ...links,
        ],
      };
    }
  },
  asyncData(context) {
    const { route, app, $axios, res } = context;
    const languageStore = useLanguageStore();
    const selectedStore = ref('de');
    const locale = ref('');
    const notHome = ref(true);
    const storeCode = ref('');
    const slug = ref('');

    const structuredData = ref({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      url: 'https://www.tisso.de',
      logo: 'https://www.tisso.de/img/tisso-logo-desktop.svg',
      contactPoint: [
        {
          '@type': 'ContactPoint',
          telephone: '+49 (0) 2762 9836-0',
          contactType: 'customer service',
        },
      ],
      foundingDate: '1999',
      address: {
        '@type': 'PostalAddress',
        addressLocality: 'Nordrhein-Westfalen',
        addressRegion: 'Wenden',
        postalCode: '57482',
        streetAddress: 'Eisenstraße 1',
      },
      sameAs: [
        'https://www.facebook.com/tissonaturprodukte/',
        'https://www.instagram.com/tissonaturprodukte/',
        'https://www.pinterest.de/tissonaturprodukte',
      ],
    });

    locale.value = app.i18n.localeProperties.code;
    slug.value = route.params.slug;

    if (slug.value) {
      if (Array.isArray(slug.value)) {
        slug.value = slug.value.toString().replaceAll(',', '/');
      }
      if (isPodcastSlug(slug.value)) {
        // Always use "/wissen/podcast" as slug
        slug.value = 'wissen/podcast';
      }
    } else {
      slug.value = '';
      notHome.value = false;
    }

    storeCode.value = locale.value.split('_');
    if (storeCode.value[1]) {
      selectedStore.value = storeCode.value[1];
    }

    const jsonPath =
      process.env.VSF_STORE_URL +
      '/typo3-api/' +
      locale.value +
      '/' +
      slug.value;

    return $axios
      .get(jsonPath)
      .then(async (res) => {
        if (res.data) {
          var dataJSON = res.data;
          if (
            (dataJSON.statusCode === 307 || dataJSON.statusCode === 301) &&
            dataJSON.redirectUrl
          ) {
            // Redirect to new URL
            context.redirect(dataJSON.redirectUrl);
          }
        }
        languageStore.setPageMeta(res);
        if (slug.value == '404' && res) {
          res.statusCode = 404;
          notHome.value = false;
        }
        if (res.data.productDetail) {
          structuredData.value = {
            '@context': 'https://schema.org',
            '@type': 'Product',
            description: res?.data?.productDetail?.productFeedDescription,
            name: res?.data?.productDetail?.title,
            sku: res?.data?.productDetail?.magentoSku,
            image: res?.data?.productDetail?.detailImages[0].publicUrl,
            offers: {
              '@type': 'Offer',
              availability: res.data.productDetail.outOfStock
                ? 'https://schema.org/OutOfStock'
                : 'https://schema.org/InStock',
              price: res?.data?.productDetail?.discountPrice
                ? res?.data?.productDetail?.discountPrice
                : res?.data?.productDetail?.price,
              priceCurrency: 'EUR',
            },
          };
        }
        // TODO: Move this to podcast element once SSR is fixed
        if (isPodcastSlug(route.params.slug)) {
          const podcasts = ref({});
          const podcastTitle = ref(null);
          podcastTitle.value = route.fullPath.split('?')[0].split('/').pop();

          await fetch(process.env.VSF_STORE_URL + '/podcast.rss')
            .then((response) => response.text())
            .then((data) => {
              const parser = new xml2js.Parser();
              parser.parseStringPromise(data).then(function (result) {
                podcasts.value = podcastTitle.value
                  ? result.rss.channel[0].item.filter(
                      (podcast) =>
                        podcast.title[0]
                          .toLowerCase()
                          .replace(/ /g, '-')
                          .replace(/[^\w-]+/g, '')
                          .replace(/-+/g, '-') === podcastTitle.value,
                    )
                  : result.rss.channel[0].item;
                res.data.title = podcasts.value[0].title[0];
                res.data.meta.title = podcasts.value[0].title[0];
                res.data.meta.description = podcasts.value[0].description[0];
                res.data.meta.ogImage = {
                  properties: {
                    originalUrl: podcasts.value[0]['itunes:image'][0].$.href,
                  },
                };
              });
            });
        }

        return {
          meta: res.data.meta,
          i18n: res.data.i18n,
          slug: slug.value,
          breadcrumb: res.data.breadcrumbs,
          notHome: notHome.value,
          structuredData: structuredData.value,
          isProductDetail: res.data.productDetail ? true : false,
          robots: res.data.meta.robots?.noIndex
            ? 'noindex,follow'
            : 'index,follow',
          currentURL:
            process.env.VSF_STORE_URL +
            '/' +
            locale.value +
            '/' +
            slug.value +
            (slug.value ? '/' : ''),
          canonical: process.env.VSF_STORE_URL + res.data.meta.canonical?.href,
          removeNewsletter: !res.data.footerData?.footer_newsletter || false,
        };
      })
      .catch((err) => {
        console.error(err);
        if (res) {
          res.statusCode = 404;
        }
        return {
          notHome: notHome.value,
          hasError: false,
          breadcrumb: false,
        };
      });
  },

  setup() {
    const openOutOfStockModal = false;
    const languageStore = useLanguageStore();
    const loading = computed(() => languageStore.loading);
    const { app } = useContext();
    const { state } = app.context.$vsf.$magento.config;
    const t3CookieData = state.getFeTypoUserId();
    const { selectedStore } = useMagentoConfiguration();
    const { isOutOfStockModalOpen, toggleOutOfStockModal } = useUiState();
    const error = ref('');
    const items = ref([]);
    const i18n = ref([]);
    const stickyButton = reactive({
      title: '',
      link: '',
    });
    const { cart } = useCart();
    const removeNewsletter = ref(null);
    const itemsInCart = computed(() => cartGetters.getItems(cart.value));
    const getPopupCookie = ref(null);
    let isPopupModalOpen = false;

    onMounted(() => {
      getPopupCookie.value = app.$cookies.get('vsf-popup');
    });

    return {
      openOutOfStockModal,
      selectedStore,
      t3CookieData,
      languageStore,
      loading,
      itemsInCart,
      hasError: false,
      error: error.value,
      items: items.value,
      i18n: i18n.value,
      stickyButton,
      isPodcastSlug,
      config: {
        container: true,
      },
      isPopupModalOpen,
      getPopupCookie,
      isOutOfStockModalOpen,
      toggleOutOfStockModal,
      removeNewsletter: removeNewsletter.value,
    };
  },
  async created() {
    let storeCode = this.selectedStore;
    var slug = this.$route.params.slug;

    if (this.hasError) {
      await this.languageStore.getLanguageData(
        storeCode,
        '404',
        this.t3CookieData,
      );
      this.meta = this.languageStore.pageData.meta;
      this.items = this.languageStore.pageData;
      this.i18n = this.languageStore.pageData.i18n;
      this.notHome = false;
      this.error = 404;
      return;
    }
    try {
      if (slug) {
        if (Array.isArray(slug)) {
          slug = slug.toString().replaceAll(',', '/');
        }
        // TODO: Move this to podcast page
        if (isPodcastSlug(slug)) {
          // Always use "/wissen/podcast" as slug
          slug = 'wissen/podcast';
        }
      } else {
        slug = '';
        this.notHome = false;
      }

      await this.languageStore.getLanguageData(
        storeCode,
        slug,
        this.t3CookieData,
      );
      // TODO: Move this to podcast page
      if (isPodcastSlug(this.$route.params.slug)) {
        // Remove items before type podcast_element fom pageData
        const podcastIndex = this.languageStore.pageData.content.findIndex(
          (item) => item.type === 'podcast_element',
        );

        // If 'podcast_element' is found, slice the array to get a new array starting from that index
        if (podcastIndex !== -1) {
          this.languageStore.pageData.content =
            this.languageStore.pageData.content.slice(podcastIndex);
        }
      }
      this.items = this.languageStore.pageData;
      this.i18n = this.languageStore.pageData.i18n;
      this.stickyButton.title =
        this.languageStore?.pageData?.headerData?.sticky_button_text;
      this.stickyButton.link =
        this.languageStore?.pageData?.headerData?.sticky_button_link;

      if (this.languageStore.error) {
        await this.languageStore.getLanguageData(
          storeCode,
          '404',
          this.t3CookieData,
        );
        this.meta = this.languageStore.pageData.meta;
        this.items = this.languageStore.pageData;
        this.i18n = this.languageStore.pageData.i18n;
        this.notHome = false;
        this.error = 404;
        this.hasError = true;
      }
    } catch (error) {
      console.error(error);
      this.hasError = true;
      this.error = error;
    }
  },
  watch: {
    items(newVal) {
      if (newVal?.headerData?.popup_content) {
        this.isPopupModalOpen = true;
      }
    },
  },
  methods: {
    getPopupContent() {
      return this.items?.headerData?.popup_content;
    },
    getBg(element) {
      if (element?.content?.shortcut) {
        element = element?.content?.shortcut[0];
      }
      if (this.config.container && element?.appearance) {
        if (element?.appearance?.frameClass !== 'default') {
          if (element?.appearance?.subLayout) {
            return (
              ' ' +
              element?.appearance?.frameClass +
              ' subLayout' +
              element?.appearance?.subLayout
            );
          } else {
            return ' ' + element?.appearance?.frameClass;
          }
        } else {
          return '';
        }
      }
    },
    getBgForBreadcrumb(isProductDetail) {
      if (isProductDetail) {
        return '';
      } else {
        return 'bg-sand';
      }
    },
    getSpacing(element) {
      if (element?.content?.shortcut) {
        element = element?.content?.shortcut[0];
      }
      let spaceBefore = '';
      let spaceAfter = '';
      let frameClass = '';
      if (element?.appearance) {
        spaceBefore = element?.appearance?.spaceBefore;
        spaceAfter = element?.appearance?.spaceAfter;
        frameClass = element?.appearance?.frameClass;
      }
      if (!spaceBefore) {
        spaceBefore = '10';
      }
      if (!spaceAfter) {
        spaceAfter = '10';
      }
      if (frameClass == 'default' || frameClass == '') {
        return ' mt-' + spaceBefore + ' mb-' + spaceAfter;
      } else {
        return ' pt-' + spaceBefore + ' pb-' + spaceAfter;
      }
    },
    getID(id) {
      if (id) {
        return 'c' + id;
      } else {
        return '';
      }
    },
    getIsDetail(list) {
      return list?.length ? true : false;
    },
    checkShortcutContent(el) {
      if (el?.shortcut) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'assets/styles/misc/vars';

[class^='loading-section-'] {
  display: block;
  height: 1rem;
  width: 90%;
  max-width: 830px;
  position: relative;
  overflow: hidden;
  background-color: $gray-200;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba($gray-400, 0) 0,
      rgba($gray-400, 0.2) 20%,
      rgba($gray-400, 0.5) 60%,
      rgba($gray-400, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
.loading-section {
  min-height: 90vh;
  .container {
    margin-top: 4.3125rem;
  }
}
.loading-section-header {
  width: 45%;
  max-width: 830px;
  margin-bottom: 1rem;
  height: 2.9875rem;
}
.loading-section-text {
  // width: 33%;
  margin-bottom: 0.5rem;
  &:last-child {
    width: 40%;
    max-width: 700px;
  }
}

.sticky-button {
  --sticky-button-z-index: 27;
  background-color: $hazelgreen;
  width: max-content;
  margin-left: auto;
  right: 0;
  border-radius: 1rem 0 0 1rem;
  display: none;
  z-index: var(--sticky-button-z-index);

  @include media-breakpoint-up(lg) {
    display: block;
  }

  a {
    color: $white;
    text-decoration: none;
  }
}
</style>
