<template>
  <div class="border-bone-83">
    <nav class="filter container" v-if="getFilterlength(filters)">
      <ul class="blanklist d-flex font-weight-600">
        <li>
          <label
            @click="getFilters('', '')"
            class="form-check-button-label btn btn-secondary border-bone-83 text-anthracite px-2"
            :class="{ selected: !selectedEvents }"
          >
            {{ allEventLabel }}
          </label>
        </li>
        <li
          v-for="filter in filters"
          :key="filter"
          class="form-check-button ms-2"
        >
          <label
            @click="getFilters(filter)"
            class="form-check-button-label btn btn-secondary border-bone-83 text-anthracite px-2"
            :class="{ selected: replaceAll(filter) == selectedEvents }"
          >
            {{ filter }}
          </label>
        </li>
      </ul>
    </nav>
  </div>
</template>
<script>
import { replaceAll } from '~/composables/utils/functions';

export default {
  name: 'ListFilterEvent',
  props: {
    allEventLabel: String,
    filters: Array,
    applyFilter: Function,
    selectedEvents: String,
  },
  data() {
    return {
      replaceAll,
    };
  },
  methods: {
    getFilters(id) {
      const link = this.applyFilter(id);
      return link;
    },
    getFilterlength(filter) {
      return filter ? filter.length : 0;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.selected {
  background: $hazelgreen-70 !important;
  border-color: transparent !important;
}

.form-check-button {
  &-input:checked {
    + label {
      background: $hazelgreen-70 !important;
      border-color: transparent !important;
    }
  }

  &-label {
    text-transform: none;
    font-weight: 400;
    white-space: nowrap;
  }
}
</style>
