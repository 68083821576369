<template>
  <div class="contactoptioniconlist">
    <div class="bodytext" v-html="element.bodytext" />
    <div
      v-for="option in element.options"
      :key="option.name"
      class="contactoptioniconlist-icon d-flex mt-4"
    >
      <figure class="image me-2">
        <i :class="`icon ${option.icon}`"></i>
      </figure>
      <!-- <i :class="`icon-${option.icon}`"></i> -->
      <div class="content" v-html="option.text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactOptionIconList',
  props: {
    element: Object,
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.contactoptioniconlist {
  .image {
    .icon {
      background-color: $bone-83;
      color: $anthracite;
      font-size: 1.5rem;
      line-height: 3.5rem;
      width: 3.5rem;
      text-align: center;
      border-radius: 50%;
      display: inline-block;
    }
  }
  p {
    margin-bottom: 0.3rem;
  }
}
</style>
