<template>
  <div class="product-detail-hero-slider">
    <template v-if="sale">
      <span class="label">Sale</span>
    </template>

    <template v-if="!outOfStock === false">
      <span class="label">Out of Stock</span>
    </template>

    <EnlargeImgModal
      :slidesImg="slides"
      :slideIndex="slideIndex"
      :modalIsOpen="modalIsOpen"
      @onModalClose="closeEnlargedImgModal"
    >
    </EnlargeImgModal>

    <VueSlickCarousel
      v-if="slides.length"
      ref="carouselParent"
      :class="'big-image'"
      v-bind="settingsParent"
    >
      <div
        v-for="(slide, i) in slides"
        :key="i"
        @click.prevent="openEnlargedImgModal(i)"
      >
        <RatioImage
          :imgSrc="slide.publicUrl"
          :imgAlt="slide.alt"
          :fullsize="false"
          :elemClass="'ratio-1x1 m-0'"
          :id="`product-image-${slide.uid}`"
          :notLazy="i === 0"
        />
      </div>
    </VueSlickCarousel>

    <div class="sub-gallary-container d-none d-lg-block mt-2">
      <div class="hooper--controls">
        <button
          @click.prevent="slidePrev"
          class="hooper--arrow arrow-prev"
          type="button"
        >
          <i class="icon-arrow-left"></i>
        </button>
        <button
          @click.prevent="slideNext"
          class="hooper--arrow arrow-next"
          type="button"
        >
          <i class="icon-arrow-right"></i>
        </button>
      </div>

      <VueSlickCarousel
        v-if="slides.length"
        :class="'sub-gallary'"
        v-bind="settingsChild"
        ref="carousel"
      >
        <div v-for="(slide, i) in slides" :key="i">
          <RatioImage
            :imgSrc="slide.publicUrl"
            :imgAlt="slide.alt"
            :fullsize="true"
            :elemClass="'ratio-1x1 m-0'"
            :id="`product-image-${slide.uid}`"
          />
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import RatioImage from '../RatioImage.vue';
import VueSlickCarousel from 'vue-slick-carousel';
import EnlargeImgModal from '../EnlargeImgModal.vue';

export default {
  name: 'ProductDetailHeroSlider',
  components: {
    RatioImage,
    VueSlickCarousel,
    EnlargeImgModal,
  },
  data() {
    return {
      slideSettings: {
        infiniteScroll: true,
        wheelControl: false,
      },
      settingsParent: {
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: this.$refs.carousel,
      },
      settingsChild: {
        arrows: true,
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        infiniteScroll: true,
        asNavFor: this.$refs.carouselParent,
        focusOnSelect: true,
      },
      outOfStock: false,

      modalIsOpen: false,
      slideIndex: 0,
    };
  },
  props: {
    slides: Array,
    sale: String,
  },
  computed: {
    firstImageUrl() {
      if (!this.slides.length) return '';
      const firstSlide = this.slides[0];
      // Assuming you have a mobile-specific image property, adjust as necessary
      return this.$device.isMobile && firstSlide.imageMobile
        ? firstSlide.imageMobile
        : firstSlide.publicUrl;
    },
  },
  mounted() {
    this.settingsParent.asNavFor = this.$refs.carousel;
    this.settingsChild.asNavFor = this.$refs.carouselParent;
  },
  methods: {
    openEnlargedImgModal(index) {
      this.modalIsOpen = true;
      this.slideIndex = index;
    },

    closeEnlargedImgModal() {
      this.modalIsOpen = false;
      this.slideIndex = 0;
    },
    slidePrev() {
      this.$refs.carousel.prev();
    },
    slideNext() {
      this.$refs.carousel.next();
    },
  },
  head() {
    if (!this.firstImageUrl) return {};
    return {
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: this.firstImageUrl,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'assets/styles/misc/vars';
@import 'assets/styles/misc/slick-slider';

.product-detail-hero-slider {
  margin-left: calc($spacer-2 - $spacer-2 * 2);
  margin-right: calc($spacer-2 - $spacer-2 * 2);

  position: relative;

  .label {
    position: absolute;
    top: $spacer-2;
    left: $spacer-2;
    z-index: 2;
  }

  .sub-gallary-container {
    margin: 0 auto;
    width: calc(100% - 5.5rem);
    position: relative;

    .hooper--arrow {
      font-size: 1.25rem;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      color: $primary;

      &.arrow-prev {
        left: -2.5rem;
      }
      &.arrow-next {
        right: -2.5rem;
      }
    }
  }

  .hooper {
    height: auto;
    &.big-image {
      .hooper-list {
        .hooper-slide {
          padding: 0;
        }
      }
    }
    .hooper-list {
      .hooper-slide {
        padding: 0 0.5rem;
        cursor: pointer;
      }
    }
  }

  .hooper-pagination {
    padding: 0;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: var(--spacer-2);
    margin-bottom: var(--spacer-3);
    li {
      button {
        height: 0.25rem;
        width: 2.5rem;
        margin: 0 0.5rem;
        background: $bone-83;
        transition: $transition-base;
        border-radius: 0;

        &.is-active {
          background: $jasmine;
        }

        &:hover {
          background: $jasmine;
        }
      }
    }
  }

  .hooper--controls {
    position: unset;
    transform: unset;
    top: unset;
    width: unset;
  }
}

.slick-prev,
.slick-next {
  &:before {
    display: none;
  }
}

@include media-breakpoint-up(lg) {
  .product-detail-hero-slider {
    margin-right: unset;
    margin-left: unset;
  }
}
</style>
