<template>
  <div class="row">
    <div
      class="col-lg-4 col-md-6 col-xs-12"
      v-for="card in gatedArea"
      :key="card.name"
    >
      <div class="gatedCard">
        <T3Link :href="card.detailLink">
          <template #content>
            <p>
              {{ card.name }}
            </p>
          </template>
        </T3Link>
      </div>
    </div>
  </div>
</template>

<script>
import T3Link from './General/T3Link.vue';

export default {
  name: 'GatedCard',
  props: {
    gatedArea: Array,
  },
  components: { T3Link },
};
</script>

<style scoped lang="scss">
@import 'assets/styles/misc/vars';

.gatedCard {
  position: relative;
  width: 100%;
  height: calc(100% - 1rem);
  margin-bottom: 1rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 1.5rem;
  }

  a {
    transition: $transition-base;
    background: $bone;
    border: 1px solid $bone-83;
    display: block;
    text-decoration: none;
    text-transform: uppercase;
    color: $anthracite;
    padding: 4.1875rem 2rem;
    text-align: center;
    border-radius: $border-radius-lg;

    &:hover {
      border-color: $jasmine;
      background: $jasmine;
    }
  }
}
</style>
