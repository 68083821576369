<template>
  <div class="hero-section pt-1">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <RatioImage
            :elemClass="'d-none d-lg-block m-0'"
            :imgSrc="getHeaderImage(headerImage)"
            :imgAlt="'Bild'"
          />
          <RatioImage
            :elemClass="'d-lg-none m-0 ratio ratio-3x2'"
            :imgSrc="getHeaderImage(headerImage)"
            :imgAlt="'Bild'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RatioImage from './RatioImage.vue';
export default {
  name: 'HeroHeaderBlogDetail',
  components: { RatioImage },
  props: {
    title: String,
    readTime: String,
    date: String,
    authors: Array,
    headerImage: String,
  },
  methods: {
    getHeaderImage(headerImage) {
      if (typeof headerImage !== 'undefined' && headerImage) {
        return headerImage;
      }
      return '/img/TISSO_Naturprodukte_Home_Hero_1_Wald.webp';
    },
  },
};
</script>

<style lang="scss">
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

@import 'assets/styles/misc/vars';
.hero-section {
  .ratio {
    --bs-aspect-ratio: 82%;
  }
}
@include media-breakpoint-up(lg) {
  .hero-section {
    .ratio {
      --bs-aspect-ratio: 30.75%;
    }
  }
}
</style>
