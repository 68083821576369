<template>
  <div>
    <!-- Begin Mailchimp Signup Form -->
    <!-- <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">  -->
    <div id="mc_embed_signup_b2c">
      <form
        action="https://tisso.us10.list-manage.com/subscribe/post?u=796799058014c6fa649c34608&amp;id=2ff9d448b6&amp;f_id=0066d8e1f0"
        method="post"
        id="mc-embedded-subscribe-form-b2c"
        name="mc-embedded-subscribe-form-b2c"
        class="validate newsletterform mt-5"
        target="_blank"
      >
        <div id="mc_embed_signup_scroll">
          <div class="mc-field-group mb-3">
            <label for="mce-EMAIL" class="form-label"
              >{{ $t('Email address') }}
            </label>
            <input
              type="email"
              value=""
              name="EMAIL"
              class="required email form-control"
              id="mce-EMAIL"
              required
            />
          </div>
          <div class="mb-3 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
              required
            />
            <label class="form-check-label" for="exampleCheck1"
              >Bitte sendet mir entsprechend eurer
              <button
                type="button"
                class="px-0 d-inline text-primary"
                @click.prevent="goToPrivacyPage()"
              >
                Datenschutzerklärung
              </button>
              regelmäßig und jederzeit widerruflich Informationen zu aktuellen
              Gesundheitsthemen und Nahrungsergänzungsmitteln per E-Mail
              zu.</label
            >
          </div>
          <div style="position: absolute; left: -5000px" aria-hidden="true">
            <input
              type="text"
              name="b_796799058014c6fa649c34608_2ff9d448b6"
              tabindex="-1"
              value=""
            />
          </div>
          <div class="clear">
            <input
              type="submit"
              :value="$t('Subscribe to Newsletters')"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="btn btn-primary w-100 mt-8 w-lg-auto join-button mb-6 mb-md-0"
            />
          </div>
        </div>
        <!-- Form Transaction ID -->
        <input
          type="hidden"
          class="custom_payload form_transaction_id"
          value=""
        />

        <!-- GA Client Id -->
        <input type="hidden" class="custom_payload ga_client_id" value="" />

        <!-- GCLID -->
        <input type="hidden" class="custom_payload gclid" value="" />

        <!-- FBCLID -->
        <input type="hidden" class="custom_payload fbclid" value="" />

        <!-- UTM traffic source -->
        <input
          type="hidden"
          class="custom_payload utm_traffic_source"
          value=""
        />

        <input
          type="hidden"
          class="custom_payload form_category"
          value="form_newsletter_b2c"
        />

        <!-- Page Url -->
        <input
          type="hidden"
          class="custom_payload page_url"
          name="page_url"
          :value="getPageUrl()"
        />
      </form>
    </div>
    <!--End mc_embed_signup-->
  </div>
</template>

<script>
import { useRouter, useContext } from '@nuxtjs/composition-api';
export default {
  name: 'NewsletterB2C',
  components: {},
  setup() {
    const router = useRouter();
    const { app } = useContext();

    const goToPrivacyPage = async () => {
      const privacyPage = router.resolve(
        app.localeRoute({ name: 'typo3', params: { slug: 'datenschutz' } }),
      );
      await window.open(privacyPage.href, '_blank');
    };

    const getPageUrl = () => {
      return window.location.href;
    };
    return {
      goToPrivacyPage,
      getPageUrl,
    };
  },
};
</script>

<style lang="scss">
form.newsletterform {
  max-width: 41.75rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
</style>
