var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page",class:{ loading: _vm.loading }},[(_vm.breadcrumb && _vm.notHome)?_c('section',{class:_vm.getBgForBreadcrumb(_vm.isProductDetail)},[_c('div',{staticClass:"container"},[_c('Breadcumb',{attrs:{"breadcrumbs":_vm.breadcrumb,"currentURL":_vm.currentURL}})],1)]):_vm._e(),_vm._v(" "),(_vm.hasError)?[_c('section',{staticClass:"mb-0"},[_c('div',{staticClass:"container py-4"},[_c('h1',[_vm._v(_vm._s(_vm.$t('An error occurred'))+" – "+_vm._s(_vm.error))])])]),_vm._v(" "),_vm._l((_vm.items.content),function(ce){return [(ce)?_c('section',{key:ce.id,class:!_vm.items.eventDetailsOnlyContent
            ? ce.type + _vm.getBg(ce) + _vm.getSpacing(ce)
            : '',attrs:{"id":_vm.getID(ce.id)}},[(
            ce.type == 'structured_content' && !_vm.items.eventDetailsOnlyContent
          )?_c('GridElement',{key:ce.id,attrs:{"appearance":ce.appearance,"element":ce}}):(
            ce.type == 'shortcut' && _vm.checkShortcutContent(ce.content)
          )?[_vm._l((ce.content.shortcut),function(ce){return [(ce.type == 'structured_content')?_c('GridElement',{key:ce.id,attrs:{"appearance":ce.appearance,"element":ce}}):_c('DynamicElement',{key:ce.id,attrs:{"element":ce}})]})]:_c('DynamicElement',{key:ce.id,attrs:{"element":ce,"isDetail":_vm.getIsDetail(_vm.items.eventDetailsOnlyContent)}})],2):_vm._e()]})]:(_vm.loading)?_c('section',{staticClass:"bg-sand loading-section py-10 h-100"},[_vm._m(0)]):[(_vm.items.productDetail)?_c('ProductDetailHero',{attrs:{"productDetails":_vm.items.productDetail},on:{"outOfStock":_vm.toggleOutOfStockModal}}):_vm._e(),_vm._v(" "),_vm._l((_vm.items.content),function(ce){return [(ce)?_c('section',{key:ce.id,class:!_vm.items.eventDetailsOnlyContent
            ? ce.type + _vm.getBg(ce) + _vm.getSpacing(ce)
            : '',attrs:{"id":_vm.getID(ce.id)}},[(
            ce.type == 'structured_content' && !_vm.items.eventDetailsOnlyContent
          )?_c('GridElement',{key:ce.id,attrs:{"appearance":ce.appearance,"element":ce}}):(ce.type == 'news_pi1' && ce.content.data.detail)?_c('BlogDetailPage',{attrs:{"data":ce.content.data,"latest":_vm.items.latestNewsContent}}):(
            ce.type == 'headlessevents_eventslist' &&
            !_vm.items.eventDetailsOnlyContent
          )?_c('Events',{attrs:{"dataProp":ce.content}}):(ce.type == 'headlessevents_eventslist')?_c('EventsDetail',{attrs:{"data":ce.content,"eventDetailsOnlyContent":_vm.items.eventDetailsOnlyContent}}):(
            ce.type == 'shortcut' && _vm.checkShortcutContent(ce.content)
          )?[_vm._l((ce.content.shortcut),function(ce){return [(ce.type == 'structured_content')?_c('GridElement',{key:ce.id,attrs:{"appearance":ce.appearance,"element":ce}}):_c('DynamicElement',{key:ce.id,attrs:{"element":ce}})]})]:_c('DynamicElement',{key:ce.id,attrs:{"element":ce,"isDetail":_vm.getIsDetail(_vm.items.eventDetailsOnlyContent)}})],2):_vm._e()]}),_vm._v(" "),(_vm.isOutOfStockModalOpen)?_c('OutOfStockModal',{attrs:{"data":_vm.items}}):_vm._e(),_vm._v(" "),(!_vm.error && !_vm.removeNewsletter)?_c('NewsletterSection'):_vm._e(),_vm._v(" "),(!_vm.error)?_c('DynamicNewsticker'):_vm._e()],_vm._v(" "),(_vm.isPopupModalOpen && !_vm.getPopupCookie)?_c('PopupModal',{attrs:{"data":_vm.getPopupContent(),"visibleOn":_vm.isPopupModalOpen},on:{"closePopupModal":function($event){_vm.isPopupModalOpen = false}}}):_vm._e(),_vm._v(" "),(_vm.stickyButton.title && _vm.stickyButton.link && _vm.itemsInCart.length < 1)?_c('div',{staticClass:"sticky-button position-fixed bottom-50"},[_c('T3Link',{staticClass:"d-block p-1 text-decoration-none",attrs:{"href":_vm.stickyButton.link},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('i',{staticClass:"icon-shopping-cart fs-2"}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.stickyButton.title))])])]},proxy:true}],null,false,2816550889)})],1):_vm._e()],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container py-4"},[_c('h1',{staticClass:"loading-section-header mb-7"}),_vm._v(" "),_c('p',{staticClass:"loading-section-text"}),_vm._v(" "),_c('p',{staticClass:"loading-section-text"}),_vm._v(" "),_c('p',{staticClass:"loading-section-text"}),_vm._v(" "),_c('p',{staticClass:"loading-section-text"})])}]

export { render, staticRenderFns }