<template>
  <div class="breadcrumb py-3 my-0" v-if="breadcrumbs.length > 1">
    <nav aria-label="breadcrumb">
      <ol
        class="breadcrumb m-0"
        itemscope
        itemtype="https://schema.org/BreadcrumbList"
      >
        <template v-for="(breadcrumb, i) in breadcrumbs">
          <li
            itemprop="itemListElement"
            itemtype="https://schema.org/ListItem"
            itemscope
            class="breadcrumb-item body-2-regular active"
            :key="breadcrumb.title"
          >
            <meta itemprop="position" :content="i" />
            <T3Link
              itemprop="item"
              :label="getTitle(breadcrumb.title, i)"
              :toHome="true"
              v-if="i === 0"
            >
              <template #content>
                <span itemprop="name"> Home </span>
              </template>
            </T3Link>
            <T3Link
              :href="breadcrumb.link"
              itemprop="item"
              v-else-if="!breadcrumb.current"
            >
              <template #content>
                <span itemprop="name">
                  {{ breadcrumb.title }}
                </span>
              </template>
            </T3Link>
            <T3Link :href="currentURL" itemprop="item" target="_self" v-else>
              <template #content>
                <span itemprop="name">
                  {{ breadcrumb.title }}
                </span>
              </template>
            </T3Link>
          </li>
        </template>
      </ol>
    </nav>
  </div>
</template>

<script>
import T3Link from '~/components/General/T3Link.vue';

export default {
  name: 'Breadcrumb',
  components: {
    T3Link,
  },
  methods: {
    getTitle(title, i) {
      if (i === 0) {
        return 'Home';
      } else {
        return title;
      }
    },
  },
  props: {
    breadcrumbs: Array,
    currentURL: String,
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.breadcrumb-item::v-deep {
  &.active {
    color: var(--bs-anthracite);
  }

  .sf-menu-item__mobile-nav-icon {
    display: none;
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  font-family: var(--iconmoon);
  content: '\e930';
  color: var(--bs-anthracite);
}
</style>
