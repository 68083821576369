<template>
  <div class="card-review bg-sand" v-if="customerReviews">
    <Rating :star="customerReviews.rating" v-if="customerReviews.rating" />
    <div
      class="card-review--content mb-9 mb-lg-4"
      v-html="customerReviews.text"
    />
    <div class="card-review--author">
      <RatioImage
        :img-src="customerReviews.customerImage"
        :img-alt="customerReviews.customerName"
        elem-class="ratio ratio-1x1 rounded-circle overflow-hidden my-0"
      />
      <div class="card-review--author-name">
        <p>
          <strong>{{ customerReviews.customerName }}</strong>
        </p>
        <p>{{ customerReviews.customerLocation }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import RatioImage from '~/components/RatioImage';
import Rating from '~/components/Rating';

export default {
  name: 'CustomerReviews',

  components: {
    RatioImage,
    Rating,
  },

  props: {
    customerReviews: Object,
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.card-review {
  padding: 1.625rem;

  @include media-breakpoint-up(lg) {
    padding: 2rem;
  }

  &--author {
    display: flex;

    .ratio {
      width: 3.5rem;
      height: 3.5rem;

      &::before {
        display: none;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
      }
    }

    &-name {
      p {
        margin: 0;
      }
    }
  }

  .card-review--author-name {
    padding-left: 1rem;
  }
}
</style>
