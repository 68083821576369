<template>
  <div class="container pt-4 pb-4" v-if="eventData2">
    <div class="events--detail">
      <div class="events--meta mb-4">
        <span class="tag bg-sand">{{ eventData2.type }}</span>
        <h1 class="h4">{{ eventData2.title }}</h1>
        <p v-html="eventData2.description"></p>
      </div>

      <div class="row">
        <div class="col-xs-12 col-lg-8">
          <div class="row">
            <div class="col-xs-12 col-md-6">
              <template v-if="getDateTime(eventData2, 'eventStart')">
                <strong>{{ $t('Start') }}:</strong>
                <time
                  class="mb-2 d-block"
                  :datetime="getDateTime(eventData2, 'eventStart', 'raw')"
                  >{{ getDateTime(eventData2, 'eventStart') }}</time
                >
              </template>

              <template v-if="getDateTime(eventData2, 'eventEnd')">
                <strong>{{ $t('End') }}:</strong>
                <time
                  class="mb-2 d-block"
                  :datetime="getDateTime(eventData2, 'eventEnd', 'raw')"
                  >{{ getDateTime(eventData2, 'eventEnd') }}</time
                >
              </template>

              <strong>{{ $t('Location') }}:</strong>
              <p>{{ eventData2.location }}</p>
              <p v-if="eventData2.liveStream">{{ $t('Live stream') }}</p>

              <strong>{{ $t('Price') }}:</strong>
              <p>{{ getEventPrice(eventData2) }}</p>
            </div>

            <div class="col-xs-12 col-md-6">
              <ul
                class="blanklist"
                v-for="(item, index) in eventData2.speakers"
                :key="index"
              >
                <template v-if="item.name">
                  <li>
                    <strong>{{ $t('Referent') }}:</strong>
                  </li>
                  <li>
                    {{ item.name }}
                  </li>
                </template>
              </ul>
              <strong>{{ $t('Target group') }}:</strong>
              <p>{{ getGroups(eventData2.targetGroups) }}</p>
              <ul class="blanklist tag-list">
                <li v-for="tag in eventData2.tags" :key="tag.name">
                  <span class="tag bg-sand">
                    {{ tag.name }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="col-xs-12 col-lg-4">
          <Speaker
            :image-src="eventData2.contactPerson.image"
            :speaker-content="`<h6 class='mt-2 mb-0'>${getName(
              eventData2.contactPerson.name,
            )}</h6><p class='mt-0'>${$t('Contact Person')}</p>`"
            :contact="contact"
          />
        </div>
      </div>

      <!-- Event Detail Grid Elements -->
      <div class="mt-4" v-html="eventData2.longText" />
      <!-- Event Detail Trust Signal Elements -->

      <div class="pt-4">
        <h1 class="trust-signals-heading">{{ eventData2.moreAboutThis }}</h1>
        <TrustSignals class="pt-3">
          <template #content>
            <div class="d-flex row">
              <template v-for="item in trustSignalData">
                <div
                  v-if="item.data"
                  :key="item"
                  class="py-2 trust-signal-data trust-item text-left col-4 col-md-4 col-sm-12 col-xs-12 col col-lg-4 col-xl-4"
                >
                  <figure class="image mb-2 mx-auto">
                    <i :class="item.icon"></i>
                  </figure>
                  <div v-html="item.data"></div>
                </div>
              </template>
            </div>
          </template>
        </TrustSignals>
      </div>

      <!-- Event Detail Speakers Elements -->
      <template>
        <div
          class="mt-12"
          v-for="(item, index) in eventData2.speakers"
          :key="index"
        >
          <TextMediaTeaser :rowreverse="index % 2 != 0" :gallery="item">
            <template #mediaSide>
              <RatioImage
                :imgSrc="getImage(item.image)"
                :elemClass="'ratio-5x4 m-0'"
                :imgAlt="item.alt"
              />
            </template>
            <template #textSide>
              <TextContentBlock
                :headlineTop="item.name"
                :bodytext="item.description"
                :container="true"
              ></TextContentBlock>
            </template>
          </TextMediaTeaser>
        </div>
      </template>

      <!--ContentElements  -->
      <section
        v-for="ce in eventElements"
        :class="ce.type + getBg(ce) + getSpacing(ce)"
        :id="getID(ce.id)"
        :key="ce.id"
      >
        <GridElement
          :appearance="ce.appearance"
          :key="ce.id"
          :element="ce"
          v-if="ce.type == 'structured_content'"
        ></GridElement>
        <template v-else-if="ce.type == 'shortcut' && ce.content.shortcut">
          <template v-for="ce in ce.content.shortcut">
            <GridElement
              :appearance="ce.appearance"
              :key="ce.id"
              :element="ce"
              v-if="ce.type == 'structured_content'"
            ></GridElement>
            <DynamicElement
              :key="ce.id"
              :element="ce"
              :isChild="true"
              v-else
            ></DynamicElement>
          </template>
        </template>
        <DynamicElement
          :key="ce.id"
          :element="ce"
          :isChild="true"
          :type="eventData2.type"
          v-else
        ></DynamicElement>
      </section>

      <!-- eventDetailsOnlyContent -->
      <template v-for="ce in eventDetailsOnlyContent">
        <section
          :class="ce.type + getBg(ce) + getSpacing(ce)"
          :id="getID(ce.id)"
          :key="ce.id"
          v-if="ce.type == 'powermail_pi1' && !eventData2.externalRegisterLink"
        >
          <a
            :href="`#c${ce.id}`"
            id="formId"
            class="anchor"
            v-if="
              ce.type == 'powermail_pi1' && !eventData2.externalRegisterLink
            "
          />
          <GridElement
            :appearance="ce.appearance"
            :key="ce.id"
            :element="ce"
            v-if="ce.type == 'structured_content'"
          ></GridElement>
          <template v-else-if="ce.type == 'shortcut' && ce.content.shortcut">
            <template v-for="ce in ce.content.shortcut">
              <GridElement
                :appearance="ce.appearance"
                :key="ce.id"
                :element="ce"
                v-if="ce.type == 'structured_content'"
              ></GridElement>
              <DynamicElement
                :key="ce.id"
                :element="ce"
                :isChild="true"
                v-else
              ></DynamicElement>
            </template>
          </template>
          <DynamicElement
            :key="ce.id"
            :element="ce"
            :isChild="true"
            :type="eventData2.type"
            v-else
          ></DynamicElement>
        </section>
      </template>

      <EventsSubmit
        :left-content="`<p>${eventData2.title}</p>`"
        :button-text="$t('Sign-up for event')"
        @scrollPress="scrollToElement"
      />
    </div>
  </div>
</template>

<script>
import Speaker from '~/components/Speaker';
import EventsSubmit from '~/components/EventsSubmit';
import { useEventStore } from '../stores/eventStore';
import TrustSignals from '~/components/TrustSignals.vue';
import EventContactForm from '~/components/EventContactForm.vue';
import DynamicElement from '~/components/DynamicElement.vue';
import EventsItem from '~/components/EventsItem';
import GridElement from '~/components/GridElement.vue';
import TextMediaTeaser from '~/components/TextMediaTeaser.vue';
import TextContentBlock from '~/components/TextContentBlock.vue';
import RatioImage from '~/components/RatioImage.vue';

export default {
  name: 'EventsDetail',

  components: {
    Speaker,
    EventsSubmit,
    TrustSignals,
    EventContactForm,
    DynamicElement,
    EventsItem,
    GridElement,
    TextMediaTeaser,
    TextContentBlock,
    RatioImage,
  },
  props: {
    data: Object,
    eventDetailsOnlyContent: Array,
  },

  data() {
    const eventStore = useEventStore();
    return {
      eventStore,
      eventData2: null,
      contact: [],
      eventElements: [],
      trustSignalData: [],
    };
  },
  async created() {
    this.eventData2 = this.data?.events_list;
    this.eventElements = this.data?.events_list?.contentElements;
    this.trustSignalData = [
      {
        icon: 'icon-layers',
        data: this.data?.events_list?.agenda || '',
      },
      {
        icon: 'icon-book-open',
        data: this.data?.events_list?.content || '',
      },
      {
        icon: 'icon-clock',
        data: this.data?.events_list?.learnings || '',
      },
    ];
    this.contact = [
      {
        href: 'mailto:' + this.data?.events_list?.contactPerson?.email,
        text: this.data?.events_list?.contactPerson?.email || 'E-Mail',
      },
      {
        href: 'tel:' + this.data?.events_list?.contactPerson?.phone,
        text: this.data?.events_list?.contactPerson?.phone || 'Tel',
      },
    ];
  },
  methods: {
    getGroups(list) {
      let speakers = '';
      if (list?.length) {
        list.map((item) => {
          speakers += `${item?.name}, `;
        });
      } else {
        speakers = '';
      }
      return speakers?.replace(/,\s*$/, '');
    },
    getName(name) {
      if (name) {
        return name;
      } else {
        return '';
      }
    },
    getData(list) {
      if (list?.contentElements) {
        return list?.contentElements.length;
      } else {
        return 0;
      }
    },
    getRightContent(list) {
      return (
        list?.content?.structure?.rows[0]?.columns[0]?.elements[0]?.content
          ?.bodytext || ''
      );
    },
    getLeftContent(list) {
      return (
        list?.content?.structure?.rows[0]?.columns[1]?.elements[0]?.content
          ?.bodytext || ''
      );
    },
    getIconContent(list) {
      return list?.content?.structure?.rows[0]?.columns[0]?.elements || [];
    },
    scrollToElement() {
      const el = document.getElementById('formId');
      if (this.eventData2?.externalRegisterLink) {
        window.open(this.eventData2?.externalRegisterLink);
      } else {
        if (el) {
          el.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    },
    getEvents(list) {
      if (list.length) {
        return list[0]?.content?.data?.events;
      } else {
        return [];
      }
    },
    getBg(element) {
      if (element.appearance) {
        if (element.appearance.frameClass !== 'default') {
          if (element?.appearance?.subLayout) {
            return (
              ' ' +
              element.appearance.frameClass +
              ' subLayout' +
              element?.appearance?.subLayout
            );
          } else {
            return ' ' + element.appearance.frameClass;
          }
        } else {
          return '';
        }
      }
    },
    getSpacing(element) {
      let spaceBefore = '';
      let spaceAfter = '';
      let frameClass = '';
      if (element.appearance) {
        spaceBefore = element.appearance.spaceBefore;
        spaceAfter = element.appearance.spaceAfter;
        frameClass = element.appearance.frameClass;
      }
      if (!spaceBefore) {
        spaceBefore = '10';
      }
      if (!spaceAfter) {
        spaceAfter = '10';
      }
      if (frameClass == 'default' || frameClass == '') {
        return ' mt-' + spaceBefore + ' mb-' + spaceAfter;
      } else {
        return ' pt-' + spaceBefore + ' pb-' + spaceAfter;
      }
    },
    getID(id) {
      if (id) {
        return 'c' + id;
      } else {
        return '';
      }
    },
    getType(data) {
      if (data?.length) {
        return data[0]?.content?.data?.eventTypes[0]?.name || '';
      } else {
        return '';
      }
    },
    getImage(data) {
      if (data) {
        return data;
      } else {
        return '/img/dummy.png';
      }
    },
    getEventPrice(event) {
      if (event?.priceInfo) {
        return event?.priceInfo;
      }
      if (event?.price && event?.price != '0,00 €') {
        return event?.price;
      } else {
        return this.$i18n.t('free');
      }
    },
    getDateTime(event, field, raw) {
      if (!event[field]) {
        return false;
      }
      const date = new Date(event[field] * 1000);
      const uhr = this.$i18n.loadedLanguages[0] == 'de' ? ' Uhr' : '';
      if (raw) {
        return date.toISOString();
      }
      return (
        date.toLocaleString(this.$i18n.loadedLanguages[0], {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          weekday: 'long',
          hour: '2-digit',
          minute: '2-digit',
        }) + uhr
      );
    },
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars.scss';

.icon_style {
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 6.25rem;
  background: #ded9c9;
}

@include media-breakpoint-between(xs, md) {
  .event-content {
    flex-direction: column;
  }
}

@include media-breakpoint-between(xs, md) {
  .events-icons-content {
    flex-direction: column;
  }
}

.events {
  &--meta {
    .tag {
      margin-bottom: 1.5rem;
      display: block;
      max-width: max-content;
    }
  }

  &--detail {
    strong {
      margin-bottom: 0.5rem;
      display: block;
    }

    ul.blanklist {
      margin-bottom: 1rem;

      li {
        margin-bottom: 0.25rem;
      }
    }

    .tag-list {
      display: flex;
      margin: 0 -0.5rem;
      flex-wrap: wrap;
      gap: 1rem;

      li {
        margin: 0 0.5rem;
      }
    }
  }
}

.trust-signals-heading {
  font-size: 2.07375rem;
  color: #3e3e3f;
  font-weight: 500;
}

.trust-signal-data {
  p {
    font-size: 1.44rem !important;
    color: #3e3e3f !important;
  }

  li {
    font-size: 1rem !important;
    color: #3e3e3f !important;
  }
}
</style>
