<template>
  <label><span v-html="label" />{{ isMandatory(mandatory) }}</label>
</template>

<script>
export default {
  name: 'LabelComponent',

  props: {
    label: String,
    mandatory: Boolean,
  },

  methods: {
    isMandatory(mandatory) {
      if (mandatory) {
        return ' *';
      }
    },
  },
};
</script>

<style scoped></style>
