export default `
query getOrderInfo($cartId:String!) {
	CustomerOrderByCartId(cart_id: $cartId) {
		items {
			id
			product_id
			product_name
			product_sku
			product_url_key
			product_type
			status
			product_sale_price {
				currency
				value
			}
			discounts {
				amount {
					currency
					value
				}
				label
			}
			selected_options {
				label
				value
			}
			entered_options {
				label
				value
			}
			quantity_ordered
			quantity_shipped
			quantity_refunded
			quantity_invoiced
			quantity_canceled
			quantity_returned
		}
		billing_address {
			city
			company
			country_code
			fax
			firstname
			lastname
			middlename
			postcode
			prefix
			region
			region_id
			street
			suffix
			telephone
			vat_id
		}
		carrier
		comments {
			message
			timestamp
		}
		created_at
		grand_total
		id
		number
		order_date
		order_number
		payment_methods {
			additional_data {
				name
				value
			}
			name
			type
		}
		shipping_address {
			city
			company
			country_code
			fax
			firstname
			lastname
			middlename
			postcode
			prefix
			region
			region_id
			street
			suffix
			telephone
			vat_id
		}
		shipping_method
		status
		total {
			base_grand_total {
				currency
				value
			}
			grand_total {
				currency
				value
			}
		}
	}
}
`;
