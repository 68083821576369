



















import base64 from 'base-64';
import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useRouter,
} from '@nuxtjs/composition-api';
import RegistrationForm from '~/modules/customer/components/LoginModal/forms/RegisterForm.vue';
import AccountCreatedThankyou from '~/modules/customer/components/LoginModal/forms/AccountCreatedThankyou.vue';
import { RegisterFormFields } from '~/modules/customer/components/LoginModal/forms/types';
import { useApi } from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';

import { CustomerAddress } from '~/modules/GraphQL/types';
import { useAddresses } from '~/modules/customer/composables/useAddresses';

import { useMagentoConfiguration } from '~/composables/useMagentoConfiguration';

import { useUiNotification } from '~/composables/useUiNotification';

export default defineComponent({
  name: 'RegistrationFormElement',
  components: {
    RegistrationForm,
    AccountCreatedThankyou,
  },
  props: {
    element: Object,
  },
  setup() {
    const {
      registerPlusAddresses,
      loading,
      error: userError,
      isAuthenticated,
    } = useUser();

    const router = useRouter();
    const { app } = useContext();

    const { send: sendNotification, notifications } = useUiNotification();

    const registerForm = ref<RegisterFormFields>({
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      salutation: '',
      gender: '',
      title: '',
      phone: '',
      msg: '',
      billingCompany: '',
      billingAddress: '',
      billingAdditionalInfo: '',
      billingPostCode: '',
      billingCity: '',
      billingCountry: '',
    });

    // @ts-expect-error Recaptcha is not registered as a Nuxt module. Its absence is handled in the code
    const { $recaptcha, $config } = useContext();
    const isRecaptchaEnabled = ref<boolean>(
      typeof $recaptcha !== 'undefined' && Boolean($config.isRecaptcha),
    );

    const getRecaptchaInfo = async (
      isRecaptchaOn: boolean,
    ): Promise<{ token: string | null; cleanup: () => void }> => {
      if (isRecaptchaOn) {
        $recaptcha.init();
        return {
          token: await $recaptcha.getResponse(),
          cleanup: () => {
            $recaptcha.reset();
          },
        };
      }
      return { token: null, cleanup: () => {} };
    };

    const accountCreatedThankyou = ref(false);
    const accountCreatedEmail = ref('');

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    const onRegisterFormSubmit = async (form: RegisterFormFields) => {
      registerForm.value = form;
      const { token, cleanup } = await getRecaptchaInfo(
        isRecaptchaEnabled.value,
      );

      try {
        let userData = {
          email: form.email,
          firstname: form.firstName,
          lastname: form.lastName,
          password: form.password,
          prefix: form.title,
          phonenumber: form.phone,
          marketing_note: form.msg,
          gender: form.gender ? parseInt(form.gender) : null,
          ...(token ? { recaptchaToken: token } : {}),
        };

        let billingDetails = <CustomerAddress>{
          firstname: form.firstName,
          lastname: form.lastName,
          street: [form.billingAddress, form.billingAdditionalInfo],
          postcode: form.billingPostCode,
          city: form.billingCity,
          company: form.billingCompany,
          // @ts-ignore
          country_code: form.billingCountry,
          // telephone: (form.phone != "") ? form.phone : "00000000",
          default_shipping: true,
          default_billing: true,
        };

        let addressArr = [];
        addressArr.push(billingDetails);

        let jsonParam = base64.encode(JSON.stringify(addressArr));

        await registerPlusAddresses({
          user: userData,
          addressCode: jsonParam,
        });

        if (userError.value.register !== null) {
          throw new Error(userError.value.register.message);
        }

        cleanup();

        if (!userError.value.register) {
          scrollToTop();
          accountCreatedThankyou.value = true;
          accountCreatedEmail.value = form.email;
          // await Promise.all([loadItemsCount(), loadCart()]);
        }
      } catch (e) {
        console.log('Error: ', e);

        sendNotification({
          icon: 'error',
          id: Symbol(`registration_error`),
          message: e.message,
          persist: true,
          title: 'Registeration error',
          type: 'danger',
        });
      }
    };

    onMounted(async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      }
    });
    return {
      accountCreatedThankyou,
      accountCreatedEmail,

      userError,
      loading,

      isRecaptchaEnabled,
      registerForm,
      onRegisterFormSubmit,
    };
  },
});
