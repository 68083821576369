<template>
  <select :name="id" :id="id">
    <option
      :value="option.value"
      v-for="option in select"
      :key="option.name"
      :selected="option.isSelected"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'SelectComponent',

  props: {
    id: String,
    select: Array,
  },
};
</script>
