<template>
  <p v-if="!isAuthenticated">
    <button
      v-if="type == 1 || type == 4"
      type="button"
      class="btn btn-secondary ms-1"
      @click.prevent="triggerTopControlBtn(1)"
    >
      {{ $t('Login') }}
    </button>
    <button
      v-if="type == 3"
      type="button"
      class="btn btn-primary ms-1"
      @click.prevent="triggerTopControlBtn(1)"
    >
      {{ $t('LoginRegister') }}
    </button>
    <button
      v-if="type == 2 || type == 4"
      type="button"
      class="btn btn-primary ms-1"
      @click.prevent="triggerTopControlBtn(2)"
    >
      {{ $t('Register') }}
    </button>
  </p>
</template>

<script>
import { useUiState } from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';
import { useRouter, useContext } from '@nuxtjs/composition-api';
import { useMainNavStore } from '../stores/mainNavStore';
export default {
  name: 'LoginRegisterCTA',

  methods: {
    // triggerTopControlBtn(id) {
    //     if(id) {
    //         const buttonToTrigger = document.getElementById('top-controls--'+id)
    //         if(buttonToTrigger) {
    //             buttonToTrigger.click()
    //         }
    //     }
    // }
  },
  props: {
    type: Number,
  },

  setup() {
    const mainNavStore = useMainNavStore();
    const registerPath = mainNavStore.registerPath;
    const router = useRouter();
    const { app } = useContext();
    const { toggleLoginModal } = useUiState();
    const { isAuthenticated } = useUser();

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    const triggerTopControlBtn = (id) => {
      if (id === 1) {
        handleAccountClick();
        mainNavStore.updateShowDocCheckLogin(true);
      } else {
        goToRegister();
      }
    };

    const goToRegister = async () => {
      await router.push(
        app.localeRoute({
          name: 'typo3',
          params: { slug: registerPath.slice(0, -1) },
        }),
      );
    };

    return {
      triggerTopControlBtn,
      mainNavStore,
      isAuthenticated,
      // handleAccountClick,
      // goToRegister,
    };
  },
};
</script>
