<template>
  <article class="h-100 flex-wrap d-flex" :id="`uid${item.uid}`">
    <header class="w-100">
      <T3Link :href="getLink()" class="w-100" v-if="getLink()">
        <template #content
          ><RatioImage
            :imgSrc="item.image"
            elemClass="ratio-3x2 my-0 rounded-3"
            v-if="item.image"
        /></template>
      </T3Link>
      <template v-else>
        <RatioImage
          :imgSrc="item.image"
          elemClass="ratio-3x2 mt-0 mb-3 rounded-3"
          v-if="item.image"
        />
      </template>
      <h4 v-if="getHeadline()" class="fs-6">{{ getHeadline() }}</h4>
      <p v-if="getText()" v-html="getText()" />
    </header>
    <footer
      class="mt-3 d-flex justify-content-between mt-auto w-100"
      v-if="getLink() && getLinkLabel()"
    >
      <T3Link
        class="text-uppercase text-decoration-none read-more-link"
        :href="getLink()"
      >
        <template #content>
          {{ getLinkLabel() }} <i class="icon-arrow-right"></i>
        </template>
      </T3Link>
    </footer>
  </article>
</template>
<script>
import T3Link from './General/T3Link.vue';
import RatioImage from './RatioImage.vue';
export default {
  name: 'QuickOverview',
  props: {
    item: Object,
  },
  components: {
    RatioImage,
    T3Link,
  },
  methods: {
    getHeadline() {
      if (this?.item?.headline) {
        return this.item.headline;
      }
      if (this?.item?.title) {
        return this.item.title;
      }
      return false;
    },
    getText() {
      if (this?.item?.text) {
        return this.item.text;
      }
      if (this?.item?.content) {
        if (typeof this.item.content === 'object') {
          return '';
        } else {
          return this.item.content.substring(0, 200) + '...';
        }
      }
    },
    getLink() {
      if (this?.item?.link) {
        return this.item.link;
      }
      if (this?.item?.url) {
        return this.item.url;
      }
    },
    getLinkLabel() {
      if (this?.item?.link?.label) {
        return this.item.link.label;
      } else {
        return this.getHeadline();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/styles/misc/vars';
a strong {
  color: $anthracite;
}
</style>
