<template>
  <div class="events--wrapper" :class="{ loading: isLoading }">
    <div
      v-if="!events.length && !isLoading"
      class="bg-sand py-8 my-2 d-flex justify-content-center"
    >
      <h3>
        {{
          $t(
            'Unfortunately, there are currently no planned English events in this category',
          )
        }}
      </h3>
    </div>
    <template v-if="isLoading">
      <div v-for="item in [1, 2]" :key="item" class="bg-sand py-8 my-2">
        <div class="container d-flex align-items-center flex-column py-2">
          <p class="loading-section-text-event my-1"></p>
          <p class="loading-section-text-event my-1"></p>
          <p class="loading-section-text-event my-1"></p>
          <p class="loading-section-text-event my-1"></p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="events--item" v-for="(event, index) in events" :key="index">
        <div class="row">
          <div class="col-xs-12 col-xl-4 mb-3" v-if="event.startEndDates">
            <div class="row">
              <div class="col-xs-12 col-xl-6 mb-3">
                <span class="tag bg-sand">{{ event.type }}</span>
              </div>
              <div class="col-xs-12 col-xl-6">
                <div class="row">
                  <div
                    class="col-xs-12 col-sm-6 col-xl-12"
                    v-if="getDateTime(event, 'eventStart')"
                  >
                    <p>
                      <strong>
                        {{ $t('Start') }}
                      </strong>
                    </p>
                    <time :datetime="getDateTime(event, 'eventStart', 'raw')">{{
                      getDateTime(event, 'eventStart')
                    }}</time>
                  </div>
                  <div
                    class="col-xs-12 col-sm-6 col-xl-12"
                    v-if="getDateTime(event, 'eventEnd')"
                  >
                    <p class="mt-4 mt-sm-0 mt-xl-4">
                      <strong>
                        {{ $t('End') }}
                      </strong>
                    </p>
                    <time :datetime="getDateTime(event, 'eventEnd', 'raw')">{{
                      getDateTime(event, 'eventEnd')
                    }}</time>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-xl-4" v-if="event.teaserText">
            <div class="row">
              <div class="col-xs-12 col-md-6 col-xl-12">
                <div class="mb-3">
                  <a
                    class="text-decoration-none eventsTitle"
                    :href="event.detailLink"
                    ><h6>{{ event.title }}</h6></a
                  >
                  <p>{{ event.teaserText }}</p>
                </div>
              </div>
              <div
                class="col-xs-12 col-md-6 col-xl-12"
                v-if="getSpeakers(event.speakers)"
              >
                <div class="events--body-sub">
                  <div>
                    <p>
                      <strong>{{ $t('Speakers') }}:</strong>
                    </p>
                    <p>{{ getSpeakers(event.speakers) }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xs-12 col-xl-3 mt-3 mt-xl-0">
            <ul class="blanklist">
              <li>
                <strong>{{ $t('Location') }}</strong> {{ event.location }}
              </li>
              <li>
                <strong>{{ $t('Price') }}</strong> {{ getEventPrice(event) }}
              </li>
            </ul>
          </div>

          <div
            class="col-xs-12 col-xl-1 mt-5 mt-xl-0 justify-content-xl-end d-xl-flex"
          >
            <p>
              <T3Link class="btn btn-primary" :href="event.detailLink">
                <template #content>
                  {{ $t('Details') }}
                </template>
              </T3Link>
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import T3Link from '~/components/General/T3Link.vue';
export default {
  name: 'EventsItem',

  components: { T3Link },

  props: {
    events: Array,
    infos: Array,
    isLoading: Boolean,
  },
  methods: {
    getSpeakers(list) {
      let speakers = '';
      if (list?.length) {
        list.map((item) => {
          speakers += `${item?.name}, `;
        });
      } else {
        speakers = '';
      }
      return speakers?.replace(/,\s*$/, '');
    },
    getEventPrice(event) {
      if (event?.priceInfo) {
        return event?.priceInfo.length >= 42
          ? event?.priceInfo.substring(0, 42) + '…'
          : event?.priceInfo;
      }
      if (event?.price && event?.price != '0,00 €') {
        return event?.price;
      } else {
        return this.$i18n.t('free');
      }
    },
    getDateTime(event, field, raw) {
      if (!event[field]) {
        return false;
      }
      const date = new Date(event[field] * 1000);
      const uhr = this.$i18n.localeProperties.code == 'de' ? ' Uhr' : '';
      if (raw) {
        return date.toISOString();
      }
      return (
        date.toLocaleString(this.$i18n.localeProperties.code, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
          weekday: 'long',
          hour: '2-digit',
          minute: '2-digit',
        }) + uhr
      );
    },
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.events {
  &--item {
    padding: 2.875rem 0;
    border-bottom: 1px solid $bone-83;

    .btn {
      width: 100%;
      text-align: center;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

    ul {
      @include media-breakpoint-up(xl) {
        display: flex;
        margin: 0 -0.5rem;

        li {
          display: flex;
          flex-direction: column;
          width: 50%;
          max-width: 50%;
          margin: 0 0.5rem;
        }
      }
    }
  }

  &--body-sub {
    p {
      margin-bottom: 0;
    }
  }
}
.loading {
  &--product {
    padding: var(--spacer-3xl) auto;
    @include for-desktop {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem;
    }
  }

  &--product-gallery {
    padding: var(--spacer-3xl) auto;
    height: 43.75rem;
    @include for-desktop {
      padding-top: 3.75rem;
      padding-bottom: 3.75rem;
    }
  }
}
.eventsTitle {
  color: $anthracite;
}
</style>
