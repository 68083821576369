<template>
  <div class="container-full">
    <h1 class="h3 container pt-3 pb-2 px-5">
      {{ EventsHeadline }}
    </h1>

    <div class="border-top border-bottom border-bone-83 mobile__filter__nav">
      <nav class="filter container-full px-5">
        <ul
          class="blanklist d-flex align-items-center my-2 font-weight-600 lh-1"
        >
          <li class="d-inline-block">
            <strong>{{ $t('Events') }}</strong>
            <!-- <strong class="text-hazelgreen">&nbsp;{{filteredProducts.length}}</strong> -->
          </li>
          <li
            class="d-inline-block border-start border-bone-83 py-0 ms-3 ps-1 text-hazelgreen text-uppercase"
          >
            <button
              class="d-flex px-2 text-hazelgreen button__filter"
              :class="{ active: showFilter }"
              type="button"
              @click="toggleFilter()"
            >
              <span class="icon-sliders me-1">&nbsp;</span>
              <strong
                >Filter
                <small v-if="activeFilters"
                  >({{ activeFilters }})</small
                ></strong
              >
            </button>
          </li>
          <li>
            <ListFilterEvent
              :allEventLabel="allEventLabel"
              :filters="eventTypes"
              :applyFilter="applyFilter"
              :selectedEvents="selectedEvents"
            />
          </li>
        </ul>
      </nav>
    </div>
    <!-- FILTER -->
    <div class="container-full px-5">
      <div class="row mx-lg-n2 events__dropdown__row">
        <aside
          class="col-sm-12 col-md-9 col-lg-5 col-xl-4 col-xxl-3 events__dropdown"
          v-show="showFilter"
        >
          <div class="box bg-sand p-2 shadow my-3">
            <p class="text-end mb-0">
              <button type="button" @click="toggleFilter()" class="pe-0">
                <i class="icon-x"></i>
              </button>
            </p>
            <!-- <fieldset>
               <label class="form-label">Speaker</label>
               <select name="filter-sort" id="filter-sort" class="form-select" v-model="something">
                 <option v-for="(item, index) in getSpeaker(events)" :key="index" :value="item">{{item.name}}</option>
               </select>
             </fieldset>
             <hr class="my-3 border-bone-83">
             <legend class="form-label">Location</legend>
             <fieldset class="form-check" v-for="(item, index) in getLocation(events)" :key="index">
               <input
               type="checkbox"
               :id="`filter-${index}`"
               :name="`filter-${item}`"
               :value="item"
               class="form-check-input"
               v-model="selectedFilter">
               <label class="form-check-label" :for="`filter-${index}`">{{item}}</label>
             </fieldset>
             <hr class="my-3 border-bone-83"> -->
            <legend class="form-label">
              {{ $t('Type of events') }} ({{ $t('select one') }})
            </legend>
            <fieldset class="form-check" v-for="item in eventTypes" :key="item">
              <input
                type="radio"
                :id="item"
                :name="item"
                :value="replaceAll(item)"
                class="form-check-input"
                v-model="selectedEvents"
              />
              <label :for="item">{{ item }}</label>
            </fieldset>
            <hr class="my-3 border-bone-83" />
            <legend class="form-label">{{ $t('Live Stream') }}</legend>

            <fieldset
              v-for="(item, index) in liveStreamFilter"
              :key="index"
              class="form-check"
            >
              <input
                type="radio"
                :id="item"
                :name="item"
                :value="item"
                v-model="isLive"
                class="form-check-input"
              />
              <label :for="item">{{ $t(item) }}</label>
            </fieldset>

            <hr class="my-3 border-bone-83" />
            <IconList
              :iconList="[
                {
                  headline:
                    'Alle unsere Produkte sind natürlich und frei von künstlichen Zusatzstoffen sowie Schadstoffen.',
                  icon: 'icon-heart',
                },
              ]"
            />
            <!-- <hr class="my-3 border-bone-83"> -->
            <fieldset class="d-flex filter__buttons">
              <button
                type="button"
                class="btn btn-outline border-hazelgreen text-hazelgreen"
                @click="applyFilter(selectedEvents, isLive)"
              >
                {{ $t('Apply filters') }}
              </button>
              <button
                type="button"
                class="btn btn-outline border-hazelgreen text-hazelgreen"
                @click="applyFilter('', '')"
              >
                {{ $t('Reset') }}
              </button>
            </fieldset>
          </div>
        </aside>
        <EventsItem
          :events="getAllEvents"
          :isLoading="languageStore.eventLoading"
        />
      </div>
    </div>

    <div
      v-if="events.length > 3"
      @click="loadMoreEvents()"
      class="d-flex justify-content-center mt-6 mb-6"
    >
      <a class="btn btn-secondary">
        {{ $t(getButtonTitle) }}
      </a>
    </div>
  </div>
</template>

<script>
import EventsItem from '~/components/EventsItem';
import IconList from '~/components/IconList';
import { replaceAll } from '~/composables/utils/functions';
import ListFilterEvent from '../components/ListFilterEvent.vue';
import { useLanguageStore } from '../stores/languageStore';

export default {
  name: 'Events',

  components: {
    ListFilterEvent,
    EventsItem,
    IconList,
  },
  props: {
    dataProp: Object | Array,
  },
  data() {
    const languageStore = useLanguageStore();

    return {
      languageStore,
      showFilter: false,
      EventsHeadline: this.dataProp?.header,
      activeFilters: 0,
      moreEventsText: 'Load More Events',
      events: [],
      eventTypes: [],
      liveStreamFilter: ['Yes', 'No'],
      selectedEvents: this.$route.query.type,
      isLive: this.getIsLive(),
      noMorePages: true,
      loadMoreLink: '',
      isLoadMore: true,
      replaceAll,
      allEventLabel: '',
    };
  },
  computed: {
    getAllEvents: function () {
      return this.isLoadMore ? this.events?.slice(0, 3) : this.events;
    },
    getButtonTitle: function () {
      return this.isLoadMore ? 'Load more trainings' : 'Show less training';
    },
  },

  methods: {
    getIsLive: function () {
      if (this.$route.query.live) {
        return this.$route.query.live == 'true' ? 'Yes' : 'No';
      } else {
        return '';
      }
    },
    loadMoreEvents() {
      this.isLoadMore = !this.isLoadMore;
    },

    toggleFilter() {
      this.showFilter = !this.showFilter;
    },

    applyFilter(typeValue, live) {
      let type = replaceAll(typeValue);
      let path = '';
      this.selectedEvents = type;
      if (type) {
        path = `?type=${type}`;
        this.events = this.languageStore.eventList.filter(
          (item) => replaceAll(item.type) == type,
        );
      }
      if (live) {
        path = `?live=${live == 'Yes' ? true : false}`;
        this.events = this.languageStore.eventList.filter(
          (item) => item.isLiveStream == (live == 'Yes' ? true : false),
        );
      }
      if (type && live) {
        path = `?type=${type}&live=${live == 'Yes' ? true : false}`;
        this.events = this.languageStore.eventList.filter(
          (item) =>
            replaceAll(item.type) == type &&
            item.isLiveStream == (live == 'Yes' ? true : false),
        );
      }
      if (!type && !live) {
        path = location.pathname;
        this.events = this.languageStore.eventList;
      }
      if (history.pushState) {
        history.pushState(null, '', path);
      }
      this.showFilter = false;
    },

    changeSelect(event) {
      this.selectedEvents = event;
    },
  },

  async created() {
    this.eventTypes = this.languageStore.eventTypeList?.map(
      (item) => item.name,
    );
    this.applyFilter(this.$route.query.type, this.getIsLive());
    this.allEventLabel =
      this.languageStore.eventData?.content?.events_list?.allEventsLabel || '';
  },
};
</script>

<style lang="scss" scoped>
@import 'assets/styles/misc/vars';

.mobile__filter__nav {
  @include media-breakpoint-down(md) {
    overflow-x: auto;
  }

  .button__filter {
    @include media-breakpoint-down(md) {
      padding-top: 0.9375rem;
    }
  }
}
.events__dropdown__row {
  position: relative;

  .events__dropdown {
    position: absolute;
    z-index: 1;
  }
}

.filter__buttons {
  gap: 1rem;
}
</style>
