<template>
  <div class="product-detail-hero container">
    <div class="row">
      <div class="col-12 col-lg-5 product-detail-hero__image-wrapper">
        <ProductDetailHeroSlider
          :slides="productDetails.detailImages"
          :sale="productDetails.discountPrice"
          :outOfStock="productDetails.outOfStock"
        />
      </div>

      <div class="col-12 col-lg-7 ps-lg-5 ps-xl-6">
        <template v-if="productDetails.outOfStock != true">
          <span
            class="badge badge-status status-success bg-sand body-2-semibold shadow-none"
            ><span></span>{{ $t('Product available') }}</span
          ></template
        >
        <template v-else
          ><span
            class="badge badge-status status-danger bg-sand body-2-semibold shadow-none"
            >{{ $t('Product not available') }}</span
          ></template
        >

        <p
          class="text-preset-details mt-2 mb-1"
          v-if="productDetails.productLegalNote"
        >
          {{ productDetails.productLegalNote }}
        </p>

        <h1 class="h4 mt-1 mb-3" v-if="productDetails.title">
          {{ productDetails.title }}
        </h1>

        <div
          class="product-description"
          v-if="productDetails.detailText"
          v-html="productDetails.detailText"
        ></div>

        <div class="text-end my-5 mt-lg-0 mb-lg-5">
          <button
            @click="scrollToContent()"
            id="read-more-btn"
            class="btn btn-text btn-icon"
          >
            <span
              >{{ $t('Read more') }}<i class="icon icon-arrow-down"></i
            ></span>
          </button>
        </div>

        <ProductKpi
          :nutritions="productDetails.nutritions"
          :attributes="productDetails.attributes"
          :product="productDetails"
        />

        <div class="d-lg-none">
          <div class="price text-end mt-2 py-2">
            <template
              v-if="!productDetails.discountPrice && productDetails.price"
            >
              <span class="d-block text-preset-details">
                {{ $fc(productDetails.price) }}
              </span>
            </template>
            <template v-else>
              <span
                v-if="productDetails.price"
                class="d-block body-3-regular text-decoration-line-through text-danger"
                >{{ $fc(productDetails.price) }}</span
              >
              <span
                class="d-block text-preset-details"
                v-if="productDetails.discountPrice"
              >
                {{ $fc(productDetails.discountPrice) }}
              </span>
            </template>
          </div>

          <ProductWeightInfo
            class="text-end mb-2"
            :product="productDetails"
            :tax="productDetails.productTaxLabel"
          />

          <div
            class="info body-3-regular text-end mb-1"
            v-if="productDetails.smallInfo"
            v-html="productDetails.smallInfo"
          ></div>
        </div>

        <ProductAddToCartBar 
          :productDetails="productDetails" 
          @outOfStock="$emit('outOfStock')"
        />
      </div>
    </div>
    <span ref="anchor"></span>
  </div>
</template>

<script>
import HeroHeaderBlogDetail from '../HeroHeaderBlogDetail.vue';
import ProductAddToCartBar from './ProductAddToCartBar.vue';
import ProductDetailHeroSlider from './ProductDetailHeroSlider.vue';
import ProductKpi from './ProductKpi.vue';
import ProductShowContentsByType from './ProductShowContentsByType.vue';
import { useLanguageStore } from '@/stores/languageStore';
import ProductWeightInfo from '@/components/ProductWeightInfo.vue';
import { computed, useContext } from '@nuxtjs/composition-api';
export default {
  components: {
    ProductDetailHeroSlider,
    HeroHeaderBlogDetail,
    ProductShowContentsByType,
    ProductKpi,
    ProductAddToCartBar,
    ProductWeightInfo,
  },
  name: 'ProductDetailHero',
  props: {
    productDetails: Object,
  },
  methods: {
    scrollToContent() {
      const el = this.$refs.anchor;
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
  computed: {
    mergedKPIArray: function () {
      return this.productDetails.nutritions.concat(
        this.productDetails.attributes,
      );
    },
  },

  setup(props) {
    const { app } = useContext();

    try {
      app.$gtm.push({ ecommerce: null });
      app.$gtm.push({
        event: 'view_item',
        ecommerce: {
          items: [
            {
              item_id: props.productDetails.magentoSku,
              item_name: props.productDetails.title,
              item_brand: 'tisso',
              price: props.productDetails.price,
              quantity: 1,
            },
          ],
        },
      });
    } catch (e) {}
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'assets/styles/misc/vars';

.product-detail-hero {
  > .row {
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
      overflow-x: inherit;
    }
  }
  &__image-wrapper {
    padding-right: 0.625rem;
  }
  .product-description {
    > :last-child {
      margin-bottom: 0;
    }
  }
  .price {
    border-top: $border-1;
    border-color: $bone;
  }
  .info {
    * {
      font-size: 0.75rem;
      color: $gray-500;
      margin: 0;
    }
  }
  // .add-to-cart-bar {
  //   z-index: 9;
  //   .middle {
  //     border-right: $border-1;
  //     border-color: $bone-83;
  //   }
  // }
}
</style>
