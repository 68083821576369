<template>
  <form
    id="formId"
    class="pt-10 pb-10 event__registration__form"
    :action="getFormAction(data)"
    method="post"
    name="form_event_registration"
  >
    <div class="row form_parent">
      <h1 class="form_heading">{{ geHeading(data) }}</h1>
      <div class="mb-2" v-html="getDescription(data)" />

      <hr class="sf-divider mb-4" />
      <template v-for="item in getFields(data)">
        <div
          class="col-xs-12 col-lg-6"
          v-for="(form, index) in getSubFields(item)"
          :key="index"
          :class="checkHalfWidth(form.field.css)"
        >
          <div class="form-group">
            <InputComponent
              v-if="form.field.type === 'text'"
              :type="form.field.type"
              :id="getFieldId(form)"
              :placeholder="form.field.placeholder"
              :required="form.field.label.mandatory"
              :name="form.field.name"
            />
            <InputComponent
              v-if="form.field.type === 'email'"
              :type="form.field.type"
              :id="getFieldId(form)"
              :placeholder="form.field.placeholder"
              :required="form.field.label.mandatory"
              :name="form.field.name"
            />

            <InputComponent
              v-if="form.field.type === 'tel'"
              :type="form.field.type"
              :id="getFieldId(form)"
              :placeholder="form.field.placeholder"
              :required="form.field.label.mandatory"
              :name="form.field.name"
              pattern="[0-9]*"
            />

            <SelectComponent
              v-if="form.field.type === 'select'"
              :id="getFieldId(form)"
              :select="form.field.options"
              :required="form.field.label.mandatory"
              :name="form.field.name"
            />

            <TextareaComponent
              v-if="form.field.type === 'textarea'"
              :id="getFieldId(form)"
              :placeholder="form.placeholder"
              :required="form.field.label.mandatory"
              :name="form.field.name"
            />

            <LabelComponent
              v-if="form.field.label"
              :for="getFieldId(form)"
              :label="form.field.label.title"
              :mandatory="form.field.label.mandatory"
              :class="form.field.label.labelClasses"
            />
            <button
              type="submit"
              v-if="form.field.type === 'submit'"
              class="btn btn-primary registration_form_btn"
              :class="form.field.submitClasses"
            >
              {{ form.field.title }}
            </button>
          </div>
        </div>
        <hr v-if="item.title !== 'Page 3'" class="sf-divider mb-4" />
      </template>
    </div>
    <template v-for="hiddenField in getHiddenFields(data)">
      <input
        type="hidden"
        :value="getHiddenFieldsValue(hiddenField.value)"
        :name="hiddenField.name"
        :key="hiddenField.name"
      />
    </template>
  </form>
</template>

<script>
import InputComponent from '~/components/InputComponent';
import SelectComponent from '~/components/SelectComponent';
import LabelComponent from '~/components/LabelComponent';
import TextareaComponent from '~/components/TextareaComponent';

export default {
  name: 'EventContactForm',

  components: {
    LabelComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
  },

  props: {
    data: Object,
    type: String,
  },

  methods: {
    getFieldId(form) {
      return form?.field?.id;
    },
    checkHalfWidth(halfWidth) {
      if (halfWidth == 'halfWidth') {
        return 'col-lg-6';
      }
    },
    getFields(data) {
      if (data?.content?.data?.form?.fields?.pages?.length) {
        return data.content.data.form.fields.pages;
      } else {
        return [];
      }
    },
    getSubFields(data) {
      if (data?.fields?.length) {
        return data?.fields;
      } else {
        return [];
      }
    },
    getFormAction(data) {
      return data?.content?.data?.form?.fields?.action;
    },
    getHiddenFields(data) {
      if (data) {
        return data?.content?.data.form.fields?.hiddenFields;
      } else {
        return [];
      }
    },
    geHeading(data) {
      return data?.content?.header || '';
    },
    getDescription(data) {
      return data?.content?.bodytext || '';
    },
    getHiddenFieldsValue(value) {
      if (this.type != 'Webinar' && String(value).includes('eventurl')) {
        return location.href;
      } else {
        return value;
      }
    },
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';
@import 'assets/styles/misc/icons';

#formId {
  scroll-margin-top: 6.25rem;
}

.event__registration__form {
  width: 70%;
  margin: 0 auto;
  @include media-breakpoint-between(xs, md) {
    width: 100%;
  }

  .form_parent {
    background: #f9f8f3;
    padding: 2rem 2.5rem;
    box-shadow:
      0 1px 0.125rem rgba(57, 59, 43, 0.24),
      0 0.125rem 0.5rem 0.125rem rgba(57, 59, 43, 0.08);
    border-radius: 0.5rem;
    @include media-breakpoint-between(xs, md) {
      padding: 2rem 1.5rem;
    }

    .form_heading {
      font-size: 1.44rem;
      font-weight: 500;
      color: #000000;
    }
  }
  .form-group {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1.5rem;
    position: relative;

    label {
      font-size: 0.875rem;
      font-weight: 600;
      margin: 0 $border-radius $border-radius;
    }

    input,
    textarea,
    select {
      padding: 0.65625rem 1rem;
      border: 1px solid $bone-83;
      border-radius: $border-radius;
    }

    textarea {
      resize: none;
      height: 6.25rem;
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;

      ~ label {
        position: relative;

        &::before {
          @extend .iconElement;
          content: $icon-chevron-down;
          display: block;
          position: absolute;
          top: calc(100% + 1.0625rem);
          right: 0.4375rem;
          font-size: 1.5rem;
          color: $hazelgreen;
        }
      }
    }

    .btn.btn-primary {
      width: 100%;
      margin-top: 1.5rem;
      text-align: center;
    }
  }
}
</style>
