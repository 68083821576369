<template>
  <div>
    <template v-for="ce in itemsContent">
      <section
        :class="
          !itemsEventDetailsOnlyContent
            ? ce.type + getBg(ce) + getSpacing(ce)
            : ''
        "
        :id="getID(ce.id)"
        v-if="ce"
        :key="ce.id"
      >
        <GridElement
          :appearance="ce.appearance"
          :key="ce.id"
          :element="ce"
          v-if="
            ce.type == 'structured_content' && !itemsEventDetailsOnlyContent
          "
        ></GridElement>
        <BlogDetailPage
          :data="ce.content.data"
          v-else-if="ce.type == 'news_pi1' && ce.content.data.detail"
          :latest="itemsLatestNewsContent"
        />
        <Events
          v-else-if="
            ce.type == 'headlessevents_eventslist' &&
            !itemsEventDetailsOnlyContent
          "
          :dataProp="ce.content"
        />
        <EventsDetail
          v-else-if="ce.type == 'headlessevents_eventslist'"
          :data="ce.content"
          :eventDetailsOnlyContent="itemsEventDetailsOnlyContent"
        />
        <template
          v-else-if="ce.type == 'shortcut' && checkShortcutContent(ce.content)"
        >
          <template v-for="ce in ce.content.shortcut">
            <GridElement
              :appearance="ce.appearance"
              :key="ce.id"
              :element="ce"
              v-if="ce.type == 'structured_content'"
            ></GridElement>
            <DynamicElement :key="ce.id" :element="ce" v-else></DynamicElement>
          </template>
        </template>
        <DynamicElement
          :key="ce.id"
          :element="ce"
          :isDetail="getIsDetail(itemsEventDetailsOnlyContent)"
          v-else
        ></DynamicElement>
      </section>
    </template>
  </div>
</template>

<script>
import GridElement from '~/components/GridElement.vue';
import DynamicElement from '~/components/DynamicElement.vue';
import BlogDetailPage from './BlogDetailPage.vue';
import Events from '~/components/EventsOverview.vue';
import EventsDetail from '~/components/EventsDetail.vue';

export default {
  name: 'T3PageContent',
  components: {
    GridElement,
    DynamicElement,
    BlogDetailPage,
    Events,
    EventsDetail,
  },

  props: {
    itemsContent: Array || Object,
    itemsEventDetailsOnlyContent: {
      type: Boolean,
      default: true,
    },
    itemsLatestNewsContent: Array,
  },

  setup() {
    const getBg = (element) => {
      if (element?.content?.shortcut) {
        element = element?.content?.shortcut[0];
      }
      if (this.config.container && element?.appearance) {
        if (element?.appearance?.frameClass !== 'default') {
          if (element?.appearance?.subLayout) {
            return (
              ' ' +
              element?.appearance?.frameClass +
              ' subLayout' +
              element?.appearance?.subLayout
            );
          } else {
            return ' ' + element?.appearance?.frameClass;
          }
        } else {
          return '';
        }
      }
    };
    const getSpacing = (element) => {
      if (element?.content?.shortcut) {
        element = element?.content?.shortcut[0];
      }
      let spaceBefore = '';
      let spaceAfter = '';
      let frameClass = '';
      if (element?.appearance) {
        spaceBefore = element?.appearance?.spaceBefore;
        spaceAfter = element?.appearance?.spaceAfter;
        frameClass = element?.appearance?.frameClass;
      }
      if (!spaceBefore) {
        spaceBefore = '10';
      }
      if (!spaceAfter) {
        spaceAfter = '10';
      }
      if (frameClass == 'default' || frameClass == '') {
        return ' mt-' + spaceBefore + ' mb-' + spaceAfter;
      } else {
        return ' pt-' + spaceBefore + ' pb-' + spaceAfter;
      }
    };
    const getID = (id) => {
      if (id) {
        return 'c' + id;
      } else {
        return '';
      }
    };
    const getIsDetail = (list) => {
      return list?.length ? true : false;
    };
    const checkShortcutContent = (el) => {
      if (el?.shortcut) {
        return true;
      } else {
        return false;
      }
    };

    return {
      getBg,
      getSpacing,
      getID,
      getIsDetail,
      checkShortcutContent,
    };
  },
};
</script>
