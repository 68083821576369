<template>
  <div class="event--submit bg-sand shadow">
    <div class="container">
      <div class="row">
        <div class="col-xs-12 col-md-6 d-none d-md-block">
          <div v-html="leftContent" />
        </div>

        <div class="col-xs-12 col-md-6 d-flex justify-content-end">
          <p>
            <button class="btn btn-primary" @click="$emit('scrollPress')">
              {{ buttonText }}
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EventsSubmit',
  props: {
    leftContent: String,
    buttonHref: String,
    buttonText: String,
    scrollPress: Function,
  },
};
</script>

<style lang="scss">
.event--submit {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 99;
  padding: 1.6875rem 0;

  [class*='col-'] {
    p:last-child {
      margin: 0;
    }
  }
}
</style>
