<template>
  <div class="download-list">
    <h1 v-html="folderName" />
    <div v-html="detailText" />
    <template v-for="(item, index) in downloadList">
      <div
        class="download-list--item"
        v-if="index < showItems"
        :key="item.name"
      >
        <a :href="item.downloadLink">
          <button type="button">
            <i class="icon-download"></i>
          </button>
          <figure class="image">
            <a
              v-html="item.thumbnail"
              :href="item.fileViewLink"
              target="_blank"
            />
          </figure>
          <div class="download-list--content">
            <p>
              <strong>
                {{ item.name }}
              </strong>
            </p>

            <p>
              {{ item.description }}
            </p>
          </div>
        </a>
      </div>
    </template>

    <div class="row mt-4" v-if="showItems < downloadList.length">
      <div class="col-xs-12 d-flex justify-content-center">
        <button
          type="button"
          class="btn btn-secondary"
          @click="setShowItems(loadItems)"
        >
          {{ $t('show more') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DownloadList',

  data() {
    return {
      showTotal: 0,
      showItems: 0,
    };
  },

  props: {
    downloadList: Array,
    folderName: String,
    detailText: String,
    maxItems: Number,
    loadItems: {
      type: Number,
      default: 5,
    },
  },

  methods: {
    setShowItems(count) {
      const total = this.downloadList.length;

      if (this.showItems + count < total) {
        this.showItems = this.showItems + count;
      } else {
        this.showItems = total;
      }
    },
  },

  created() {
    this.setShowItems(this.maxItems);
  },
};
</script>

<style lang="scss">
.download-list {
  figure.image {
    width: 7.5rem;
    height: 7.5rem;
    margin-bottom: 0;

    img {
      width: 7.5rem;
      height: 7.5rem;
      object-fit: cover;
    }
  }
}
</style>

<style scoped lang="scss">
@import 'assets/styles/misc/vars';

.download-list {
  &--item {
    position: relative;
    margin-bottom: 2rem;

    a {
      display: flex;
      text-decoration: none;
      position: relative;

      p {
        color: $anthracite;
      }
    }

    button {
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;

      i[class*='icon-'] {
        &::before {
          font-size: 1.5rem;
        }
      }

      @include media-breakpoint-up(lg) {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    p {
      margin: 0;
    }

    &:first-child {
      padding-top: 1rem;

      @include media-breakpoint-up(lg) {
        margin-top: 0.5rem;
      }
    }

    @include media-breakpoint-up(lg) {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
      border-radius: $border-radius;

      &:first-child {
        padding-top: 0.5rem;
      }
    }

    @include media-breakpoint-up(lg) {
      border: 1px solid transparent;

      &:hover {
        background: $sand;
        border: 1px solid $bone-83;

        button {
          i[class*='icon-'] {
            &::before {
              color: $hazelgreen;
            }
          }
        }

        a {
          color: $hazelgreen;

          p,
          strong {
            color: $hazelgreen;
          }
        }
      }
    }
  }

  &--content {
    margin-left: 0.75rem;
  }
}
</style>
