<template>
  <div
    class="notify-banner py-1 py-md-2"
    v-if="noteText"
    :class="{ 'd-none': isClosed }"
  >
    <div class="container">
      <button @click="persist()" type="button" class="icon-x">
        <span class="d-none">{{ $t('Close') }}</span>
      </button>

      <T3Link
        :href="noteSrc"
        class="d-flex justify-content-center w-100 align-items-center align-items-lg-start"
        v-if="noteSrc"
      >
        <template #content>
          {{ noteText }}
          <i class="icon-arrow-right" :class="arrowTrueFunction"></i><br />
        </template>
      </T3Link>
      <p
        v-else
        class="d-flex justify-content-center w-100 align-items-center align-items-lg-start p-0 m-0"
      >
        {{ noteText }}
      </p>
    </div>
  </div>
</template>

<script>
import T3Link from './General/T3Link.vue';

export default {
  name: 'NotificationBanner',
  data() {
    return {
      isClosed: false,
    };
  },
  props: {
    noteText: String,
    noteSrc: String,
    arrowTrue: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    arrowTrueFunction() {
      return [this.arrowTrue ? 'd-inline-block' : ''];
    },
  },
  mounted() {
    if (localStorage.close) this.isClosed = localStorage.close;
  },
  methods: {
    persist() {
      localStorage.close = !this.isClosed;
      this.isClosed = !this.isClosed;
    },
  },
  components: { T3Link },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.notify-banner {
  // padding: .6875rem 0;
  a {
    max-width: 90%;
    @include media-breakpoint-up(md) {
      max-width: initial;
    }
  }

  a,
  p {
    text-decoration: none;
    font-weight: 600;
    color: $anthracite;
    line-height: 1.3125rem;
    font-size: 0.875rem;

    @include media-breakpoint-up(lg) {
      line-height: 1.7rem;
      font-size: 1rem;
    }

    [class*='icon-'] {
      display: none;
      margin-left: 0.75rem;
    }

    &:hover {
      color: $anthracite;
    }
  }

  > .container {
    position: relative;
  }

  button.icon-x {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    transition: $transition-base;

    &::before {
      font-size: 1.5rem;
      z-index: 2;
      position: relative;
      top: 1px;
      left: -0.25rem;
    }

    &::after {
      content: '';
      width: 1.75rem;
      height: 1.75rem;
      background: $hazelgreen;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      z-index: 1;
      transform: scale(0.5);
      opacity: 0;
      transition: $transition-base;
    }

    &:hover {
      &::before {
        color: $white;
      }

      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
}
</style>
