














































































import {
  defineComponent,
  ref,
  useContext,
  onMounted,
  useRoute,
  useFetch,
  computed,
} from '@nuxtjs/composition-api';
import { SfImage, SfLoader } from '@storefront-ui/vue';
import { useApi } from '~/composables';

import getOrderInfoGql from '~/customQueries/order/queries/getOrderInfo.gql';
import type { ProductDetailsQuery } from '~/modules/GraphQL/types';
import getCustomMultiProductOptionsGql from '~/customQueries/product/queries/getCustomMultiProductOptions.gql';
import getProductAdditionalDataGql from '~/customQueries/product/queries/getProductAdditionalData.gql';
import { useImage } from '~/composables';

export default defineComponent({
  name: 'CheckoutThankyouElement',
  components: {
    SfImage,
    SfLoader,
  },
  props: {
    element: Object,
  },
  setup() {
    const { query } = useApi();
    const route = useRoute();
    const { getMagentoImage } = useImage();

    const { app } = useContext();

    const haveProducts = ref(false);

    const groupProducts = ref({
      onetime: [],
      sub: [],
    });

    const productExtOpt = ref([]);
    const loading = ref(false);

    const getInhaltValue = (id) => {
      let str = '';
      if (id) {
        if (productExtOpt.value.length > 0) {
          productExtOpt.value.forEach((prod) => {
            if (prod.id == id) {
              let pType = prod.inhalt_label;
              if (pType == 'powder') {
                str =
                  prod.unit_value +
                  ' ' +
                  prod.unit_type +
                  ' ' +
                  app.i18n.t(pType);
              } else if (pType == 'other') {
                str = prod.unit_value + ' ' + prod.unit_type;
              } else if (
                pType == 'capsules' ||
                pType == 'drops' ||
                pType == 'tablets'
              ) {
                str =
                  prod.unit_value +
                  ' ' +
                  prod.unit_type +
                  ' = ' +
                  prod.inhalt +
                  ' ' +
                  app.i18n.t(pType);
              } else if (pType == 'liquid') {
                str = prod.unit_value + ' ' + prod.unit_type;
              } else {
                str = prod.unit_value + ' ' + prod.unit_type;
              }
            }
          });
        }
      }

      return str;
    };

    const getItemImage = (id) => {
      let image = '';
      if (id) {
        if (productExtOpt.value.length > 0) {
          productExtOpt.value.forEach((prod) => {
            if (prod.id == id) {
              image = prod?.swatch_image || '';
            }
          });
        }

        if (image !== '') {
          // image = getMagentoImage(image);

          let base =
            'https://tisso-assets.s3.eu-central-1.amazonaws.com/tisso_media/media/catalog/product';
          if (image[0] == '/') {
            image = base + image;
          } else {
            image = base + '/' + image;
          }
        }
      }

      return image;
    };

    const getGroupedItems = (orderObj) => {
      if (orderObj == null) return null;

      let items = orderObj.items;

      let groupedItems = {
        onetime: [],
        sub: [],
      };

      for (let item of items) {
        let customOption = item.entered_options;
        if (customOption.length > 0) {
          groupedItems['sub'].push(item);
        } else {
          groupedItems['onetime'].push(item);
        }
      }

      return groupedItems;
    };

    onMounted(async () => {
      const { token } = route.value.query;

      loading.value = true;

      if (token) {
        // let cartId = "IpeLonqZX9O2LNF9HOP7XrpcSSPfzX2r";
        let cartId = token;
        const { data, errors } = await query(getOrderInfoGql, {
          cartId: cartId,
        });

        if (!errors && data) {
          // @ts-ignore
          let orderedInfo = data?.CustomerOrderByCartId || null;
          if (orderedInfo) {
            let urlArr = [];
            // @ts-ignore
            orderedInfo.items.forEach((el) => {
              urlArr.push(el.product_url_key);
            });
            const { data: data1 } = await query<ProductDetailsQuery>(
              getProductAdditionalDataGql,
              {
                filter: { url_key: { in: urlArr } },
              },
            );
            productExtOpt.value = data1.products?.items;

            groupProducts.value = getGroupedItems(orderedInfo);
            haveProducts.value = true;
          }
        }
      }

      loading.value = false;
    });

    return {
      loading,

      getItemImage,
      getMagentoImage,
      getInhaltValue,

      groupProducts,
      haveProducts,
    };
  },
});
