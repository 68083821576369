<template>
  <textarea :id="id" :placeholder="placeholder" :required="required"></textarea>
</template>

<script>
export default {
  name: 'TextareaComponent',

  props: {
    id: String,
    placeholder: String,
    required: Boolean,
  },
};
</script>

<style scoped></style>
