<template>
  <div v-if="!isDetail" :class="getContainerClass(element)">
    <template
      v-if="
        element.content.header &&
        element.content.headerLayout != 0 &&
        element.type !== 'html' &&
        getEventConditions(element.type)
      "
    >
      <template v-if="element.type == 'apdocchecklogin_doccheckauthentication'">
        <header
          class="doccheck-ifram-element"
          :class="{
            'mb-7': element.type !== 'powermail_pi1',
            container:
              element.type === 'headlessproducts_productslisting' ||
              element.type === 'news_pi1',
          }"
          v-if="element.content.header"
        >
          <div
            v-html="
              getHeadline(element.content.header, element.content.headerLayout)
            "
          ></div>
          <p v-if="element.content.subheader">
            <strong v-html="element.content.subheader"></strong>
          </p>
        </header>
      </template>
      <template v-else>
        <header
          :class="{
            'mb-7': element.type !== 'powermail_pi1',
            container:
              element.type === 'headlessproducts_productslisting' ||
              element.type === 'news_pi1',
          }"
          v-if="element.content.header"
          v-html="
            getHeadline(element.content.header, element.content.headerLayout)
          "
        />
      </template>
    </template>

    <AutomaticSlider
      v-if="element.type == 'news_ticker'"
      :news="element.news"
    ></AutomaticSlider>

    <template v-if="element.type == 'textmedia'">
      <div v-html="element.content.bodytext" />
      <LoginRegisterCTA :type="element.content.showLoginRegister" />
    </template>

    <TextMediaTeaser
      v-if="element.type == 'textpic'"
      :rowreverse="element.content.gallery.position.horizontal == 'right'"
      :gallery="element"
      @onClickEnlargeImage="openEnlargedImgModal('textpic')"
    >
      <template #mediaSide>
        <RatioImage
          :imgSrc="getImageProcessedURL(element)"
          :elemClass="'ratio-5x4 m-0'"
          :imgAlt="getImageAlt(element)"
          v-if="getImageProcessedURL(element)"
        />
      </template>
      <template #textSide>
        <TextContentBlock
          :bodytext="element.content.bodytext"
          :container="config.container"
          :fullwidth="element.content.full_width_text"
        >
        </TextContentBlock>
        <LoginRegisterCTA :type="element.content.showLoginRegister" />
      </template>
    </TextMediaTeaser>

    <EnlargeImgModal
      :sourceImg="enlargeImgSource"
      :slideIndex="0"
      :modalIsOpen="modalIsOpen"
      @onModalClose="modalIsOpen = false"
    >
    </EnlargeImgModal>

    <RelatedTopics
      :sectionHeadline="headline"
      :card="element"
      v-if="element.type == 'product_category_teaser'"
    />

    <TextContentBlock
      :bodytext="element.content.bodytext"
      v-if="element.type == 'text'"
    >
      <template #textBelow>
        <LoginRegisterCTA :type="element.content.showLoginRegister" />
      </template>
    </TextContentBlock>

    <template v-if="element.type == 'headlessproducts_categorylisting'">
      <CardList
        :cardContent="element.content.category_list.categories"
        :firstRowThreeCols="false"
        v-if="element.content.category_list.layout == '0'"
      />
      <CardList
        :cardContent="element.content.category_list.categories"
        :firstRowThreeCols="true"
        v-if="element.content.category_list.layout == '1'"
      />
      <div class="row" v-if="element.content.category_list.layout == '2'">
        <div
          class="col-12 col-lg-4"
          v-for="cardContent in element.content.category_list.categories"
          :key="cardContent.title"
        >
          <Card
            :card="cardContent"
            :layoutElemSpace="'mb-4'"
            :layoutProduct="'categories'"
          />
        </div>
      </div>
    </template>

    <template v-if="element.type == 'headlessproducts_productslisting'">
      <div
        class="container"
        v-if="getProductSKU() && showRelatedProductsFromMagento(element)"
      >
        <ProductRelatedProducts :magentoSKU="getProductSKU()" />
      </div>
      <ProductGridSection
        :contentProductList="element.content.products_list.products"
        :filters="element.content.products_list.categories"
        :nutritions="element.content.products_list.nutritions"
        :sectionHeadline="element.content.header"
        :manualSorting="element.content.products_list.manual_sorting"
        :showAllProducts="element.content.products_list.show_all_products === 1"
        v-else
      />
    </template>

    <TrustSignals v-if="element.type == 'icon_with_text'">
      <template #content>
        <figure class="image" v-if="element.icon">
          <i :class="element.icon"></i>
        </figure>
        <div v-html="element.text" />
      </template>
    </TrustSignals>

    <Accordeon
      :accordeonItem="element.accordions"
      v-if="element.type == 'faq_accordion'"
    />

    <template v-if="element.type == 'news_pi1'">
      <BlogOverview
        :data="element.content.data"
        v-if="element.content.data.list"
      />
    </template>

    <Slider3Col
      v-if="element.type == 'customer_reviews'"
      sliderType="customer_reviews"
      :slider="element.reviews.slice(0, 9)"
    />

    <Slider3Col
      :slider="element.content.products_slider.products.slice(0, 9)"
      sliderType="headlessproducts_productslider"
      v-if="element.type == 'headlessproducts_productslider'"
    />

    <div
      v-if="element.type == 'image_banner'"
      class="hero-section"
      @click="openEnlargedImgModal('image_banner')"
    >
      <RatioImage
        :elemClass="'m-0'"
        :imgSrc="element.image"
        :imgAlt="'Bild'"
        :fullsize="true"
        v-if="element.image"
      />
      <RatioImage
        :elemClass="'d-none d-lg-block m-0'"
        :imgSrc="'/placeholder/blogdetail-image-desktop-placeholder.jpg'"
        :imgAlt="'Bild'"
        v-if="element.imageDesktop"
      />
    </div>

    <NotificationBanner
      :layout-bg-color="'bg-jasmine'"
      :arrow-true="true"
      :note-text="element.label"
      :note-src="element.link"
      v-if="element.type == 'top_notification_banner'"
    />

    <NotificationElementContent
      :layout-bg-color="'bg-jasmine'"
      v-if="element.type == 'notification_element'"
    >
      <template #left>
        <div v-html="element.leftText" />
      </template>
      <template #right>
        <div v-html="element.rightText" />
        <LoginRegisterCTA :type="element.content.showLoginRegister" />
      </template>
    </NotificationElementContent>

    <VideoElement
      :placeholderSrc="element.previewImage"
      :placeholderAlt="element.previewImageAltText"
      :videoSrc="element.video"
      :playVideo="false"
      v-if="element.type == 'video_element'"
    />

    <HeroSlider
      v-if="element.type == 'home_page_slider'"
      :slides="element.slides"
    />

    <TwoColBackgroundImageTeaser
      v-if="element.type == 'text_with_background_image'"
      :element="element"
    />

    <QuickOverview v-if="element.type == 'overview_teaser'" :item="element" />

    <Table
      v-if="element.type == 'table'"
      :tablecontent="element.content.bodytext"
    />

    <TrustSignals v-if="element.type == 'icons_list'">
      <template #content>
        <div class="row justify-content-center">
          <div
            v-for="item in element.iconsList"
            :key="item"
            class="trust-item text-center col-4 col-md-3 col-lg-2 col-xl-1"
          >
            <figure class="image mb-1 mx-auto" v-if="item.icon">
              <i :class="item.icon"></i>
            </figure>
            <div
              class="body-2-semibold mb-3"
              v-if="item.label"
              v-html="item.label"
            ></div>
          </div>
        </div>
        <div class="row d-none d-lg-flex mt-14">
          <div class="col-12 text-center">
            <button class="btn btn-text btn-icon" @click="scrollToTop">
              <span>Take me back up<i class="icon icon-arrow-up"></i></span>
            </button>
          </div>
        </div>
      </template>
    </TrustSignals>

    <ContactForm v-if="element.type == 'powermail_pi1'" :data="element" />

    <!-- <ProfessionalDetails v-if="(element.type =='powermail_pi1' && !isChild && element.id == 10905)" :data="element" />
    <EventContactForm v-if="(element.type=='powermail_pi1' && isChild && type != 'Webinar')" :data="element" :type="type" /> -->

    <EventsItem
      v-if="element.type == 'headlessevents_eventslistbytype'"
      :events="getEvents(element)"
      :isLoading="false"
    />

    <NewsletterB2C v-if="element.type == 'newsletter_b2c'" />

    <NewsletterB2B v-if="element.type == 'newsletter_b2b'" />

    <EventsOverview v-if="element.type == 'headlessevents_eventslist'" />

    <ContactOptionIconList
      v-if="element.type == 'contact_options'"
      :element="element"
    />

    <RegistrationFormElement
      v-if="element.type == 'registration_element'"
      :element="element"
    />

    <div v-html="element.content.bodytext" v-if="element.type == 'html'" />

    <iframe
      v-if="element.type == 'apdocchecklogin_doccheckauthentication'"
      v-lazy-load :data-src="getDocCheckUrl(element)"
      :width="element.content.data.width"
      :height="element.content.data.height"
    />

    <IframeElement v-if="element.type == 'iframe_element'" :element="element" />

    <CheckoutThankyouElement
      v-if="element.type == 'checkout_thank_you_page_element'"
      :element="element"
    />

    <template v-if="element.type == 'headlessdownloads_downloadslist'">
      <CardList
        :cardContent="element.content.data.folders"
        :firstRowThreeCols="false"
        v-if="element.content.data.layout == '0'"
      />
      <DownloadList
        v-if="element.content.data.isDetail"
        :downloadList="element.content.data.files"
        :folderName="element.content.data.folderName"
        :detailText="element.content.data.detailText"
      />
      <GatedCard
        v-if="element.content.data.layout == '1'"
        :gatedArea="element.content.data.folders"
      />
    </template>

    <DownloadList
      v-if="element.type == 'headlessdownloads_fileslist'"
      :downloadList="element.content.data.files"
      :folderName="element.content.data.folderName"
      :detailText="element.content.data.detailText"
    />

    <PodcastPlayer
      v-if="element.type == 'podcast_element'"
      :podcastType="element.podcastType"
    />

    <div
      v-if="element.type == 'itkanzlei_rechtstext'"
      v-html="element.content.data.text"
    />
  </div>
</template>

<script>
import Table from '~/components/Table';
import RatioImage from '~/components/RatioImage.vue';
import AutomaticSlider from '~/components/AutomaticSlider.vue';
import TextMediaTeaser from '~/components/TextMediaTeaser.vue';
import RelatedTopics from '~/components/RelatedTopics.vue';
import TextContentBlock from '~/components/TextContentBlock.vue';
import Card from '~/components/Card.vue';
import CardList from '~/components/CardList.vue';
import ProductGridSection from '~/components/ProductGridSection.vue';
import Slider3Col from '~/components/Slider3Col.vue';
import TrustSignals from '~/components/TrustSignals.vue';
import Accordeon from '~/components/Accordeon.vue';
import BlogOverview from '~/components/BlogOverview.vue';
import NotificationBanner from './NotificationBanner.vue';
import VideoElement from './VideoElement.vue';
import NotificationElementContent from './NotificationElementContent.vue';
import QuickOverview from './QuickOverview.vue';
import TwoColBackgroundImageTeaser from '~/components/TwoColBackgroundImageTeaser';
import HeroSlider from '~/components/HeroSlider';
import ContactForm from './ContactForm.vue';
import NewsletterB2C from './Newsletter/NewsletterB2C.vue';
import NewsletterB2B from './Newsletter/NewsletterB2B.vue';
import EventsOverview from '~/components/EventsOverview.vue';
import ContactOptionIconList from '~/components/ContactOptionIconList.vue';
import RegistrationFormElement from '~/components/RegistrationFormElement.vue';
import EventContactForm from '~/components/EventContactForm.vue';
import EventsItem from '~/components/EventsItem.vue';
import GatedCard from '~/components/GatedCard.vue';
import DownloadList from '~/components/DownloadList.vue';
import IframeElement from '~/components/IframeElement.vue';
import LoginRegisterCTA from '~/components/LoginRegisterCTA.vue';
import PodcastPlayer from '~/components/PodcastPlayer.vue';
import ProfessionalDetails from '~/components/ProfessionalDetailsForm.vue';
import CheckoutThankyouElement from '~/components/CheckoutThankyouElement.vue';
import { useUser } from '~/modules/customer/composables/useUser';
import { useLanguageStore } from '@/stores/languageStore';
import ProductRelatedProducts from '~/components/ProductPage/ProductRelatedProducts.vue';
import EnlargeImgModal from '~/components/EnlargeImgModal.vue';

export default {
  name: 'DynamicElement',
  components: {
    AutomaticSlider,
    TextMediaTeaser,
    RatioImage,
    TextContentBlock,
    RelatedTopics,
    CardList,
    ProductGridSection,
    Slider3Col,
    TrustSignals,
    Accordeon,
    BlogOverview,
    NotificationBanner,
    VideoElement,
    NotificationElementContent,
    QuickOverview,
    TwoColBackgroundImageTeaser,
    Table,
    HeroSlider,
    Card,
    ContactForm,
    NewsletterB2C,
    NewsletterB2B,
    EventsOverview,
    ContactOptionIconList,
    RegistrationFormElement,
    EventContactForm,
    EventsItem,
    GatedCard,
    DownloadList,
    IframeElement,
    LoginRegisterCTA,
    PodcastPlayer,
    CheckoutThankyouElement,
    ProfessionalDetails,
    ProductRelatedProducts,
    EnlargeImgModal,
  },
  props: {
    element: Object,
    parentHeadline: String,
    pid: {
      type: Number | null,
    },
    config: {
      type: Array | Object | null,
      default: () => {
        return {
          container: true,
        };
      },
    },
    isDetail: Boolean,
    isChild: Boolean,
    type: String,
  },
  data() {
    return {
      headline: '',
      baseURL: process.env.T3_API_BASE,
      outOfStockProduct: false,
      modalIsOpen: false,
      slideIndex: 0,
      enlargeImgSource: '',
    };
  },
  methods: {
    onClose_outOfStockModal() {
      this.outOfStockProduct = false;
      console.log('outOfStockProduct ', this.outOfStockProduct);
    },

    openEnlargedImgModal(elementType) {
      if (this.element.content.enlargeImageOnClick) {
        switch (elementType) {
          case 'textpic':
            this.enlargeImgSource = this.getOriginalImageURL(this.element);
            break;
          case 'image_banner':
            this.enlargeImgSource = this.element.image;
            break;
        }
        this.modalIsOpen = true;
      }
    },

    getOriginalImageURL(element) {
      if (
        element?.content?.gallery?.rows[1]?.columns[1].properties?.originalUrl
      ) {
        return element?.content?.gallery?.rows[1]?.columns[1].properties
          ?.originalUrl;
      }
      return element?.content?.gallery?.rows[1]?.columns[1]?.publicUrl;
    },

    closeEnlargedImgModal() {
      this.modalIsOpen = false;
      this.slideIndex = 0;
    },

    getContainerClass(element) {
      if (element.appearance) {
        if (
          (element.appearance.layout == 'default' ||
            element.appearance.layout == '0' ||
            element.appearance.layout == 'grid-slider') &&
          this.config.container
        ) {
          return 'container';
        }
      }
    },
    getHeadline(headline, headerLayout) {
      if (headline && headerLayout != 0) {
        return (
          '<h' + headerLayout + '>' + headline + '</h' + headerLayout + '>'
        );
      } else {
        return '';
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },

    getImageProcessedURL(element) {
      return element?.content?.gallery?.rows[1]?.columns[1]?.publicUrl;
    },

    getImageURL(element) {
      if (
        element?.content?.gallery?.rows[1]?.columns[1].properties?.originalUrl?.includes(
          '.webp',
        )
      ) {
        return element?.content?.gallery?.rows[1]?.columns[1].properties
          ?.originalUrl;
      }
      return element?.content?.gallery?.rows[1]?.columns[1]?.publicUrl;
    },
    getImageAlt(element) {
      return element?.content?.gallery?.rows[1]?.columns[1]?.properties
        ?.alternative;
    },
    getEventConditions(type) {
      if (this.isChild) {
        if (type == 'powermail_pi1') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    getEvents(list) {
      if (list?.content?.data?.events?.length) {
        return list?.content?.data?.events;
      } else {
        return [];
      }
    },
    getDocCheckUrl(element) {
      const { isAuthenticated, user } = useUser();

      let url = element?.content?.data?.iframeUrl || '';

      if (isAuthenticated.value) {
        if (user.value !== null && user.value.email) {
          url += 'email=' + user.value.email;
        }
      } else if (window && window.localStorage) {
        let storageEmail = localStorage.getItem('email');
        if (storageEmail) {
          url += 'email=' + storageEmail;
        }
      }
      return url;
    },
    getProductSKU() {
      const languageStore = useLanguageStore();
      const pageData = languageStore.pageData;
      return pageData?.productDetail?.magentoSku || false;
    },
    showRelatedProductsFromMagento(element) {
      const showRelated = element?.content?.products_list?.related_products;
      return showRelated;
    },
  },
  mounted() {
    const CookieDeclarationTrigger = document.getElementById(
      'CookieDeclarationTrigger',
    );
    const CookieDeclarationExists =
      document.getElementById('CookieDeclaration');
    if (CookieDeclarationTrigger && !CookieDeclarationExists) {
      const embed = document.createElement('script');
      embed.id = 'CookieDeclaration';
      embed.src =
        'https://consent.cookiebot.com/57d1c64f-7c06-4075-b98d-8e778c52e370/cd.js';
      CookieDeclarationTrigger.appendChild(embed);
    }
  },
};
</script>

<style lang="scss" scoped>
.doccheck-ifram-element {
  :is(h1, h2, h3, h4, h5, h6) {
    font-size: 1rem;
    font-weight: 400;
    line-height: 170%;
    letter-spacing: 0;
    margin-bottom: 1rem;
  }
}
</style>
