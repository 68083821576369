<template>
  <div>
    <ListFilter
      :filters="data.categories"
      v-model="selectedFilter"
      v-if="data.categories[0]"
    />
    <div class="container">
      <div class="row">
        <div
          class="col-xs-12 col-md-6 col-lg-4 mb-6"
          v-for="item in filteredItems"
          :key="item.uid"
        >
          <BlogCard :item="item" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlogCard from './BlogCard.vue';
import ListFilter from './ListFilter.vue';
export default {
  name: 'BlogOverview',
  props: {
    data: Object,
  },
  components: {
    BlogCard,
    ListFilter,
  },
  data() {
    return {
      selectedFilter: [],
      activeFilters: 0,
    };
  },
  methods: {
    // clickEvent(value) {
    //     console.log(value);
    // }
  },
  computed: {
    filteredItems: function () {
      var vm = this;

      const filter = vm.selectedFilter.map(function (x) {
        return parseInt(x, 10);
      });

      if (filter.length) {
        vm.activeFilters = filter.length;
        const checkFilter = vm.data.list.filter((item) =>
          item.categories.some((cat) => filter.includes(cat.id)),
        );
        return checkFilter;
      } else {
        vm.activeFilters = 0;
        return vm.data.list;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h4 {
  font-size: 1rem;
}
.read-more-link {
  font-weight: 600;
}
</style>
