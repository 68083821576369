<template>
  <div class="cardList--inner" :class="firstRowThreeColsFunc">
    <Card
      :layoutProduct="'layoutProduct'"
      v-for="card in cardContent"
      :key="card.title"
      :card="card"
      :layoutElemSpace="'mb-4'"
    />
  </div>
</template>

<script>
import Card from '~/components/Card';

export default {
  name: 'CardList',
  props: {
    cardContent: Array,
    sectionHeadline: String,
    firstRowThreeCols: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    firstRowThreeColsFunc() {
      return [this.firstRowThreeCols ? 'first-row-three-cols' : ''];
    },
  },
  components: {
    Card,
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.cardList--inner {
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  margin-right: calc(-0.5 * var(--bs-gutter-x));

  &.first-row-three-cols {
    .card {
      &:nth-child(-n + 3) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
@include media-breakpoint-up(md) {
  .cardList--inner {
    &.first-row-three-cols {
      .card {
        &:nth-child(-n + 3) {
          width: 33.3%;
          max-width: 33.3%;
          .ratio {
            --bs-aspect-ratio: 75%;
          }
        }
      }
    }
  }
}
</style>
