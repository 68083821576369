<template>
  <div>
    <div v-if="isLoading">
      <div class="col-12">
        <p class="text-center">
          {{ $t('Loading podcasts...') }}
        </p>
      </div>
    </div>
    <template v-else-if="podcasts.length > 1">
      <div class="podcast-feed row gx-8 gy-6">
        <template v-for="(podcast, i) in podcasts">
          <T3Link
            class="col-12 col-md-6 col-lg-4"
            :href="getPodcastUrl(podcast)"
          >
            <template #content>
              <article
                :key="podcast.title[0]"
                :id="`podcast-${podcast['itunes:episode']}`"
                ref="podcast"
                v-if="showPodcast(i)"
              >
                <div class="position-relative">
                  <RatioImage
                    :imgSrc="getImageSrc(podcast)"
                    elemClass="image-wrapper ratio-1x1 mt-0 mb-3"
                    v-if="getImageSrc(podcast)"
                  />
                  <p
                    class="text-preset-details text-preset-badge podcast-feed__date-badge"
                  >
                    {{ getDate(podcast.pubDate) }}
                  </p>
                  <h4 class="fs-6">
                    <i class="icon-headphones pe-1"></i>
                    <span class="text-preset-details">{{
                      podcast.title[0]
                    }}</span>
                  </h4>
                </div>
              </article>
            </template>
          </T3Link>
        </template>
      </div>
    </template>
    <template v-else-if="podcasts.length === 1">
      <template v-for="(podcast, i) in podcasts">
        <h1 class="mb-7">TISSO-Podcast</h1>
        <h2 class="mb-5">{{ podcast.title[0] }}</h2>
        <article
          class="podcast-item col-12 row"
          :key="podcast.title[0]"
          :id="`podcast-${podcast['itunes:episode']}`"
          ref="podcast"
          v-if="showPodcast(i)"
        >
          <div class="col-12 col-md-8 col-lg-5 position-relative">
            <RatioImage
              :imgSrc="getImageSrc(podcast)"
              elemClass="image-wrapper ratio-1x1 mt-0 mb-3"
              v-if="getImageSrc(podcast)"
            />
            <p
              class="text-preset-details text-preset-badge podcast-feed__date-badge"
            >
              {{ getDate(podcast.pubDate) }}
            </p>
          </div>
          <div class="col-12 col-lg-7">
            <figure>
              <audio controls class="col-12 col-md-8 col-lg-12">
                <source v-lazy-load :data-src="getAudioSrc(podcast)" type="audio/mpeg" />
              </audio>
            </figure>
            <div v-html="podcast.description[0]" />
          </div>
        </article>
      </template>
    </template>
    <template v-else>
      <div class="col-12">
        <p class="text-center">
          {{ $t('No podcasts available') }}
        </p>
      </div>
    </template>
  </div>
</template>

<script>
import RatioImage from './RatioImage.vue';
import { useRoute, ref, useContext, useMeta } from '@nuxtjs/composition-api';
import xml2js from 'xml2js';
import T3Link from '~/components/General/T3Link.vue';
import { useMagentoConfiguration } from '~/composables';

export default {
  name: 'PodcastPlayer',
  components: {
    T3Link,
    RatioImage,
  },
  data() {
    return {
      selectedTeaser: [],
    };
  },
  props: {
    podcastType: String,
  },
  methods: {
    getImageSrc(podcast) {
      return podcast['itunes:image'][0]['$']['href'];
    },
    getAudioSrc(podcast) {
      return podcast.enclosure[0]['$'].url;
    },
    showPodcast(i) {
      if (this.podcastType == 'show-all' || i < 3) {
        return true;
      } else {
        return false;
      }
    },
    getDate(date) {
      const newDate = new Date(date);
      return newDate.toLocaleDateString('de-DE');
    },
    getPodcastUrl(podcast) {
      const { selectedStore } = useMagentoConfiguration();
      return (
        '/' +
        selectedStore.value +
        '/' +
        this.route.params.slug +
        '/' +
        this.formatPodcastTitle(podcast.title[0])
      );
    },
  },
  setup() {
    const route = useRoute();
    const { app } = useContext();
    const url = ref('/podcast.rss');
    const isLoading = ref(true);
    const podcasts = ref({});
    const requestError = ref(false);
    const scrolled = ref(false);
    const podcastTitle = ref(null);

    if (/wissen\/podcast\/[\w-]+$/.test(route.value.params.slug)) {
      podcastTitle.value = route.value.fullPath.split('?')[0].split('/').pop();
    }

    function formatPodcastTitle(title) {
      return title
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
        .replace(/-+/g, '-');
    }

    async function getRssFeed() {
      if (scrolled.value === false) {
        const feedUrl = url.value;
        scrolled.value = true;
        isLoading.value = true;
        return fetch(feedUrl)
          .then((response) => response.text())
          .then((data) => {
            const parser = new xml2js.Parser();
            parser.parseStringPromise(data).then(function (result) {
              podcasts.value = podcastTitle.value
                ? result.rss.channel[0].item.filter(
                    (podcast) =>
                      formatPodcastTitle(podcast.title[0]) ===
                      podcastTitle.value,
                  )
                : result.rss.channel[0].item;
            });
          })
          .catch((err) => {
            requestError.value = true;
          })
          .finally(() => {
            isLoading.value = false;
          });
      }
    }

    return {
      url,
      app,
      route,
      isLoading,
      podcasts,
      requestError,
      formatPodcastTitle,
      getRssFeed,
    };
  },
  mounted() {
    this.getRssFeed();
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.podcast-feed {
  &__date-badge {
    position: absolute;
    left: 1.5rem;
    top: 0.5rem;
  }
}

.podcast-feed ul,
.podcast-list ul {
  p {
    margin: 0 !important;
  }
}
</style>
