var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.docCheckValidation)?_c('div',{class:_vm.getContainerClass(_vm.element),attrs:{"data-cd":_vm.showDocCheckLogin,"data-cdv":_vm.docCheckValidation}},[(_vm.element.content.header && _vm.element.content.headerLayout)?_c('header',{staticClass:"mb-7",domProps:{"innerHTML":_vm._s(_vm.getHeadline(_vm.element.content.header, _vm.element.content.headerLayout))}}):_vm._e(),_vm._v(" "),(
      (_vm.getElementsOf(0, 0) || _vm.getElementsOf(0, 1)) &&
      _vm.element.content.structure.layout == 1
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pb-3 pb-md-0",class:_vm.getColClasses(_vm.element, 2, 1)},[(_vm.element.appearance.subLayout == '50-50-white-box')?_c('div',{staticClass:"card shadow rounded-3 p-2 doc-check-styling",class:_vm.element.appearance.frameClass == 'bg-sand' ? 'bg-white' : 'bg-sand'},_vm._l((_vm.getElementsOf(0, 0)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})}),1):_vm._l((_vm.getElementsOf(0, 0)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})})],2),_vm._v(" "),_c('div',{staticClass:"col-12 pt-3 pt-md-0",class:_vm.getColClasses(_vm.element, 2, 2)},[(_vm.element.appearance.subLayout == '50-50-white-box')?_c('div',{staticClass:"card shadow rounded-3 p-2",class:_vm.element.appearance.frameClass == 'bg-sand' ? 'bg-white' : 'bg-sand'},_vm._l((_vm.getElementsOf(0, 1)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})}),1):_vm._l((_vm.getElementsOf(0, 1)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})})],2)]):_vm._e(),_vm._v(" "),(
      (_vm.getElementsOf(0, 0) || _vm.getElementsOf(0, 1) || _vm.getElementsOf(0, 2)) &&
      _vm.element.content.structure.layout == 2
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-4"},_vm._l((_vm.getElementsOf(0, 0)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})}),1),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-4"},_vm._l((_vm.getElementsOf(0, 1)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})}),1),_vm._v(" "),_c('div',{staticClass:"col-12 col-lg-4"},_vm._l((_vm.getElementsOf(0, 2)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})}),1)]):_vm._e(),_vm._v(" "),(
      (_vm.getElementsOf(0, 0) ||
        _vm.getElementsOf(0, 1) ||
        _vm.getElementsOf(0, 2) ||
        _vm.getElementsOf(0, 3)) &&
      _vm.element.content.structure.layout == 3
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-3"},_vm._l((_vm.getElementsOf(0, 0)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})}),1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6 col-lg-3"},_vm._l((_vm.getElementsOf(0, 1)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})}),1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6 col-lg-3"},_vm._l((_vm.getElementsOf(0, 2)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})}),1),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6 col-lg-3"},_vm._l((_vm.getElementsOf(0, 3)),function(ce){return _c('DynamicElement',{key:ce.id,attrs:{"config":_vm.getConfig(),"element":ce}})}),1)]):_vm._e(),_vm._v(" "),(_vm.getElementsOf(0, 0) && _vm.element.content.structure.layout == 4)?[(_vm.appearance.layout == 'grid-slider')?_c('Slider3Col',{attrs:{"slider":_vm.getElementsOf(0, 0),"sliderType":"icon_with_text"}}):_vm._e(),_vm._v(" "),(_vm.appearance.layout == 'tabs')?_c('Tabs',{attrs:{"data":_vm.getElementsOf(0, 0)}}):_vm._e()]:_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }