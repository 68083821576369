<template>
  <div class="teaserBG">
    <div class="teaserBG--content" v-html="element.text" />
    <figure class="image" v-if="element.image">
      <img v-lazy-load :data-src="element.image" alt="" />
    </figure>
  </div>
</template>

<script>
export default {
  name: 'TwoColBackgroundImageTeaser',

  props: {
    element: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.teaserBG {
  position: relative;
  overflow: hidden;

  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    margin: 0;
    display: block;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      display: block;
      line-height: 0;
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: $black;
      opacity: 0.56;
    }
  }

  &--content {
    position: relative;
    z-index: 1;
    padding: 16.4375rem 2rem 4.5rem;
    color: $white;

    @include media-breakpoint-up(md) {
      height: 100%;
      display: flex;
      flex-direction: column;

      p {
        &:last-child {
          margin-top: auto;
        }
      }
    }

    p {
      .btn {
        width: 100%;
        display: block;
        text-align: center;

        &:first-child {
          margin-bottom: 1.5rem;
        }
      }

      &:nth-last-child(2) {
        margin-bottom: 3rem;
      }

      &:last-child {
        margin-bottom: 0;
      }

      @include media-breakpoint-up(lg) {
        .btn {
          display: inline-block;
          width: inherit;

          &:first-child {
            margin-right: 1.5rem;
            margin-bottom: 0;
          }
        }
      }
    }

    @include media-breakpoint-up(md) {
      padding: 4.5rem 1rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 4.5rem var(--container-padding);
      max-width: 46.5625rem;
      margin-left: auto;
    }

    @include media-breakpoint-up(xl) {
      padding: 23.75rem var(--container-padding) 5.125rem;
    }
  }
}

.structured_content {
  .w-100 {
    .row {
      [class*='col-']:not(.col-lg-4) {
        @include media-breakpoint-up(md) {
          > div,
          .teaserBG {
            height: 100%;
          }
        }
      }
    }
  }
  .row {
    [class*='col-']:not(.col-lg-4) {
      &:last-child {
        .teaserBG {
          &--content {
            @include media-breakpoint-up(lg) {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
    }
  }
}
</style>
