













import { defineComponent } from '@nuxtjs/composition-api';
import { SfModal } from '@storefront-ui/vue';
import T3PageContent from '~/pages/T3PageContent.vue';
import { useUiState } from '~/composables/useUiState';

export default defineComponent({
  name: 'OutOfStockModal',
  components: {
    T3PageContent,
    SfModal,
  },
  props: {
    data: Object,
  },
  setup() {
    const {
      isOutOfStockModalOpen,
      toggleOutOfStockModal,
    } = useUiState();


    return {
      isOutOfStockModalOpen,
      toggleOutOfStockModal,
    };
  },
});
