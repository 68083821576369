<template>
  <div class="bg-sand rounded-3 speaker">
    <RatioImage
      :imgSrc="imageSrc"
      elemClass="ratio-1x1 rounded speaker__image"
    />
    <div class="text-center" v-html="speakerContent" />
    <ul class="blanklist">
      <li v-for="info in contact" :key="info.href">
        <a :href="info.href">
          <strong>
            {{ info.text }}
          </strong>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import RatioImage from '~/components/RatioImage';

export default {
  name: 'Speaker',

  props: {
    imageSrc: Object,
    speakerContent: String,
    contact: Array,
  },

  components: {
    RatioImage,
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.speaker {
  padding: 2rem 0;
  margin-top: 2rem;

  .speaker__image {
    margin: 0 auto !important;
  }
  .rounded {
    max-width: 6rem;
    max-height: 6rem;
    margin: 0 auto 1rem;

    img {
      border-radius: 50%;
    }
  }

  ul {
    margin-bottom: 0;
    margin-top: 1.5rem;

    li {
      text-align: center;
      margin: 0.5rem 0;

      a {
        color: $anthracite;
        text-decoration: none;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0;
    padding: 3.4375rem 0;
  }
}
</style>
