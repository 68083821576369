<template>
  <div class="hooper--wrapper threeColSlider">
    <div class="hooper--inner">
      <div class="hooper--controls" v-if="slider.length > 3">
        <button
          @click.prevent="slidePrev"
          class="hooper--arrow arrow-prev"
          type="button"
        >
          <i class="icon-arrow-left"></i>
        </button>
        <button
          @click.prevent="slideNext"
          class="hooper--arrow arrow-next"
          type="button"
        >
          <i class="icon-arrow-right"></i>
        </button>
      </div>

      <VueSlickCarousel
        v-if="slider.length"
        ref="slickCarousel"
        v-bind="settings"
      >
        <div v-for="(slide, i) in slider" :key="i" :class="getSpaceBetween()">
          <TrustSignals v-if="sliderType === 'icon_with_text'">
            <template slot="content">
              <figure class="image" v-if="slide.icon">
                <i :class="slide.icon"></i>
              </figure>
              <div v-html="slide.text" />
            </template>
          </TrustSignals>
          <CustomerReviews
            :customerReviews="slide"
            v-if="sliderType === 'customer_reviews'"
          />
          <div class="mb-3">
            <ProductCard
              :product="slide"
              v-if="sliderType == 'headlessproducts_productslider'"
            />
          </div>
        </div>
        <template #customPaging="page" v-if="slider.length > 3">
          <div class="silk-dot-class" />
        </template>
      </VueSlickCarousel>
    </div>

    <div v-if="slider.buttonText" class="d-flex justify-content-center mt-4">
      <a :href="slider.buttonSrc" class="btn btn-secondary">
        {{ slider.buttonText }}
      </a>
    </div>
  </div>
</template>

<script>
import TrustSignals from '~/components/TrustSignals';
import VueSlickCarousel from 'vue-slick-carousel';
import ProductCard from '~/components/ProductCard';
import RatioImage from '~/components/RatioImage';
import CustomerReviews from '~/components/CustomerReviews';

export default {
  name: 'Slider3Col',
  components: {
    TrustSignals,
    ProductCard,
    RatioImage,
    CustomerReviews,
    VueSlickCarousel,
  },

  data() {
    return {
      highestHeadlineHeight: 0,
      settings: {
        arrows: true,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 1023,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 672,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
    };
  },

  props: {
    slider: Array,
    sliderType: String,
    sectionHeadline: String,
    imgSrc: String,
    imgAlt: String,

    buttonText: String,
    buttonSrc: String,

    layoutBgColor: {
      type: String,
      default: 'bg-white',
    },

    layoutElemSpace: {
      type: String,
      default: 'my-6 my-lg-15',
    },
  },

  watch: {
    carouselData() {
      this.$refs.carousel.slideTo(this.carouselData);
    },
  },

  methods: {
    slidePrev() {
      this.$refs.slickCarousel.prev();
    },
    slideNext() {
      this.$refs.slickCarousel.next();
    },
    updateCarousel(payload) {
      this.myCarouselData = payload.currentSlide;
    },
    getSpaceBetween() {
      if (this.sliderType == 'customer_reviews') {
        return 'px-1';
      } else {
        return 'px-2';
      }
    },

    getHighestHeadlineHeight() {
      const getHeadlines = document.querySelectorAll('.trustSignals h4');

      for (const getHeadline of getHeadlines) {
        const getHeadlineHeight = getHeadline.offsetHeight;

        if (getHeadlineHeight > this.highestHeadlineHeight) {
          this.highestHeadlineHeight = getHeadlineHeight;
        }
        getHeadline.style.height = this.highestHeadlineHeight + 'px';
      }
    },
  },

  mounted() {
    this.getHighestHeadlineHeight();
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';
@import 'assets/styles/misc/slick-slider';

.threeColSlider {
  .card-review {
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .trustSignals {
    margin-bottom: 0;
  }

  .slick-dots {
    bottom: 0;
    position: relative;
    margin-top: 2rem;
  }

  &.hooper--wrapper {
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
      overflow-x: auto;
    }
  }
}

.hooper {
  height: auto !important;

  &--wrapper {
    position: relative;
    padding: 0 4rem;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  &--inner {
    position: relative;
  }

  &--controls {
    position: absolute;
    top: calc(50% - 2.9375rem);
    transform: translateY(-50%);
    width: 100%;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &--arrow {
    position: absolute;

    i {
      &::before {
        font-size: 1.5rem;
        color: $hazelgreen;
      }
    }

    &.arrow-next {
      right: -4rem;
    }

    &.arrow-prev {
      left: -4rem;
    }
  }

  &-pagination {
    padding: 0;
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 1.25rem;

    li {
      button {
        height: 0.25rem;
        width: 2.5rem;
        margin: 0 0.5rem;
        background: $bone-83;
        transition: $transition-base;
        border-radius: 0;

        &.is-active {
          background: $jasmine;
        }

        &:hover {
          background: $jasmine;
        }
      }
    }
  }
}
</style>
