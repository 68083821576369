






import { defineComponent, ref, useContext } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'AccountCreatedThankyou',
  components: {},
  props: {
    email: {
      type: String,
      required: true,
    },
    element: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    const { app } = useContext();
    localStorage.setItem('email', props.email);

    try {
      //   console.log("Data Layer push: ");
      app.$gtm.push({
        event: 'sign_up',
        method: 'website',
        user_type: 'B2C',
        user_id: props.email,
      });
    } catch (e) {}

    const htmlReplacedMarker = () => {
      return props.element.thankYouMessage.replace('###email###', props.email);
    };
    return {
      htmlReplacedMarker,
    };
  },
});
