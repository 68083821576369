











import {
  defineComponent,
  onMounted,
  useContext,
} from '@nuxtjs/composition-api';
import { SfModal } from '@storefront-ui/vue';
import T3PageContent from '~/pages/T3PageContent.vue';

export default defineComponent({
  name: 'PopupModal',
  components: {
    SfModal,
    T3PageContent,
  },
  props: {
    data: Array || Object,
    visibleOn: Boolean,
  },
  setup() {
    const { app } = useContext();
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7);
    const optsParams = { expires: expirationDate };

    onMounted(() => {
      const getPopupCookie = app.$cookies.get('vsf-popup');

      if (!getPopupCookie) {
        const setPopupCookie = app.$cookies.set(
          'vsf-popup',
          'true',
          optsParams,
        );
      }
    });
  },
});
