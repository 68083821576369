<template>
  <form
    :action="formAction"
    ref="form"
    :id="`c${data.id}`"
    v-show="!isSent"
    enctype="multipart/form-data"
    class="form_professional_details"
    name="form_professional_details"
  >
    <a :href="`#c${data.id}`" class="anchor" ref="anchor"></a>
    <div class="row">
      <div v-html="getFormHeading(data)"></div>
      <template v-for="form in fieldData">
        <div
          class="col-xs-12 align-self-start"
          :class="checkHalfWidth(form.field.css)"
          :key="form.placeholder"
        >
          <div class="form-group">
            <small
              class="text-danger mt-1"
              v-if="form.field.validationClass"
              v-html="
                form.field.additionalAttributes[
                  'data-powermail-required-message'
                ]
              "
            />
            <small
              class="text-danger mt-1"
              v-if="form.field.validationClass"
              v-html="
                form.field.additionalAttributes['data-powermail-error-message']
              "
            />
            <small
              class="text-danger mt-1"
              v-if="form.field.errorClass"
              v-html="
                form.field.additionalAttributes[
                  'data-powermail-powermailfilesize-message'
                ]
              "
            />

            <input
              :disabled="isSending"
              @change="form.field.validationClass = ''"
              :class="checkValidationClass(form.field.validationClass)"
              v-if="
                form.field.type === 'text' ||
                form.field.type === 'email' ||
                form.field.type === 'tel'
              "
              :type="form.field.type"
              :id="getFieldId(form)"
              :placeholder="form.field.placeholder"
              :required="form.field.label.mandatory"
              :name="form.field.name"
              v-model="form.field.value"
            />

            <div v-if="form.field.type === 'radio'">
              <div
                class="d-flex align-item-center radioContainer"
                v-for="options in form.field.options"
              >
                <input
                  :disabled="isSending"
                  @change="form.field.validationClass = ''"
                  :class="checkValidationClass(form.field.validationClass)"
                  class="form-check-input formRadio"
                  :type="form.field.type"
                  :required="form.field.label.mandatory"
                  :name="form.field.name"
                  :value="options.value"
                  :checked="options.checked"
                />
                <LabelComponent
                  v-if="options.label"
                  :for="options.label"
                  :label="options.label"
                  :mandatory="form.field.label.mandatory"
                  :class="form.field.label.labelClasses"
                />
              </div>
            </div>

            <div class="checkContainer" v-if="form.field.type === 'check'">
              <div v-for="options in form.field.options">
                <input
                  :disabled="isSending"
                  @change="form.field.validationClass = ''"
                  type="checkbox"
                  :class="checkValidationClass(form.field.validationClass)"
                  class="form-check-input formCheck"
                  :name="form.field.name"
                  :value="options.value"
                  :required="form.field.label.mandatory"
                />
                <LabelComponent
                  v-if="options.label"
                  :for="options.label"
                  :label="options.label"
                  :mandatory="form.field.label.mandatory"
                  :class="form.field.label.labelClasses"
                  :required="form.field.label.mandatory"
                />
              </div>
            </div>

            <div
              class="uploadContainer"
              v-if="form.field.type === 'file'"
              :class="checkValidationClass(form.field.errorClass)"
            >
              <input
                :disabled="isSending"
                :class="checkValidationClass(form.field.errorClass)"
                @change="form.field.errorClass = ''"
                :type="form.field.type"
                :id="getFieldId(form)"
                :placeholder="form.field.placeholder"
                :required="form.field.label.mandatory"
                :name="form.field.name"
              />
            </div>

            <select
              :disabled="isSending"
              @change="form.field.validationClass = ''"
              :class="checkValidationClass(form.field.validationClass)"
              v-if="form.field.type === 'select'"
              :name="form.field.name"
              :id="getFieldId(form)"
              :required="form.field.label.mandatory"
            >
              <option
                :value="option.value"
                v-for="option in form.field.options"
                :key="option.name"
                :selected="option.isSelected"
              >
                {{ option.label }}
              </option>
            </select>

            <textarea
              :disabled="isSending"
              @change="form.field.validationClass = ''"
              :class="checkValidationClass(form.field.validationClass)"
              v-if="form.field.type === 'textarea'"
              :id="getFieldId(form)"
              :placeholder="form.placeholder"
              :required="form.field.label.mandatory"
              :name="form.field.name"
              v-model="form.field.value"
            />

            <LabelComponent
              v-if="form.field.label"
              :for="getFieldId(form)"
              :label="form.field.label.title"
              :mandatory="form.field.label.mandatory"
              :class="form.field.label.labelClasses"
            />

            <small v-if="form.field.type === 'html'" v-html="form.field.text" />

            <button
              type="submit"
              v-if="form.field.type === 'submit'"
              class="btn btn-primary"
              :class="form.field.submitClasses"
              :disabled="isSending"
            >
              {{ form.field.title }}
              <i class="icon-loader isLoading" v-show="isSending"></i>
            </button>
          </div>
        </div>
      </template>

      <template
        v-for="hiddenField in data.content.data.form.fields.hiddenFields"
      >
        <input
          type="hidden"
          :value="hiddenField.value"
          :name="hiddenField.name"
          :key="hiddenField.name"
        />
      </template>
      <input type="hidden" :value="data.id" name="responseElementId" />
      <input type="hidden" value="1" name="responseElementRecursive" />
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import InputComponent from '~/components/InputComponent';
import SelectComponent from '~/components/SelectComponent';
import LabelComponent from '~/components/LabelComponent';
import TextareaComponent from '~/components/TextareaComponent';
import { useRouter, useContext, ref, computed } from '@nuxtjs/composition-api';
export default {
  name: 'ProfessionalDetails',

  components: {
    LabelComponent,
    InputComponent,
    SelectComponent,
    TextareaComponent,
  },

  setup(props) {
    const form = ref(null);
    const anchor = ref(null);
    const fieldData = ref(null);
    const isSending = ref(false);
    const isSent = ref(false);
    const formAction = ref('');
    const redirectURL = ref('');
    const formData = ref(new FormData());
    const router = useRouter();
    const { app } = useContext();

    formAction.value = props.data?.content?.data?.form?.fields?.action;
    fieldData.value = props.data?.content?.data?.form?.fields?.pages[0].fields;

    const formRedirect = async () => {
      await router.push(
        app.localeRoute({ name: 'typo3', params: { slug: redirectURL.value } }),
      );
    };

    const sendForm = async () => {
      Array.from(form.value.elements).forEach((input) => {
        formData.value.append(input.name, input.value);
      });

      const proxyURL = '/typo3-api' + formAction.value;
      isSending.value = true;

      axios
        .post(proxyURL, formData.value)
        .then((res) => {
          isSending.value = false;

          fieldData.value =
            res.data?.content?.data?.form?.fields?.pages[0].fields;

          if (!fieldData.value) {
            // console.log('wurde vermutlich gesendet');
          } else {
            return;
          }
          redirectURL.value = res.data.slug.replace(/^\//, '');
          isSent.value = true;
          formRedirect();
        })
        .catch((error) => {
          console.log(error);
          isSending.value = false;
        });
    };

    return {
      form,
      sendForm,
      formAction,
      isSending,
      fieldData,
      isSent,
    };
  },

  props: {
    data: Object,
    formItem: Array,
    buttonHref: String,
    buttonText: String,
    formHeadline: String,
  },

  methods: {
    getFieldId(form) {
      return form?.field?.id;
    },
    checkHalfWidth(halfWidth) {
      if (halfWidth == 'halfWidth') {
        return 'col-lg-6';
      }
    },
    getFields(data) {
      //console.log(data);
      return data?.content?.data?.form?.fields?.pages[0].fields;
    },
    getFormAction(data) {
      return data?.content?.data?.form?.fields?.action;
    },
    showErrorMessage(object, message) {
      return message;
    },
    // sendForm(submitEvent) {
    //   this.isSending = true;

    //   var formData = new FormData(this.$refs.form);

    //   const typo3cmsURL = process.env.T3_API_BASE + this.formActionURL; //https://staging-cms.tisso.de
    //   const proxyURL = '/typo3-api' + this.formActionURL;
    //   axios.post(proxyURL, formData)
    //   .then((res) => {

    //       const redirectURL = res.data.slug
    //       if(redirectURL) {

    //         this.isSending = false;
    //         this.isSent = true

    //         router.push(app.localeRoute({ name: 'typo3', slug: redirectURL }));
    //         return;
    //         // wurde gesendet
    //       }
    //       // wurde nicht gesendet
    //       this.resultFormData = res.data;
    //       this.isSending = false;
    //       this.$refs.anchor.scrollIntoView({behavior: "smooth"});
    //   })
    //   .catch((error) => {
    //       console.log(error);
    //       this.isSending = false;
    //   });

    // },
    checkValidationClass(validation) {
      if (validation) {
        return 'border-danger';
      }
    },
    getFormHeading(data) {
      return data ? data.content?.bodytext : '';
    },
    uploadFile(form) {
      document.getElementById(form?.field?.id)?.click();
    },
    getPlaceholder(form) {
      let value = document.getElementById(form?.field?.id)?.value;
      return value ? value.split(/(\\|\/)/g).pop() : form.field?.placeholder;
    },
  },
};
</script>
<style lang="scss" scoped>
.anchor {
  display: block;
  position: relative;
  top: -10rem;
}
</style>
<style lang="scss">
@import 'assets/styles/misc/vars';
@import 'assets/styles/misc/icons';

.icon-loader.isLoading {
  animation: loader 1.2s linear infinite;
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

form {
  .form-group {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 1.5rem;
    position: relative;

    label {
      font-size: 0.875rem;
      font-weight: 600;
      margin: 0 $border-radius $border-radius;
    }

    input,
    textarea,
    select {
      padding: 0.65625rem 1rem;
      border: 1px solid $bone-83;
      border-radius: $border-radius;
    }

    textarea {
      resize: none;
      height: 6.25rem;
    }

    select {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;

      ~ label {
        position: relative;

        &::before {
          @extend .iconElement;
          content: $icon-chevron-down;
          display: block;
          position: absolute;
          top: calc(100% + 1.0625rem);
          right: 0.4375rem;
          font-size: 1.5rem;
          color: $hazelgreen;
        }
      }
    }

    .btn.btn-primary {
      width: 100%;
      margin-top: 1.5rem;
      text-align: center;

      @include media-breakpoint-up(md) {
        width: fit-content;
      }
    }
  }
}

.uploadContainer {
  padding: 0.65625rem 1rem;
  border: 1px solid $bone-83;
  border-radius: $border-radius;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .uploadPlaceholder {
    margin-bottom: 0;
    color: $anthracite;
  }
  span {
    cursor: pointer;
  }
}

.radioContainer {
  .formRadio {
    padding: 0px !important ;
  }
}
.checkContainer {
  .formCheck {
    padding: 0px !important;
  }
}
</style>
