<template>
  <div>
    <div id="mc_embed_signup_b2b">
      <form
        action="https://tisso.us10.list-manage.com/subscribe/post?u=796799058014c6fa649c34608&amp;id=25e7aabfe6&amp;f_id=0062d8e1f0"
        method="post"
        id="mc-embedded-subscribe-form-b2b"
        name="mc-embedded-subscribe-form-b2b"
        class="validate newsletterform"
        target="_blank"
      >
        <div id="mc_embed_signup_scroll">
          <div class="mc-field-group mt-4 mb-3">
            <label for="mce-EMAIL" class="form-label">
              {{ $t('Email address') }} <span class="asterisk">*</span>
            </label>
            <input
              type="email"
              value=""
              name="EMAIL"
              class="required email form-control"
              id="mce-EMAIL"
              required
            />
            <span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
          </div>
          <div class="mc-field-group input-group mb-3">
            <strong class="form-label ms-0">{{
              $t('I would like to receive newsletters on the following topics')
            }}</strong>
            <ul class="blanklist">
              <li class="form-check">
                <input
                  type="checkbox"
                  value="1"
                  name="group[7293][1]"
                  id="mce-group[7293]-7293-0"
                  class="form-check-input"
                />
                <label class="form-check-label" for="mce-group[7293]-7293-0">{{
                  $t('Product and therapy news')
                }}</label>
              </li>
              <li class="form-check">
                <input
                  type="checkbox"
                  value="2"
                  name="group[7293][2]"
                  id="mce-group[7293]-7293-1"
                  class="form-check-input"
                />
                <label class="form-check-label" for="mce-group[7293]-7293-1">{{
                  $t('Further training offers')
                }}</label>
              </li>
              <li class="form-check">
                <input
                  type="checkbox"
                  value="4"
                  name="group[7293][4]"
                  id="mce-group[7293]-7293-2"
                  class="form-check-input"
                />
                <label class="form-check-label" for="mce-group[7293]-7293-2">{{
                  $t('Other health topics (e.g. from Provicell)')
                }}</label>
              </li>
            </ul>
            <span id="mce-group[7293]-HELPERTEXT" class="helper_text"></span>
          </div>
          <div id="mce-responses" class="clear">
            <div
              class="response"
              id="mce-error-response"
              style="display: none"
            ></div>
            <div
              class="response"
              id="mce-success-response"
              style="display: none"
            ></div>
          </div>
          <div style="position: absolute; left: -5000px" aria-hidden="true">
            <input
              type="text"
              name="b_796799058014c6fa649c34608_25e7aabfe6"
              tabindex="-1"
              value=""
            />
          </div>
          <div class="mb-3 form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
              required
            />
            <label class="form-check-label" for="exampleCheck1"
              >Bitte sendet mir entsprechend eurer
              <button
                type="button"
                class="px-0 d-inline text-primary"
                @click.prevent="goToPrivacyPage()"
              >
                Datenschutzerklärung
              </button>
              regelmäßig und jederzeit widerruflich Informationen zu aktuellen
              Gesundheitsthemen und Nahrungsergänzungsmitteln per E-Mail
              zu.</label
            >
          </div>
          <div class="clear">
            <input
              type="submit"
              :value="$t('Subscribe')"
              name="subscribe"
              id="mc-embedded-subscribe"
              class="btn btn-primary w-100 mt-8 w-lg-auto join-button"
            />
          </div>
        </div>
        <!-- Form Transaction ID -->
        <input
          type="hidden"
          class="custom_payload form_transaction_id"
          value=""
        />

        <!-- GA Client Id -->
        <input type="hidden" class="custom_payload ga_client_id" value="" />

        <!-- GCLID -->
        <input type="hidden" class="custom_payload gclid" value="" />

        <!-- FBCLID -->
        <input type="hidden" class="custom_payload fbclid" value="" />

        <!-- UTM traffic source -->
        <input
          type="hidden"
          class="custom_payload utm_traffic_source"
          value=""
        />

        <input
          type="hidden"
          class="custom_payload form_category"
          value="form_newsletter_b2b"
        />

        <!-- Page Url -->
        <input
          type="hidden"
          class="custom_payload page_url"
          name="page_url"
          :value="getPageUrl()"
        />
      </form>
    </div>
  </div>
</template>

<script>
import { useRouter, useContext } from '@nuxtjs/composition-api';
export default {
  name: 'NewsletterB2B',
  components: {},
  setup() {
    const router = useRouter();
    const { app } = useContext();

    const goToPrivacyPage = async () => {
      const privacyPage = router.resolve(
        app.localeRoute({ name: 'typo3', params: { slug: 'datenschutz' } }),
      );
      await window.open(privacyPage.href, '_blank');
    };

    const getPageUrl = () => {
      return window.location.href;
    };
    return {
      goToPrivacyPage,
      getPageUrl,
    };
  },
};
</script>
