<template>
  <Card :card="card" />
</template>

<script>
import Card from '~/components/Card.vue';
export default {
  name: 'RelatedTopics',
  components: { Card },
  props: {
    sectionHeadline: String,
    card: Object,
  },
};
</script>
