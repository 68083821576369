<template>
  <div class="video-element">
    <div class="container">
      <figure class="video-element--video" :class="{ isRunning: isPlayed }">
        <img v-lazy-load :data-src="placeholderSrc" :alt="placeholderAlt" v-if="!playVideo" />
        <video class="video" controls muted autoplay v-if="playVideo">
          <source v-lazy-load :data-src="videoSrc" type="video/mp4" />
        </video>
        <div class="button-wrapper" v-if="!playVideo">
          <button
            class="icon-play-circle"
            type="button"
            @click="setPlayVideo(true)"
          >
            <span class="d-none">Play {{ videoSrc }}</span>
          </button>
        </div>
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoElement',

  data() {
    return {
      isPlayed: false,
    };
  },

  props: {
    placeholderSrc: String,
    placeholderAlt: String,
    videoSrc: String,

    playVideo: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    setPlayVideo(c) {
      this.playVideo = c;
      this.isPlayed = !this.isPlayed;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/styles/misc/vars';

.video-element {
  position: relative;

  &--video {
    width: 100%;
    padding-top: 56.25%;
    height: 0;
    position: relative;

    &.isRunning {
      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      width: 100%;
      height: 100%;
      display: block;
      background: $anthracite;
      z-index: 0;
      position: absolute;
      opacity: 0.4;
      top: 0;
      left: 0;
      transition: $transition-base;
      pointer-events: none;
    }

    .video,
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }

    .button-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;

      button {
        padding: 0.9375rem;
        border-radius: 50%;
        background: $bone;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.5rem;
        height: 3.5rem;
        transition: $transition-base;
        z-index: 2;

        &::before {
          font-size: 1.625rem;
          color: $hazelgreen;
        }

        &::after {
          content: '';
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
      }
    }

    &:hover {
      &::after {
        opacity: 0;
      }

      .button-wrapper {
        button {
          background: $hazelgreen;

          &::before {
            color: $sand;
          }
        }
      }
    }
  }
}
</style>
