<template>
  <ul class="rating blanklist d-flex" :class="`rating-${getRating()}`">
    <li>
      <span class="d-none">1 / 5</span>
    </li>
    <li>
      <span class="d-none">2 / 5</span>
    </li>
    <li>
      <span class="d-none">3 / 5</span>
    </li>
    <li>
      <span class="d-none">4 / 5</span>
    </li>
    <li>
      <span class="d-none">5 / 5</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Rating',

  methods: {
    getRating() {
      const rating = this.star;
      return String(rating).replace('.', '-');
    },
  },

  props: {
    star: Number,
  },
};
</script>

<style scoped lang="scss">
@import 'assets/styles/misc/vars';
@import 'assets/styles/misc/social-icons';

.rating {
  margin: 0 -0.375rem 1.75rem;

  li {
    padding: 0 0.375rem;
    position: relative;

    &::before {
      @extend .SocialIconElement;
      content: $social-icon-star-full;
      display: block;
      font-size: 1.625rem;
      color: $jasmine;
    }

    &::after {
      @extend .SocialIconElement;
      content: $social-icon-star-half;
      font-size: 1.625rem;
      color: $jasmine;
      position: absolute;
      top: 0;
      left: -1px;
      display: none;
    }
  }

  /* STYLING RATING FOR EACH OPTION */

  &-1 {
    li {
      &:nth-child(n + 2) {
        &::before {
          color: $bone-83;
        }
      }
    }
  }

  &-1-5 {
    li {
      &:nth-child(2) {
        &::after {
          display: block;
        }
      }

      &:nth-child(n + 2) {
        &::before {
          color: $bone-83;
        }
      }
    }
  }

  &-2 {
    li {
      &:nth-child(n + 3) {
        &::before {
          color: $bone-83;
        }
      }
    }
  }

  &-2-5 {
    li {
      &:nth-child(3) {
        &::after {
          display: block;
        }
      }

      &:nth-child(n + 3) {
        &::before {
          color: $bone-83;
        }
      }
    }
  }

  &-3 {
    li {
      &:nth-child(n + 4) {
        &::before {
          color: $bone-83;
        }
      }
    }
  }

  &-3-5 {
    li {
      &:nth-child(4) {
        &::after {
          display: block;
        }
      }

      &:nth-child(n + 4) {
        &::before {
          color: $bone-83;
        }
      }
    }
  }

  &-4 {
    li {
      &:nth-child(n + 5) {
        &::before {
          color: $bone-83;
        }
      }
    }
  }

  &-4-5 {
    li {
      &:nth-child(5) {
        &::after {
          display: block;
        }
      }

      &:nth-child(n + 5) {
        &::before {
          color: $bone-83;
        }
      }
    }
  }
}
</style>
